<template>
  <a-modal
    title="Filters"
    width="600px"
    v-model="visible"
    @cancel="apply"
  >
    <div class="content-view">
      <div class="content-view-block w-full">
        <p>
          <b>Associate Status</b>
          <a-select
            class="filters-select"
            v-model:value="filters.associateStatus"
            :allow-clear="true"
            placeholder="Status"
          >
            <a-select-option
              v-for="(status, index) in associateStatuses"
              :key="index"
              :value="status"
            >
              {{ convertStatusToText(status) }}
            </a-select-option>
          </a-select>
        </p>
        <p>
          <b>Job Classification</b>
          <a-select
            class="filters-select"
            v-model:value="filters.role"
            :dropdown-match-select-width="false"
            :allow-clear="true"
            placeholder="Job Classification"
          >
            <a-select-option v-for="role in roles" :key="role.id" :value="role.id">
              {{ role.name }}
            </a-select-option>
          </a-select>
        </p>
        <p>
          <b>Associates</b>
          <a-select
            class="filters-select"
            mode="multiple"
            ref="associateSelect"
            v-model:value="filters.associates"
            show-search
            :allowClear="true"
            placeholder="Associates"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            :default-active-first-option="false"
            :dropdown-match-select-width="false"
            :options="foundAssociates"
            :loading="loading"
            @search="fetchAssociates"
            @change="handleSearchAssociatesChange"
            @focus="handleFocusAssociates"
          />
        </p>
      </div>
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button
          type="primary"
          @click="apply"
        >
          Apply
        </a-button>
        <a-button
          type="danger"
          :disabled="disableResetButton"
          @click="reset"
        >
          Reset
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { Modal, Button, Select } from "ant-design-vue";
import { associateStatusMap } from "@/const";
import api from "@/api";

const filtersBaseState = Object.freeze({
  associateStatus: undefined,
  role: undefined,
  associates: undefined
});

export default {
  components: {
    'a-modal': Modal,
    'a-button': Button,
    'a-select': Select,
    'a-select-option': Select.Option
  },
  mixins: [api],
  data() {
    return {
      filters: {...filtersBaseState},
      foundAssociates: [],
      loading: false,
      searchUsersTimeout: null,
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    currentFilters: {
      type: Object,
      required: true
    }
  },
  computed: {
    associateStatuses() {
      return Object.keys(associateStatusMap);
    },
    roles() {
      return this.$store.state.applicationState.projectRoles;
    },
    disableResetButton() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    }
  },
  watch: {
    visible(newVal) {
      this.localVisible = newVal;
    },
    localVisible(newVal) {
      this.$emit('update:visible', newVal);
    }
  },
  methods: {
    reset() {
      this.filters = { ...filtersBaseState };
    },
    apply() {
      const filtersToApply = { ...this.filters };
      if (filtersToApply.associates && filtersToApply.associates.length === 0) {
        filtersToApply.associates = undefined;
      }
      this.$emit('apply', filtersToApply);
    },
    handleSearchAssociatesChange(value) {
      this.filters.associates = value;
      this.$refs.associateSelect.blur();
    },
    fetchAssociates(value) {
      if (this.searchUsersTimeout) {
        clearTimeout(this.searchUsersTimeout);
      }
      this.searchUsersTimeout = setTimeout(() => {
        this.loading = true;
        this.apiSearchAssociate(value).then((resp) => {
          this.foundAssociates = resp.data.associate_list.map(user => ({
            label: `[${user.employee_id}] ${user.first_name} ${user.last_name}`,
            key: user.employee_id,
            value: user.employee_id
          }));
          this.loading = false;
        });
      }, 1000);
    },
    handleFocusAssociates() {
      if (!this.filters.associates || !this.filters.associates.length) {
        this.fetchAssociates('');
      }
    },
    convertStatusToText(status) {
      return associateStatusMap[status] ?? status;
    }
  },
  mounted() {
    this.filters = { ...this.currentFilters };
  }
}
</script>

<style scoped>
.daily-summary-filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.content-view-block {
  max-width: unset;
}
.filters-select {
  width: 250px;
}

.filters-select .ant-select-dropdown {
  width: 200px;
}

.filters-select .ant-select-dropdown-menu-item {
  white-space: normal;
  word-wrap: break-word;
}
</style>
