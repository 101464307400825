<template>
  <div class="flex flex-column gap-4 align-items-start">
    <div>
      <a-button @click="showNotification">Notification</a-button>
    </div>
  </div>
</template>

<script>
import {Button, notification} from "ant-design-vue";

export default {
  components: {
    'a-button': Button,
    notification
  },
  methods: {
    showNotification() {
      notification['warning']({
        message: 'Test Message',
        description: 'Test Description'
      })
    }
  }
}
</script>
