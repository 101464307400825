<template>
  <a-modal
    title="Add Note"
    :visible="isVisibleModal"
    @ok="setNote"
    @cancel="close"
    @close="close"
  >
    <template v-if="associate">
      <a-textarea
        v-model:value="associate.note"
        placeholder="Type the note"
        :rows="10"
        :maxlength="512"
      />
    </template>
  </a-modal>
</template>

<style scoped>

</style>

<script>

import {Button, Icon, Input, Modal} from 'ant-design-vue';

import api from "@/api";

export default {
  components: {
    'a-modal': Modal,
    'a-input': Input,
    'a-icon': Icon,
    'a-textarea': Input.TextArea,
    'a-button': Button,
  },
  props: ['associate'],
  mixins: [api],
  data() {
    return {}
  },
  computed: {
    isVisibleModal() {
      return this.associate != null;
    },
  },
  methods: {
    setNote() {
      this.apiSetAssociateNote(this.associate)
        .then(() => {
          this.$emit('close')
        });
    },
    close() {
      this.$emit('close');
    }
  },
}

</script>
