var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"Reset Password","width":"300px","footer":null},on:{"cancel":_vm.handleCancel},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('transition',{attrs:{"name":"bounce"}},[(_vm.resetErrorMsg)?_c('a-alert',{staticClass:"mb-2",attrs:{"type":"error","message":_vm.resetErrorMsg}}):_vm._e()],1),(!_vm.verificationCodeSent)?_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'username',
          {
            rules: [
              { required: true, message: 'Please enter your username' },
              { type: 'email', message: _vm.usernameErrorMsg },
            ],
            validateTrigger: ['blur', 'change'],
          },
        ]),expression:"[\n          'username',\n          {\n            rules: [\n              { required: true, message: 'Please enter your username' },\n              { type: 'email', message: usernameErrorMsg },\n            ],\n            validateTrigger: ['blur', 'change'],\n          },\n        ]"}],attrs:{"disabled":_vm.loading,"placeholder":"Username","allow-clear":true}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{staticClass:"no-margin"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password',
          {
            rules: [
              { required: true, message: 'Please enter your password' },
              { min: 6, message: 'Password must be at least 6 characters' },
            ],
            validateTrigger: ['blur', 'change'],
          },
        ]),expression:"[\n          'password',\n          {\n            rules: [\n              { required: true, message: 'Please enter your password' },\n              { min: 6, message: 'Password must be at least 6 characters' },\n            ],\n            validateTrigger: ['blur', 'change'],\n          },\n        ]"}],attrs:{"type":"password","placeholder":"New password","disabled":_vm.loading},on:{"change":_vm.handlePasswordChange}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{staticClass:"no-margin"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'passwordConfirm',
          {
            rules: [
              { required: true, message: 'Please repeat your password' },
              {
                validator: _vm.validateToNextPassword,
                message: 'Passwords do not match',
              },
            ],
            validateTrigger: ['blur', 'change'],
          },
        ]),expression:"[\n          'passwordConfirm',\n          {\n            rules: [\n              { required: true, message: 'Please repeat your password' },\n              {\n                validator: validateToNextPassword,\n                message: 'Passwords do not match',\n              },\n            ],\n            validateTrigger: ['blur', 'change'],\n          },\n        ]"}],attrs:{"type":"password","placeholder":"Repeat password","disabled":_vm.loading || _vm.passwordInvalid}},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('div',{staticClass:"flex flex-column gap-2 justify-content-center mt-4"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","disabled":_vm.hasErrors(_vm.form.getFieldsError()),"loading":_vm.loading}},[_vm._v(" Send Verification Code ")])],1)])],1):_c('div',{staticClass:"flex flex-column gap-4 justify-content-center"},[_c('div',[_vm._v(" A verification code has been sent to your email address "+_vm._s(_vm.username)+". Please enter it below. ")]),_c('a-input',{attrs:{"placeholder":"Code"},model:{value:(_vm.verificationCode),callback:function ($$v) {_vm.verificationCode=$$v},expression:"verificationCode"}}),_c('a-button',{attrs:{"type":"primary","disabled":!_vm.verificationCode || !_vm.verificationCode.length,"loading":_vm.loading},on:{"click":_vm.resetPassword}},[_vm._v(" Reset Password ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }