<template>
  <div>
    <h3>Time</h3>
    <div class="wrapper">
      <a-descriptions
        :column="1"
        bordered
        size="small"
        class="p-0"
        title="Scheduled"
      >
        <a-descriptions-item label="Date"
          >{{ formatDateRange(shift.scheduled_start, shift.scheduled_end) }}
        </a-descriptions-item>
        <a-descriptions-item label="Time"
          >{{ timeRangeToString(shift.scheduled_start, shift.scheduled_end) }}
        </a-descriptions-item>
        <a-descriptions-item label="Duration">
          {{
            minutesToHoursAndMinutes(shift.scheduled_duration)
          }}</a-descriptions-item
        >
      </a-descriptions>
      <a-descriptions
        :column="1"
        bordered
        size="small"
        class="p-0"
        title="Signed"
      >
        <a-descriptions-item label="Date"
          >{{ formatDateRange(shift.work_start, shift.work_end) }}
        </a-descriptions-item>
        <a-descriptions-item label="Time"
          >{{ timeRangeToString(shift.work_start, shift.work_end) }}
        </a-descriptions-item>
        <a-descriptions-item label="Duration">
          {{ minutesToHoursAndMinutes(shift.duration) }}</a-descriptions-item
        >
        <a-descriptions-item label="Lunch">{{
          timeRangeToString(shift.lunch_start, shift.lunch_end)
        }}</a-descriptions-item>
        <a-descriptions-item label="Lunch Duration">
          {{ minutesToHoursAndMinutes(shift.lunch_duration) }}
        </a-descriptions-item>
        <a-descriptions-item label="Second Lunch">
          {{
            timeRangeToString(shift.second_lunch_start, shift.second_lunch_end)
          }}</a-descriptions-item
        >
        <a-descriptions-item label="Second Lunch Duration">
          {{ minutesToHoursAndMinutes(shift.second_lunch_duration) }}
        </a-descriptions-item>
        <a-descriptions-item label="Work Hours"
          >{{ workHours() }}
        </a-descriptions-item>
        <a-descriptions-item
          label="Pay Hours
"
          >{{ payHours() }}
        </a-descriptions-item>
      </a-descriptions>
      <a-descriptions
        :column="1"
        bordered
        size="small"
        class="p-0"
        title="Actual"
      >
        <a-descriptions-item label="Date"
          >{{ formatDateRange(shift.actual_work_start, shift.actual_work_end) }}
        </a-descriptions-item>
        <a-descriptions-item label="Time"
          >{{
            timeRangeToString(shift.actual_work_start, shift.actual_work_end)
          }}
        </a-descriptions-item>
        <a-descriptions-item label="Duration">
          {{
            minutesToHoursAndMinutes(shift.actual_duration)
          }}</a-descriptions-item
        >
        <a-descriptions-item label="Lunch">{{
          timeRangeToString(shift.actual_lunch_start, shift.actual_lunch_end)
        }}</a-descriptions-item>
        <a-descriptions-item label="Lunch Duration">
          {{ minutesToHoursAndMinutes(shift.actual_lunch_duration) }}
        </a-descriptions-item>
        <a-descriptions-item label="Second Lunch">
          {{
            timeRangeToString(
              shift.actual_second_lunch_start,
              shift.actual_second_lunch_end
            )
          }}</a-descriptions-item
        >
        <a-descriptions-item label="Second Lunch Duration">
          {{ minutesToHoursAndMinutes(shift.actual_second_lunch_duration) }}
        </a-descriptions-item>
        <a-descriptions-item label="Work Hours"
          >{{ workHours("actual") }}
        </a-descriptions-item>
        <a-descriptions-item
          label="Pay Hours
"
          >{{ payHours("actual") }}
        </a-descriptions-item>
      </a-descriptions>
    </div>
  </div>
</template>

<script>
import { Descriptions } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import shiftsHelper from "@/helpers/shifts";

const { mapState: shiftState } =
  createNamespacedHelpers("shifts");

export default {
  components: {
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
  },
  mixins: [shiftsHelper],
  computed: {
    ...shiftState({
      shift: (state) => state.shift.shift,
    }),
  },
  methods: {
    timeRangeToString(start, end) {
      if (!start || !end) {
        return " - ";
      }

      const { start: startTime, end: endTime } = this.formatTimeRange(
        start,
        end
      );

      return `${startTime} - ${endTime}`;
    },

    getShiftTimes(type) {
      const prefix = type === "signed" ? "" : "actual_";
      return {
        work_start: this.shift[`${prefix}work_start`],
        work_end: this.shift[`${prefix}work_end`],
        lunch_start: this.shift[`${prefix}lunch_start`],
        lunch_end: this.shift[`${prefix}lunch_end`],
        second_lunch_start: this.shift[`${prefix}second_lunch_start`],
        second_lunch_end: this.shift[`${prefix}second_lunch_end`],
      };
    },

    workHours(type = "signed") {
      const {
        work_start,
        work_end,
        lunch_start,
        lunch_end,
        second_lunch_start,
        second_lunch_end,
      } = this.getShiftTimes(type);

      const formatted = this.formatWorkHours(
        work_start,
        work_end,
        lunch_start,
        lunch_end,
        second_lunch_start,
        second_lunch_end
      );

      return formatted ?? " - ";
    },

    payHours(type = "signed") {
      const {
        work_start,
        work_end,
        lunch_start,
        lunch_end,
        second_lunch_start,
        second_lunch_end,
      } = this.getShiftTimes(type);

      const formatted = this.formatPayHours(
        this.shift.scheduled_start,
        this.shift.scheduled_end,
        work_start,
        work_end,
        lunch_start,
        lunch_end,
        second_lunch_start,
        second_lunch_end
      );

      return formatted ?? " - ";
    },
  },
};
</script>

<style scoped>
::v-deep .ant-descriptions-title {
  text-align: center;
  margin: 0;
  padding: 8px;
  border-radius: 2px 2px 0 0;
  border: 1px solid #e8e8e8;
  border-bottom: none;
}

::v-deep .ant-descriptions-item-label {
  font-weight: bold;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
</style>
