<template>
  <div>
    <h2 class="view-header">Associate Shifts</h2>
    <v-tour
      name="shiftsTour"
      :steps="tourSteps"
      :callbacks="callbacks"
      :options="vueTourOptions"
    />
    <div class="no_print">
      <associate-shifts-filters
        :current-filters="filterNew"
        :visible.sync="associateShiftsFiltersVisible"
        @apply="applyFilters"
      />

      <shift-pay-code :visible.sync="visiblePayCodeModal" />

      <shift-view
        :visible.sync="visibleShiftModal"
        :shift_id="editingAssociateShiftId"
        @edit="loadData"
      />

      <a-modal
        title="Associate Profile Edit"
        width="80%"
        :footer="null"
        v-model="associateProfileModalVisible"
        @ok="endEditAssociateProfile"
        @cancel="endEditAssociateProfile"
        :destroy-on-close="true"
      >
        <div style="overflow-x: auto; width: 100%">
          <associate-profile
            :associate-number.sync="editingAssociateNumber"
            @edited="handleEditedAssociateProfile"
            @clone="openCloneAssociate"
            @close="endEditAssociateProfile"
          />
        </div>
      </a-modal>

      <a-modal
        v-model="cloneAssociateVisible"
        title="Clone Associate"
        width="1000px"
        :footer="null"
        :destroy-on-close="true"
      >
        <clone-associate
          :associate-number="cloningAssociateNumber"
          @close="endCloneAssociate"
        />
      </a-modal>

      <shift-sign
        :shift_id="editingAssociateShiftId"
        :visible.sync="visibleSignModal"
        @edit="afterSignShift"
      />

      <add-multiple-shifts
        :key="addMultipleShiftsModalKey"
        :visible="visibleAddMultipleShiftsModal"
        @close="closeMultipleShiftsModal"
      />

      <a-modal
        v-model="visibleAssociateShiftModal"
        :footer="null"
        width="950px"
        @cancel="closeAssociateShiftModal"
        :destroy-on-close="true"
      >
        <associate-shift
          :shift-id="editingAssociateShiftId"
          :associate-number="associateNumberOfChangingShift"
          @edit="closeAssociateShiftModalWithChanges"
          @on-associate-create="closeAssociateShiftModalToCreateNewAssociate"
          @shift-exists="handleShiftExists"
        />
      </a-modal>

      <send-notification
        :associate="associateProfileToSendSms"
        @close="closeSmsModal"
      />

      <shift-overlap-modal
        :visible="visibleShiftAlreadyExistsModal"
        :overlapping-shifts="overlappingShifts"
        @edit-existing-shift="editExistingShift"
        @edit-new-shift="closeExistingShiftModal"
      />

      <create-new-associate
        :visible="visibleNewAssociateModal"
        @close="closeNewAssociateModal"
      />

      <div class="table_controls_and_data">
        <div class="flex gap-2">
          <a-button
            icon="diff"
            :disabled="loading"
            type="primary"
            @click="openPayCodeModal"
            >Add Pay Code</a-button
          >
          <a-button
            type="primary"
            icon="diff"
            :disabled="loading"
            @click="addShift"
          >
            Add Shift
          </a-button>
          <a-button
            type="primary"
            icon="copy"
            :disabled="loading"
            @click="openMultipleShiftsModal"
          >
            Add Several Shift
          </a-button>
        </div>

        <div class="flex gap-2">
          <a-button
            icon="filter"
            :disabled="loading"
            @click="showAssociateShiftsFilterModal"
          >
            Filters
          </a-button>

          <a-button
            icon="undo"
            :disabled="loading || filtersAreEmpty"
            @click="resetAssociateShiftsFilters"
          >
            Reset Filters
          </a-button>
        </div>
      </div>
      <a-table
        size="small"
        :columns="columns"
        row-key="shift_id"
        :data-source="tableData"
        :loading="loading"
        :style="{ whiteSpace: 'pre' }"
        :locale="{ emptyText: 'Shifts Not Found' }"
        :pagination="pagination"
        :expanded-row-render="expandedRowRender"
        :dropdown-match-select-width="false"
        :expand-row-by-click="true"
        @change="handleTableChange"
      >
        <span
          slot="associate"
          slot-scope="text, record"
          :key="record.user_avatar"
        >
          <grouped-associate-info
            :first-name="record.first_name"
            :last-name="record.last_name"
            :photo-url="record.user_avatar"
            :unverified="record.unverified"
            :associate-number="record.associate_number"
          />
        </span>
        <span class="tags-cell" slot="status" slot-scope="text, record">
          <a-tooltip>
            <template slot="title">
              {{ getShiftStatusTagText(record.status) }}
            </template>
            <a-tag
              class="auth-tag"
              :color="
                record.pay_code_name
                  ? '#d47aff'
                  : getAuthStatusTagColor(record.status)
              "
              @click.stop="handleTagClick(record)"
            >
              {{
                record.pay_code_name ?? getShiftStatusTagLabel(record.status)
              }}
            </a-tag>
          </a-tooltip>
          <a-tooltip v-if="record.retro_shift">
            <template slot="title">
              This is a shift that has been worked or is due to be worked more
              than one month ago
            </template>
            <a-tag class="auth-tag" :color="statusTagColors.GRAY">
              Retro Shift
            </a-tag>
          </a-tooltip>
        </span>
        <span slot="authorizations" slot-scope="text, record">
          <span
            v-if="record.authorizations && record.authorizations.length > 0"
            class="flex flex-column gap-2"
          >
            <a-tag
              class="auth-tag"
              v-for="auth in record.authorizations"
              :key="auth.id"
              :color="getShiftAuthStatusTagColor(auth)"
            >
              {{ convertType(auth.type) }}
            </a-tag>
          </span>
          <span v-else>-</span>
        </span>
        <span
          v-if="!record.deleted_at"
          slot="actions"
          slot-scope="text, record"
        >
          <a-button-group size="small">
            <a-button
              id="open-shift-btn"
              icon="eye"
              @click.stop="openShiftModal(record.shift_id)"
            />

            <a-tooltip>
              <template slot="title"> Open Profile </template>
              <a-button
                icon="user"
                @click.stop="startEditAssociateProfile(record.associate_number)"
              />
            </a-tooltip>

            <a-tooltip>
              <template slot="title"> Send Message </template>
              <a-button
                id="send-msg-btn"
                icon="message"
                :disabled="!record.phone"
                @click.stop="openSmsSendModal(record)"
              />
            </a-tooltip>
          </a-button-group>
        </span>
        <span slot="origin" slot-scope="text, record">
          <a-tag :color="getOriginColor(record.origin)">
            {{ record.origin }}
          </a-tag>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import {
  Col,
  Input,
  Row,
  Icon,
  Button,
  Space,
  Pagination,
  Table,
  Tag,
  Select,
  Checkbox,
  Modal,
  Tooltip,
  notification,
  Menu,
  Dropdown,
} from "ant-design-vue";
import UsersListSelect from "@/components/users-list-select.vue";

import api from "@/api";
import shiftsHelper from "@/helpers/shifts";

import Util from "@/util";

import AssociateShift from "../components/associate-shift.vue";
import ShiftAttachmentsUpload from "@/modules/shifts/components/shift-attachments-upload.vue";
import SendNotification from "@/components/send-notification.vue";
import moment from "moment-timezone";
import shiftAuths from "@/helpers/shift-auths";
import ShiftOverlapModal from "@/modules/shifts/components/shift-overlap-modal.vue";
import AssociateShiftsFilters from "@/modules/shifts/components/shift-filters.vue";
import AddMultipleShifts from "@/modules/shifts/components/shift-multiply-modal.vue";
import CreateNewAssociate from "@/components/create-new-associate.vue";
import {
  getShiftStatusTagLabel,
  getAuthStatusTagColor,
  getShiftStatusTagText,
  statusTagColors,
  vueTourOptions,
} from "@/const";
import PaymentInfo from "@/modules/payment/components/payment-info.vue";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";
import AssociateProfile from "@/components/associate-profile.vue";
import CloneAssociate from "@/components/clone-associate.vue";
import ShiftPayCodeModal from "../components/shift-pay-code-create-modal.vue";
import ShiftViewModal from "../components/shift-view-modal.vue";
import ShiftSignModal from "../components/shift-sign-modal.vue";

const AuthStatus = Object.freeze({
  INITIAL: "initial",
  ACCEPTED: "accepted",
  DECLINED: "declined",
});

const initialPagination = {
  showSizeChanger: true,
  pageSizeOptions: ["5", "10", "20", "50"],
  pageSize: 50,
  total: 0,
  current: 1,
};

function getTableHeaderByKey(key) {
  return `th[key="${key}"]`;
}

export default {
  components: {
    SendNotification,
    ShiftAttachmentsUpload,
    Icon,
    Space,
    UsersListSelect,
    "a-table": Table,
    "a-input": Input,
    "a-input-search": Input.Search,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-row": Row,
    "a-col": Col,
    "a-icon": Icon,
    "a-tag": Tag,
    "a-button": Button,
    "a-button-group": Button.Group,
    "a-pagination": Pagination,
    "a-checkbox": Checkbox,
    "a-tooltip": Tooltip,
    "a-modal": Modal,
    "a-menu": Menu,
    "a-menu-item": Menu.Item,
    "a-dropdown": Dropdown,
    "associate-shift": AssociateShift,
    "shift-overlap-modal": ShiftOverlapModal,
    "associate-shifts-filters": AssociateShiftsFilters,
    "add-multiple-shifts": AddMultipleShifts,
    "create-new-associate": CreateNewAssociate,
    "payment-info": PaymentInfo,
    "grouped-associate-info": GroupedAssociateInfo,
    "associate-profile": AssociateProfile,
    "clone-associate": CloneAssociate,
    "shift-pay-code": ShiftPayCodeModal,
    "shift-view": ShiftViewModal,
    "shift-sign": ShiftSignModal,
  },

  mixins: [api, shiftsHelper],

  props: {
    filterNew: {
      type: Object,
    },
  },

  data() {
    return {
      tableData: [],

      pagination: initialPagination,

      associateShiftsFiltersVisible: false,

      associateNumberOfChangingShift: undefined,

      visibleAssociateShiftModal: false,
      visibleShiftAlreadyExistsModal: false,
      visibleShiftDeletionConfirmationModal: false,
      visiblePayCodeModal: false,
      visibleShiftModal: false,

      creatingNewAssociateShift: false,

      overlappingShifts: [],

      editingAssociateShiftId: undefined,

      cloneAssociateVisible: false,
      cloningAssociateNumber: undefined,

      columns: [
        {
          title: "ID",
          dataIndex: "shift_id",
          key: "shift_id",
          width: 80,
          sorter: true,
          align: "center",
        },
        {
          title: "Associate",
          dataIndex: "name",
          scopedSlots: { customRender: "associate" },
          sorter: true,
          align: "center",
        },
        {
          title: "Job Classification",
          dataIndex: "role",
          key: "role",
          sorter: true,
          align: "center",
        },
        {
          title: "Status",
          key: "status",
          scopedSlots: { customRender: "status" },
          align: "center",
        },
        {
          title: "Authorizations",
          key: "authorizations",
          scopedSlots: { customRender: "authorizations" },
          align: "center",
        },
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          align: "center",
        },
        {
          title: "Origin",
          key: "origin",
          scopedSlots: { customRender: "origin" },
          align: "center",
        },
        {
          title: "Actions",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          align: "center",
        },
      ],

      loading: false,

      associateProfileToSendSms: undefined,

      editingAssociateNumber: undefined,
      associateProfileModalVisible: false,

      visibleAddMultipleShiftsModal: false,
      addMultipleShiftsModalKey: 0,

      visibleNewAssociateModal: false,

      visibleAssociateSelectionModal: false,

      visibleSignModal: false,

      statusesNotInStatusList: ["suspected", "completed_only"],
      tourSteps: [
        {
          target: ".view-header",
          header: {
            title: "Associate Shifts Table",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: this.shiftsTourBeforeStartCallback,
          content:
            "This section displays detailed information about each shift worked by your associates. Click on a shift to see more details.",
        },
        {
          target: getTableHeaderByKey("status"),
          header: {
            title: "Status",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content: `
            <p>Each shift can have various statuses: </p>
            <ul style="text-align: left">
              <li>Scheduled: Shift has been scheduled.</li>
              <li>Started: Shift is currently in progress.</li>
              <li>Missed: Shift has been missed.</li>
              <li>Cancelled: Shift has been cancelled.</li>
              <li>Completed: Completed</li>
              <li>Unpaid: Payment for the shift is pending.</li>
              <li>Paid: Shift has been paid.</li>
              <li>Not Signed: Shift report not signed.</li>
              <li>Auth Issues: There are authorization issues with the shift.</li>
              <li>Ready To Pay: Shift has not been paid.</li>
              <li>Payment Cancelled: Payment was cancelled.</li>
              <li>Payment In Progress: Payment processing is in progress.</li>
              <li>Draft Payment: Payment request is in draft status.</li>

            </ul>
          `,
        },
        {
          target: getTableHeaderByKey("authorizations"),
          header: {
            title: "Authorizations",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content: `
          <p>Authorizations indicate rule violations during the shift. There are various types of authorizations:</p>
          <ul style="text-align: left">
            <li>Early Start</li>
            <li>Late Start</li>
            <li>No Meal Break</li>
            <li>Early Meal Break End</li>
            <li>No Second Meal Break</li>
            <li>Early Second Meal Break End</li>
            <li>Early End</li>
            <li>Late End</li>
            <li>Edit Shift</li>
            <li>Overtime</li>
            <li>Outside of Work Area</li>
            <li>Absence on Shift</li>
            <li>Manually Shift</li>
            <li>Signed Actual Time Discrepancy</li>
            <li>No Rest Break</li>
          </ul>
          `,
        },

        {
          target: "tbody tr .ant-table-row-expand-icon",
          header: {
            title: "Shift Times",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content: "This buttons opens shift details",
        },
        {
          target: '[data-row-key="signed"]',
          header: {
            title: "Signed Times",
          },
          params: {
            placement: "top",
            enableScrolling: false,
          },
          before: this.beforeSignedShiftStep,
          content:
            "This section shows the times signed off by the associate, which they consider accurate.",
        },
        {
          target: '[data-row-key="actual"]',
          header: {
            title: "Actual Times",
          },
          params: {
            placement: "top",
            enableScrolling: false,
          },
          content:
            "These are the times tracked by the application during the shift.",
        },
        {
          target: '[data-row-key="scheduled"]',
          header: {
            title: "Scheduled Times",
          },
          params: {
            placement: "top",
            enableScrolling: false,
          },
          content:
            "These are the times tracked by the application during the shift.",
        },
        {
          target: "#actions-btn-group",
          header: {
            title: "Actions",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content:
            "Here you can perform various actions on the shift. Let's explore each option available.",
        },
        {
          target: "#edit-shift-btn",
          header: {
            title: "Editing a Shift",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          before: this.beforeAuthsButtonStep,
          content:
            "Clicking the 'Edit' button opens a modal window where you can edit the shift details.",
        },
        {
          target: "#shift-times",
          header: {
            title: "Shift Times",
          },
          params: {
            placement: "left",
            enableScrolling: false,
          },
          before: this.beforeEditShiftStep,
          content:
            "Shift Times contains the aforementioned shift times (Signed, Actual, Scheduled).",
        },
        {
          target: "#signed-work-time",
          header: {
            title: "Shift Times",
          },
          params: {
            placement: "right",
            enableScrolling: false,
          },
          before: this.beforeEditShiftStep,
          content: `
          <p>You can modify the shift details using these fields. If you save changes that contradict company policies, authorizations might appear.</p>
          <p>To save a shift with authorisations and release the associate from signing them - use the "Auto Sign" checkbox in the modal confirmation box that will appear when you try to save the shift.</p>
          `,
        },
        {
          target: "#send-msg-btn",
          header: {
            title: "Send Message Button",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content:
            "Use this button to send a message to the associate, requesting them to discuss or take action regarding their shift.",
        },
        {
          target: "#delete-shift-btn",
          header: {
            title: "Delete Shift Button",
          },
          params: {
            placement: "bottom",
            enableScrolling: false,
          },
          content:
            "This button allows you to delete the shift and all its associated information.",
        },
      ],
      callbacks: {
        onStart: this.shiftTourStartCallback,
        onSkip: this.shiftTourFinishCallback,
        onFinish: this.shiftTourFinishCallback,
      },

      signedShiftStepPassed: false,
    };
  },

  computed: {
    statusTagColors() {
      return statusTagColors;
    },
    nestedColumns() {
      return [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
        },
        {
          title: "Date",
          dataIndex: "date",
          key: "date",
          className: "column-wrap",
        },
        {
          title: "Start",
          key: "start",
          customRender: (text, record) => {
            const colorClass =
              record.type === "Signed" && record.signed_start_diff_info
                ? record.signed_start_diff_info.color
                : "";
            const mainDiv = (
              <div class={`${colorClass}`}>
                {record.start && record.start.value
                  ? record.start.value
                  : record.start}
              </div>
            );

            if (record.signed_start_diff_info) {
              const tooltipTitle = `Signed start time differs ${record.signed_start_diff_info.difference} minutes from tracked time.`;
              return (
                <a-tooltip>
                  <template slot="title">{tooltipTitle}</template>
                  {mainDiv}
                </a-tooltip>
              );
            }

            return mainDiv;
          },
        },
        {
          title: "End",
          key: "end",
          customRender: (text, record) => {
            const colorClass =
              record.type === "Signed" && record.signed_end_diff_info
                ? record.signed_end_diff_info.color
                : "";
            const mainDiv = (
              <div class={`${colorClass}`}>
                {record.end && record.end.value ? record.end.value : record.end}
              </div>
            );

            if (record.signed_end_diff_info) {
              const tooltipTitle = `Signed end time differs ${record.signed_end_diff_info.difference} minutes from tracked time`;
              return (
                <a-tooltip>
                  <template slot="title">{tooltipTitle}</template>
                  {mainDiv}
                </a-tooltip>
              );
            }

            return mainDiv;
          },
        },
        {
          title: "Dur",
          dataIndex: "duration",
        },
        {
          title: "Work Hrs",
          dataIndex: "work_hours",
        },
        {
          title: "Pay Hrs",
          dataIndex: "pay_hours",
        },
        {
          title: "L1 Start",
          dataIndex: "first_lunch_start",
          key: "first_lunch_start",
        },
        {
          title: "L1 End",
          dataIndex: "first_lunch_end",
          key: "first_lunch_end",
        },
        {
          title: "L1 Dur",
          dataIndex: "first_lunch_duration",
        },
        {
          title: "L2 Start",
          dataIndex: "second_lunch_start",
          key: "second_lunch_start",
        },
        {
          title: "L2 End",
          dataIndex: "second_lunch_end",
          key: "second_lunch_end",
        },
        {
          title: "L2 Dur",
          dataIndex: "second_lunch_duration",
        },
      ];
    },

    filtersAreEmpty() {
      return Object.values(this.filterNew).every(
        (value) => value === undefined
      );
    },

    status() {
      return this.statusesNotInStatusList.includes(this.filterNew.status)
        ? null
        : this.filterNew.status;
    },

    showCompletedOnly() {
      return this.filterNew.status === "completed_only";
    },

    vueTourOptions() {
      return vueTourOptions;
    },
  },
  watch: {
    filterNew: {
      handler(newVal, prevVal) {
        if (JSON.stringify(newVal) != JSON.stringify(prevVal)) {
          this.pagination.current = 1;
          this.loadData();
        }
      },
      deep: true,
    },
  },
  methods: {
    convertType: shiftAuths.convertType,
    getAuthStatusTagColor,
    getShiftStatusTagLabel,
    getShiftStatusTagText,

    // filters updating

    resetAssociateShiftsFilters() {
      this.loading = true;

      this.$router.replace({ query: {} }).catch(() => {});
    },
    applyFilters(filters) {
      this.loading = true;

      this.hideAssociateShiftsFilterModal();

      this.$router.replace({ query: filters }).catch(() => {});
    },

    getSignedStartDiff(shift) {
      const { raw_start, raw_signed_start } = shift;

      const startTime = moment(raw_start);
      const signedStartTime = moment(raw_signed_start);

      const differenceInMinutes = Math.abs(
        startTime.diff(signedStartTime, "minutes")
      );

      const result = {
        difference: differenceInMinutes,
        color: null,
      };

      if (differenceInMinutes > 30) {
        result.color = "orange-highlight";
      }

      return result;
    },
    getSignedEndDiff(shift) {
      const { raw_end, raw_signed_end } = shift;

      const endTime = moment(raw_end);
      const signedEndTime = moment(raw_signed_end);

      const differenceInMinutes = Math.abs(
        endTime.diff(signedEndTime, "minutes")
      );

      const result = {
        difference: differenceInMinutes,
        color: null,
      };

      if (differenceInMinutes > 30) {
        result.color = "orange-highlight";
      }

      return result;
    },

    updateTotal(newTotal) {
      this.pagination = { ...this.pagination, total: newTotal };
    },

    async loadData(sorter) {
      this.loading = true;

      await this.apiGetAssociateShifts(
        this.filterNew.associateNumber ? [this.filterNew.associateNumber] : [],
        this.filterNew.dateFrom ?? null,
        this.filterNew.dateTo ?? null,
        this.filterNew.role ?? null,
        this.status ?? null,
        this.pagination,
        this.showCompletedOnly,
        sorter
      )
        .then((data) => {
          this.tableData = [];
          this.updateTotal(data.body.total);
          data.body.shifts_info.forEach((shift) => {
            const formattedDurations = this.formatShiftDurations(shift);

            this.tableData.push({
              rowKey: shift.shift_id,
              shift_id: shift.shift_id,
              first_name: Util.capitalize(shift.associate.first_name),
              last_name: Util.capitalize(shift.associate.last_name),
              phone: shift.associate.phone,
              associate_number: shift.associate_number,
              role: shift.associate.role != null ? shift.associate.role : "-",
              date: this.getDateInfo(shift),

              scheduled_date: formattedDurations.scheduled.date,
              actual_date: formattedDurations.actual.date,
              signed_date: formattedDurations.signed.date,

              scheduled_start: formattedDurations.scheduled.start,
              scheduled_end: formattedDurations.scheduled.end,
              actual_start: formattedDurations.actual.start,
              actual_end: formattedDurations.actual.end,
              signed_start: formattedDurations.signed.start,
              signed_end: formattedDurations.signed.end,

              scheduled_duration: shift.scheduled_duration_mins
                ? this.minutesToHoursAndMinutes(shift.scheduled_duration_mins)
                : "-",
              work_duration: shift.work_duration_mins
                ? this.minutesToHoursAndMinutes(shift.work_duration_mins)
                : "-",
              signed_duration: shift.signed_duration_mins
                ? this.minutesToHoursAndMinutes(shift.signed_duration_mins)
                : "-",

              work_hours: this.formatWorkHours(
                shift.signed_start ? moment.utc(shift.signed_start) : null,
                shift.signed_end ? moment.utc(shift.signed_end) : null,
                shift.signed_lunch_start
                  ? moment.utc(shift.signed_lunch_start)
                  : null,
                shift.signed_lunch_end
                  ? moment.utc(shift.signed_lunch_end)
                  : null,
                shift.signed_second_lunch_start
                  ? moment.utc(shift.signed_second_lunch_start)
                  : null,
                shift.signed_second_lunch_end
                  ? moment.utc(shift.signed_second_lunch_end)
                  : null
              ),
              pay_hours: this.formatPayHours(
                shift.scheduled_start
                  ? moment.utc(shift.scheduled_start)
                  : null,
                shift.scheduled_end ? moment.utc(shift.scheduled_end) : null,
                shift.signed_start ? moment.utc(shift.signed_start) : null,
                shift.signed_end ? moment.utc(shift.signed_end) : null,
                shift.signed_lunch_start
                  ? moment.utc(shift.signed_lunch_start)
                  : null,
                shift.signed_lunch_end
                  ? moment.utc(shift.signed_lunch_end)
                  : null,
                shift.signed_second_lunch_start
                  ? moment.utc(shift.signed_second_lunch_start)
                  : null,
                shift.signed_second_lunch_end
                  ? moment.utc(shift.signed_second_lunch_end)
                  : null
              ),

              actual_first_lunch_start:
                formattedDurations.actual.first_lunch_start,
              actual_first_lunch_end: formattedDurations.actual.first_lunch_end,
              signed_first_lunch_start:
                formattedDurations.signed.first_lunch_start,
              signed_first_lunch_end: formattedDurations.signed.first_lunch_end,

              actual_first_lunch_duration: shift.lunch_duration_mins
                ? Util.formatMinutesToHoursAndMinutesDuration(
                    shift.lunch_duration_mins
                  )
                : "-",
              signed_first_lunch_duration: shift.signed_lunch_duration_mins
                ? Util.formatMinutesToHoursAndMinutesDuration(
                    shift.signed_lunch_duration_mins
                  )
                : "-",

              actual_second_lunch_start:
                formattedDurations.actual.second_lunch_start,
              actual_second_lunch_end:
                formattedDurations.actual.second_lunch_end,
              signed_second_lunch_start:
                formattedDurations.signed.second_lunch_start,
              signed_second_lunch_end:
                formattedDurations.signed.second_lunch_end,

              actual_second_lunch_duration: shift.second_lunch_duration_mins
                ? Util.formatMinutesToHoursAndMinutesDuration(
                    shift.second_lunch_duration_mins
                  )
                : "-",
              signed_second_lunch_duration:
                shift.signed_second_lunch_duration_mins
                  ? Util.formatMinutesToHoursAndMinutesDuration(
                      shift.signed_second_lunch_duration_mins
                    )
                  : "-",

              overtimes: this.getOvertimesInfo(shift),
              status: shift.deleted_at !== null ? "deleted" : shift.status,
              pay_code_name: shift.pay_code ? shift.pay_code.name : undefined,
              has_unresolved_auths: shift.has_unresolved_auths,
              is_suspected: shift.is_suspected,
              confirmation: shift.confirmation,
              // "colors": shift.colors,

              raw_start: shift.work_start,
              raw_end: shift.work_end,

              raw_signed_start: shift.signed_start,
              raw_signed_end: shift.signed_end,
              authorizations: shift.authorizations,

              version: shift.version,

              retro_shift: shift.retro_shift,
              payment_request: shift.payment_request,

              user_avatar: shift.associate.user_avatar,
              unverified: shift.associate.is_verified === false,

              origin: shift.origin,

              deleted_at: shift.deleted_at ? true : false,
            });
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => (this.loading = false));
    },

    addShift() {
      this.openAssociateShiftModal();
    },

    openPayCodeModal() {
      this.visiblePayCodeModal = true;
    },

    openShiftModal(shift_id) {
      this.visibleShiftModal = true;
      this.editingAssociateShiftId = shift_id;
    },

    openSignModal() {
      this.visibleSignModal = true;
    },

    afterSignShift() {
      this.loadData();
    },

    getShiftAuthStatusTagColor(auth) {
      if (auth.status == AuthStatus.ACCEPTED) {
        return "rgb(55, 138, 90)";
      } else if (auth.status == AuthStatus.DECLINED) {
        return "red";
      }

      return "";
    },

    handleTableChange(pagination, sorter) {
      this.pagination = { ...pagination };
      this.loadData(sorter);
    },

    expandedRowRender(record) {
      const data = [
        {
          key: "scheduled",
          type: "Scheduled",
          date: record.scheduled_date,
          start: record.scheduled_start,
          end: record.scheduled_end,
          duration: record.scheduled_duration,
          work_hours: "-",
          pay_hours: "-",
          first_lunch_start: "-",
          first_lunch_end: "-",
          first_lunch_duration: "-",
          second_lunch_start: "-",
          second_lunch_end: "-",
          second_lunch_duration: "-",
          // colors: record.colors
        },
        {
          key: "actual",
          type: "Actual",
          date: record.actual_date,
          start: record.actual_start,
          end: record.actual_end,
          duration: record.work_duration,
          work_hours: "-",
          pay_hours: "-",
          first_lunch_start: record.actual_first_lunch_start,
          first_lunch_end: record.actual_first_lunch_end,
          first_lunch_duration: record.actual_first_lunch_duration,
          second_lunch_start: record.actual_second_lunch_start,
          second_lunch_end: record.actual_second_lunch_end,
          second_lunch_duration: record.actual_second_lunch_duration,
          // colors: record.colors
        },
        {
          key: "signed",
          type: "Signed",
          date: record.signed_date,
          start: { value: record.signed_start },
          end: { value: record.signed_end },
          duration: record.signed_duration,
          work_hours: record.work_hours,
          pay_hours: record.pay_hours,
          first_lunch_start: record.signed_first_lunch_start,
          first_lunch_end: record.signed_first_lunch_end,
          first_lunch_duration: record.signed_first_lunch_duration,
          second_lunch_start: record.signed_second_lunch_start,
          second_lunch_end: record.signed_second_lunch_end,
          second_lunch_duration: record.signed_second_lunch_duration,
          signed_start_diff_info: this.getSignedStartDiff(record),
          signed_end_diff_info: this.getSignedEndDiff(record),
          colors: record.colors,
        },
      ];

      return (
        <a-table
          class="expanded-row"
          size="small"
          columns={this.nestedColumns}
          dataSource={data}
          pagination={false}
        />
      );
    },

    getColorClass(color) {
      switch (color) {
        case "yellow":
          return "yellow-cell";
        case "red":
          return "red-cell";
        default:
          return "";
      }
    },

    clearAssociateShiftModalData() {
      this.editingAssociateShiftId = undefined;
      this.creatingNewAssociateShift = false;
      this.associateNumberOfChangingShift = undefined;
    },

    openAssociateShiftModal() {
      if (!this.editingAssociateShiftId) {
        this.creatingNewAssociateShift = true;

        if (!this.associateNumberOfChangingShift) {
          this.associateNumberOfChangingShift = this.filterNew.associateNumber;
        }
      }

      this.visibleAssociateShiftModal = true;
    },

    closeAssociateShiftModal() {
      this.visibleAssociateShiftModal = false;
      this.clearAssociateShiftModalData();
    },

    closeAssociateShiftModalWithChanges() {
      this.loadData();
      this.closeAssociateShiftModal();
    },

    closeAssociateShiftModalToCreateNewAssociate() {
      this.closeAssociateShiftModal();
      this.openNewAssociateModal();
    },

    openNewAssociateModal() {
      this.visibleNewAssociateModal = true;
    },

    showAuthStatusesSetErrorMessage() {
      notification["warning"]({
        message: "Error",
        description:
          "Authorization statuses were not set due to an error. Try again",
      });
    },

    handleShiftExists(overlappingShifts) {
      this.visibleAssociateShiftModal = false;

      this.visibleShiftAlreadyExistsModal = true;
      this.overlappingShifts = overlappingShifts;
    },

    editExistingShift(id) {
      this.visibleShiftAlreadyExistsModal = false;
      this.editingAssociateShiftId = id;
      this.openAssociateShiftModal();
    },

    closeExistingShiftModal() {
      this.visibleShiftAlreadyExistsModal = false;

      if (this.creatingNewAssociateShift) {
        this.visibleAssociateShiftModal = true;
      }
    },

    openSmsSendModal(record) {
      this.associateProfileToSendSms = {
        associate_number: record.associate_number,
        first_name: record.first_name,
        last_name: record.last_name,
      };
    },
    closeSmsModal() {
      this.associateProfileToSendSms = undefined;
    },

    startEditAssociateProfile(associateNumber) {
      this.editingAssociateNumber = associateNumber;
      this.associateProfileModalVisible = true;
    },

    endEditAssociateProfile() {
      this.editingAssociateNumber = undefined;
      this.associateProfileModalVisible = false;
    },

    handleEditedAssociateProfile() {
      this.loadData();
    },

    showAssociateShiftsFilterModal() {
      this.associateShiftsFiltersVisible = true;
    },
    hideAssociateShiftsFilterModal() {
      this.associateShiftsFiltersVisible = false;
    },

    openMultipleShiftsModal() {
      this.addMultipleShiftsModalKey += 1;
      this.visibleAddMultipleShiftsModal = true;
    },

    closeMultipleShiftsModal(duplicatedShifts) {
      this.visibleAddMultipleShiftsModal = false;

      if (duplicatedShifts) {
        this.loadData();
      }
    },

    closeNewAssociateModal(newAssociateNumber) {
      this.visibleNewAssociateModal = false;

      if (newAssociateNumber) {
        this.associateNumberOfChangingShift = newAssociateNumber;
        this.openAssociateShiftModal();
      }
    },

    shiftsTourBeforeStartCallback() {
      return new Promise((resolve, reject) => {
        const checkCondition = () => {
          if (this.tableData && this.tableData.length > 0 && !this.loading) {
            resolve();
          } else {
            setTimeout(checkCondition, 100);
          }
        };
        checkCondition();
      });
    },
    shiftTourStartCallback() {
      this.tourIsOn = true;
    },
    shiftTourFinishCallback() {
      localStorage.setItem("shiftsTourPassed", true);
      this.tourIsOn = false;
    },

    beforeAuthsButtonStep() {
      if (!this.editingAssociateShiftId) {
        return;
      }

      return new Promise((resolve) => {
        this.closeAssociateShiftModal();
        setTimeout(() => {
          resolve();
        }, 500);
      });
    },
    beforeEditShiftStep() {
      if (this.editingAssociateShiftId) {
        return;
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 500);
      });
    },
    beforeSignedShiftStep() {
      if (this.signedShiftStepPassed) {
        return;
      }

      const firstRow = document.querySelector("tbody tr");
      if (firstRow) {
        const buttonDiv = firstRow.querySelector(".ant-table-row-expand-icon");

        if (buttonDiv) {
          buttonDiv.click();
          this.signedShiftStepPassed = true;
        }
      }
    },

    getOriginColor(origin) {
      switch (origin) {
        case "Time Tracker":
          return "#7432FF";
        case "Scheduler":
          return "#52BFFF";
        case "Marketplace":
          return "#FF4646";
      }
    },

    handleTagClick(record) {
      if (record.status == "not_signed") {
        this.openSignModal();
        this.editingAssociateShiftId = record.shift_id;
      }
    },

    openCloneAssociate() {
      this.cloneAssociateVisible = true;
      this.cloningAssociateNumber = this.editingAssociateNumber;
    },

    endCloneAssociate() {
      this.cloneAssociateVisible = false;
      this.cloningAssociateNumber = undefined;
    },
  },
  mounted() {
    const shiftsTourPassed = localStorage.getItem("shiftsTourPassed");
    if (!shiftsTourPassed) {
      this.$tours["shiftsTour"].start();
    }

    this.loadData();
  },
};
</script>

<style scoped>
.role-select {
  min-width: 150px;
}

.auth-tag {
  text-align: center;
}

.auth-tag:hover,
.suspected-tag:hover {
  cursor: pointer;
}

.already-existing-shift-modal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tags-cell {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.table-associate-info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 10px;
}

.table-associate-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
}

.orange-highlight {
  background-color: #ffb546;
}

.expanded-row {
  background-color: #f4f4f4;
}
</style>
