<template>
  <div>
    <a-upload
      name="avatar"
      list-type="picture-card"
      show-upload-list="false"
      :before-upload="beforeUpload"
      @change="handleFileChange"
    >
      <template v-slot:default>
        <a-icon type="plus" />
        <div slot="upload-text">Select Image</div>
      </template>
    </a-upload>
    <a-button type="primary" @click="uploadFiles" :disabled="!fileList.length">Upload Image</a-button>
  </div>
</template>

<script>
import { Upload, Icon, Button, notification } from 'ant-design-vue';
import api from "@/api";

export default {
  mixins: [api],
  components: {
    'a-upload': Upload,
    'a-icon': Icon,
    'a-button': Button
  },
  data() {
    return {
      fileList: []
    };
  },
  methods: {
    showSuccessNotification(description) {
      notification.success({ message: 'Upload Success', description });
    },
    showErrorNotification(description) {
      notification.error({ message: 'Upload Error', description });
    },
    beforeUpload(file) {
      const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJPGorPNG) {
        this.showErrorNotification('You can only upload JPG/PNG files!');
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.showErrorNotification('Image must be smaller than 2MB!');
        return false;
      }

      this.fileList = [file];
      return false;
    },
    handleFileChange(info) {
      this.fileList = info.fileList.slice(-1);
    },
    uploadFiles() {
      if (this.fileList.length > 0) {
        const file = this.fileList[0].originFileObj;
        this.uploadImage({ file });
      }
    },
    uploadImage({ file }) {
      this.apiUploadFiles([file]).then(response => {
        if (response.data && response.data.ids && response.data.ids.length === 1) {
          this.$emit('set-project-logo', response.data.ids[0]);
        } else {
          this.showErrorNotification('Failed to upload the file');
        }
      }).catch(error => {
        this.showErrorNotification('Server error: could not upload the file.');
      });
    }
  }
}
</script>

<style scoped>
.upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
