<template>
  <div>
    <text-form-modal ref="formModal"/>

    <h2 class="view-header">Shifts</h2>
    <a-table
      :scroll="{ y: $tableHeight.value }"
      :columns="columns"
      :data-source="tableData"
      :style="{'width': '30%', 'margin': '0 auto'}"
    />
    <div class="actions">
      <a-button
        icon="reload"
        @click="refresh"
      />
      <a-button @click="addShift">Add shift</a-button>
    </div>
  </div>
</template>

<script>

import {Icon, Button, Space, Table} from 'ant-design-vue';

import api from '@/api';
import TextFormModal from "@/components/text-form-modal";

export default {
  components: {
    'a-button': Button,
    'a-table': Table,
    'text-form-modal': TextFormModal,
    Icon,
    Space,
  },

  mixins: [api],

  data: () => ({
    tableData: [],

    columns: [
      {
        title: 'Code',
        dataIndex: 'code',
        key: 'code',
        width: 100
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description'
      }
    ]
  }),

  methods: {
    refresh() {
      this.loadData();
    },

    loadData() {
      this.tableData = []

      this.apiGetShifts()
        .then((data) => {
          data.body.shifts.sort((a, b) => { return a.id - b.id })
          data.body.shifts.forEach(item =>  this.tableData.push({
            rowKey: item.id,
            "id": item.id,
            "code": item.code,
            "description": item.description
          }))
        })
        .catch(error => {
          console.error(error)
        });
    },

    async addShift() {
      const res = await this.$refs.formModal.show({
        title: 'New shift',
        fields: [
          {
            label: "Code",
            decorator: ['code', { rules: [{ required: true, message: 'Please input code'}] }]
          },
          {
            label: "Description",
            decorator: ['description', { rules: [{ required: true, message: 'Please input description'}] }]
          }
        ]
      })

      if (res != null) {
        this.apiAddShift(res.code, res.description)
          .then(() => this.loadData())
          .catch(error => console.error(error))
      }
    }
  },
  mounted() {
    this.loadData();
  },
};
</script>


<style scoped>
  .ui_table.roles {
    width: 30vw;
    margin: 10px auto;
  }

  .actions > * {
    margin: 0 2px;
  }
</style>
