<template>
  <a-modal
    title="Filters"
    width="600px"
    v-model="isVisible"
    @cancel="cancel"
    :destroy-on-close="true"
  >
    <div class="grid">
      <b class="col-4">Associate</b>
      <a-select
        class="col-8"
        ref="associateSelect"
        v-model="filters.associateNumber"
        show-search
        :allowClear="true"
        placeholder="Associate"
        :show-arrow="false"
        :filter-option="false"
        :not-found-content="null"
        :default-active-first-option="false"
        :dropdown-match-select-width="false"
        :options="foundAssociates"
        :loading="loading"
        @search="fetchAssociates"
        @change="handleSearchAssociatesChange"
        @focus="handleFocusAssociates"
      />

      <b class="col-4">Roles</b>
      <a-select
        class="col-8"
        default-value="0"
        v-model="roleProxy"
        placeholder="Job classification"
        :disabled="loading"
        :allow-clear="true"
      >
        <a-select-option v-for="role in roles" :key="role.id" :value="role.id">
          {{ role.name }}
        </a-select-option>
      </a-select>

      <b class="col-4">Schedule Type</b>
      <a-select
        class="col-8"
        default-value="0"
        v-model="scheduleTypeProxy"
        placeholder="Schedule Type"
        :disabled="loading"
        :allow-clear="true"
      >
        <a-select-option
          v-for="type in scheduleTypes"
          :key="type.id"
          :value="type.id"
        >
          {{ type.label }}
        </a-select-option>
      </a-select>

      <b class="col-4">Active</b>
      <a-checkbox
        class="col-8"
        v-model="filters.isActive"
        :disabled="loading"
      />
      <b class="col-4">Verified</b>
      <a-checkbox
        class="col-8"
        v-model="filters.isVerified"
        :disabled="loading"
      />
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button type="primary" @click="apply"> Apply </a-button>
        <a-button type="danger" :disabled="isEmptyFilters" @click="reset">
          Reset
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import {
  Modal,
  Button,
  Select,
  Checkbox,
  Switch,
  DatePicker,
} from "ant-design-vue";
import api from "@/api";
import { debounce } from "@/helpers/debounce";

const filtersBaseState = Object.freeze({
  associateNumber: undefined,
  status: undefined,
  role: undefined,
  isActive: undefined,
  isVerified: undefined,
  scheduleType: undefined,
});

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "a-select": Select,
    "a-checkbox": Checkbox,
    "a-select-option": Select.Option,
    "a-switch": Switch,
    "a-date-picker": DatePicker,
  },
  mixins: [api],
  data() {
    return {
      filters: { ...filtersBaseState },
      foundAssociates: [],
      loading: false,
      searchUsersTimeout: null,
      isVisible: false,
      roles: this.$store.state.applicationState.projectRoles,
      scheduleTypes: this.$store.state.applicationState.scheduleTypes,
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    currentFilters: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isEmptyFilters() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    },
    roleProxy: {
      get() {
        return this.filters.role ? Number(this.filters.role) : undefined;
      },
      set(value) {
        this.filters.role = value;
      },
    },
    scheduleTypeProxy: {
      get() {
        return this.filters.scheduleType
          ? Number(this.filters.scheduleType)
          : undefined;
      },
      set(value) {
        this.filters.scheduleType = value;
      },
    },
  },
  watch: {
    visible: {
      handler(newValue) {
        this.isVisible = newValue;
      },
      immediate: true,
    },
    currentFilters: {
      handler(newValue) {
        this.filters = {
          ...newValue,
          isActive: newValue.isActive ? true : undefined,
          isVerified: newValue.isVerified ? true : undefined,
        };
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    reset() {
      if (this.isEmptyFilters) {
        this.cancel();
      } else {
        this.$emit("apply", {});
      }
    },
    cancel() {
      this.$emit("update:visible", false);
    },
    apply() {
      this.$emit("apply", this.filters);
    },
    handleSearchAssociatesChange(value) {
      this.filters.associateNumber = value;
      this.$refs.associateSelect.blur();
    },
    fetchAssociates: debounce(async function (value) {
      this.loading = true;

      try {
        const resp = await this.apiSearchAssociate(value);

        this.foundAssociates = resp.data.associate_list.map(
          ({ employee_id, first_name, last_name }) => ({
            label: `[${employee_id}] ${first_name} ${last_name}`,
            key: employee_id,
            value: employee_id,
          })
        );
      } catch (error) {
      } finally {
        this.loading = false;
      }
    }, 1000),
    handleFocusAssociates() {
      if (!this.filters.associate && !this.foundAssociates) {
        this.fetchAssociates();
      }
    },
  },
  mounted() {
    this.fetchAssociates();
  },
};
</script>
