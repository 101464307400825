<template>
  <a-modal title="Filters" width="600px" v-model="visibleModal" @cancel="close">
    <div class="content-view">
      <div class="content-view-block w-full">
        <p>
          <b>Associate</b>
          <a-select
            class="filters-select"
            ref="associateSelect"
            v-model="filters.associateNumber"
            show-search
            :allowClear="true"
            placeholder="Associate"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            :default-active-first-option="false"
            :dropdown-match-select-width="false"
            :options="foundAssociates"
            :loading="loading"
            @search="fetchAssociates"
            @change="handleSearchAssociatesChange"
            @focus="handleFocusAssociates"
          />
        </p>
        <p>
          <b>Auth Type</b>
          <a-select
            v-model="filters.authType"
            placeholder="Auth Type"
            style="min-width: 250px"
            :disabled="loading"
          >
            <a-select-option
              v-for="authType in Object.keys(shiftAuthTypes)"
              :key="authType"
              :value="authType"
            >
              {{ shiftAuthTypes[authType] }}
            </a-select-option>
          </a-select>
        </p>
        <p>
          <b>With Schedule Error</b>
          <a-switch v-model="filters.withScheduleError" />
        </p>
        <p>
          <b>Assign to Me</b>
          <a-switch v-model="filters.assignedToMe" />
        </p>
      </div>
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button type="primary" @click="apply"> Apply </a-button>
        <a-button type="danger" :disabled="disableResetButton" @click="reset">
          Reset
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { Modal, Button, Select, Checkbox, Switch } from "ant-design-vue";
import api from "@/api";

const filtersBaseState = Object.freeze({
  associateNumber: undefined,
  authType: undefined,
  withScheduleError: false,
  assignedToMe: false,
});

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "a-select": Select,
    "a-checkbox": Checkbox,
    "a-select-option": Select.Option,
    "a-switch": Switch,
  },
  mixins: [api],
  data() {
    return {
      filters: { ...filtersBaseState },
      foundAssociates: [],
      loading: false,
      searchUsersTimeout: null,
      visibleModal: this.visible,
    };
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    currentFilters: {
      type: Object,
      required: true,
    },
  },
  computed: {
    shiftAuthTypes() {
      return this.$store.state.applicationState.shiftAuthTypes;
    },
    disableResetButton() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    },
  },
  watch: {
    visible(newVal) {
      this.visibleModal = newVal;
    },
  },
  methods: {
    reset() {
      this.filters = { ...filtersBaseState };
    },
    close() {
      this.$emit("close");
    },
    apply() {
      this.$emit("apply", this.filters);
    },
    handleSearchAssociatesChange(value) {
      this.filters.associateNumber = value;
      this.$refs.associateSelect.blur();
    },
    fetchAssociates(value) {
      if (this.searchUsersTimeout) {
        clearTimeout(this.searchUsersTimeout);
      }
      this.searchUsersTimeout = setTimeout(() => {
        this.loading = true;
        this.apiSearchAssociate(value).then((resp) => {
          this.foundAssociates = resp.data.associate_list.map((user) => ({
            label: `[${user.employee_id}] ${user.first_name} ${user.last_name}`,
            key: user.employee_id,
            value: user.employee_id,
          }));
          this.loading = false;
        });
      }, 1000);
    },
    handleFocusAssociates() {
      if (!this.filters.associate) {
        this.fetchAssociates();
      }
    },
  },
  mounted() {
    this.loading = true;

    this.fetchAssociates();
    this.filters = { ...this.currentFilters };
  },
};
</script>

<style scoped>
.content-view-block {
  max-width: unset;
}

.filters-select {
  width: 250px;
}

.filters-select .ant-select-dropdown {
  width: 200px;
}

.filters-select .ant-select-dropdown-menu-item {
  white-space: normal;
  word-wrap: break-word;
}
</style>
