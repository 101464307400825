<template>
  <a-modal
    title="Filters"
    width="600px"
    v-model="visible"
    @cancel="apply"
  >
    <div class="content-view">
      <div class="content-view-block w-full">
        <p>
          <b>Alert Type</b>
          <a-select
            class="filters-select"
            v-model:value="filters.alertType"
            :allow-clear="true"
            placeholder="Type"
          >
            <a-select-option
              v-for="(alertType, index) in alertTypes"
              :key="index"
              :value="alertType"
            >
              {{ convertAlertTypeToText(alertType) }}
            </a-select-option>
          </a-select>
        </p>
        <p>
          <b>Alert Severity</b>
          <a-select
            class="filters-select"
            v-model:value="filters.alertSeverity"
            :allow-clear="true"
            placeholder="Severity"
          >
            <a-select-option
              v-for="(alertSeverity, index) in alertSeverities"
              :key="index"
              :value="alertSeverity"
            >
              {{ convertAlertSeverityToText(alertSeverity) }}
            </a-select-option>
          </a-select>
        </p>
      </div>
    </div>
    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button
          type="primary"
          @click="apply"
        >
          Apply
        </a-button>
        <a-button
          type="danger"
          :disabled="disableResetButton"
          @click="reset"
        >
          Reset
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { Modal, Button, Select } from "ant-design-vue";
import {alertSeverityMap, alertTypeMap, associateStatusMap} from "@/const";
import api from "@/api";

const filtersBaseState = Object.freeze({
  alertType: undefined,
  alertSeverity: undefined,
});

export default {
  components: {
    'a-modal': Modal,
    'a-button': Button,
    'a-select': Select,
    'a-select-option': Select.Option
  },
  mixins: [api],
  data() {
    return {
      filters: {...filtersBaseState},
      foundAssociates: [],
      loading: false,
      searchUsersTimeout: null,
    }
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    currentFilters: {
      type: Object,
      required: true
    }
  },
  computed: {
    alertSeverities() {
      return Object.keys(alertSeverityMap);
    },
    alertTypes() {
      return Object.keys(alertTypeMap);
    },
    disableResetButton() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    }
  },
  watch: {
    visible(newVal) {
      this.localVisible = newVal;
    },
    localVisible(newVal) {
      this.$emit('update:visible', newVal);
    }
  },
  methods: {
    reset() {
      this.filters = { ...filtersBaseState };
    },
    apply() {
      const filtersToApply = { ...this.filters };
      this.$emit('apply', filtersToApply);
    },

    convertAlertTypeToText(alertType) {
      return alertTypeMap[alertType] ?? alertType;
    },
    convertAlertSeverityToText(alertSeverity) {
      return alertSeverityMap[alertSeverity] ?? alertSeverity;
    }
  },
  mounted() {
    this.filters = { ...this.currentFilters };
  }
}
</script>

<style scoped>
.daily-summary-filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.content-view-block {
  max-width: unset;
}
.filters-select {
  width: 250px;
}

.filters-select .ant-select-dropdown {
  width: 200px;
}

.filters-select .ant-select-dropdown-menu-item {
  white-space: normal;
  word-wrap: break-word;
}
</style>
