var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"flex-grow":"1"}},[_c('a-table',{attrs:{"size":"small","columns":_vm.columns,"data-source":_vm.projects,"loading":_vm.loading,"row-key":_vm.generateRowKey,"row-selection":{
      selectedRowKeys: _vm.currentAssociateNumberAndProjectIdArrayWrapped,
      onChange: _vm.onSelectChange,
      type: 'radio',
    }},scopedSlots:_vm._u([{key:"role",fn:function(text, record){return _c('span',{},[_c('div',{staticClass:"flex flex-column gap-2"},[(record.is_owner === true)?_c('a-tag',{attrs:{"color":"#eb8f92"}},[_vm._v("OWNER")]):_vm._e(),(
            record.associate_role && record.associate_role.name.length > 0
          )?_c('a-tag',{attrs:{"color":"#238e75"}},[_vm._v(" "+_vm._s(record.associate_role.name.toUpperCase())+" ")]):_vm._e()],1)])}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }