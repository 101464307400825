<template>
  <div v-if="kpiStats">
    <div class="content-view">
      <div class="content-view-block">
        <h3>Shifts</h3>
        <p>
          <b>Paid</b>
          <span>{{ paidShiftsCount }}</span>
        </p>
        <p>
          <b>Manual</b>
          <span>{{ kpiStats.manually_created_shifts }}</span>
        </p>
        <p>
          <b>With Auths</b>
          <span>{{ kpiStats.shifts_with_auths }}</span>
        </p>
        <p>
          <b>Total Hours</b>
          <span>{{ kpiStats.total_hours }}</span>
        </p>
      </div>
    </div>
    <div class="content-view">
      <div class="content-view-block">
        <h3>Shifts By Origin</h3>
        <bar-chart
          class="kpi-chart"
          :chart-data="originChartData"
        />
        <p>
          <b>Time</b>
          <span>{{ whereAmIShiftsCount }}</span>
        </p>
        <p>
          <b>Schedule</b>
          <span>{{ scheduleShiftsCount }}</span>
        </p>
        <p>
          <b>Marketplace</b>
          <span>{{ marketplaceShiftsCount }}</span>
        </p>
      </div>
      <div class="content-view-block">
        <h3>Shifts By Status</h3>
        <bar-chart
          class="kpi-chart"
          :chart-data="shiftStatusesChartData"
        />
        <template v-if="shiftByStatuses && shiftByStatuses.length">
          <p
            v-for="status in shiftByStatuses"
            :key="status.item"
          >
            <b>{{ getShiftStatusLabel(status.item) }}</b>
            <span>{{ status.count }}</span>
          </p>
        </template>
        <span v-else>Information is not available</span>
      </div>
    </div>
    <div class="content-view">
      <div class="content-view-block">
        <h3>Shifts By Payment Statuses</h3>
        <bar-chart
          class="kpi-chart"
          :chart-data="shiftPaymentStatusesChartData"
        />
        <template v-if="shiftByPaymentStatuses && shiftByPaymentStatuses.length">
          <p
            v-for="status in shiftByPaymentStatuses"
            :key="status.item"
          >
            <b>{{ getPaymentStatusLabel(status.item) }}</b>
            <span>{{ status.count }}</span>
          </p>
        </template>
        <span v-else>Information is not available</span>
      </div>
      <div class="content-view-block">
        <h3>Auths By Types</h3>
        <bar-chart
          class="kpi-chart"
          :chart-data="authsChartData"
        />
        <template v-if="authsByTypes && authsByTypes.length > 0">
          <p
            v-for="auth in authsByTypes"
            :key="authsByTypes.item"
          >
            <b>{{ getAuthTypeLabel(auth.item) }}</b>
            <span>{{ auth.count }}</span>
          </p>
        </template>
        <span v-else>Information is not available</span>
      </div>
    </div>
    <div class="content-view">
      <div class="content-view-block">
        <h3>Tasks</h3>
        <p>
          <b>Completed</b>
          <span>{{ kpiStats.completed_tasks }}</span>
        </p>
        <p>
          <b>Completed Assigned</b>
          <span>{{ kpiStats.assigned_tasks }}</span>
        </p>
      </div>
      <div class="content-view-block">
        <h3>Problems</h3>
        <p>
          <b>Shifts with Auths</b>
          <span>{{ kpiStats.shifts_with_auths }}</span>
        </p>
        <p>
          <b>Write-Ups</b>
          <span>{{ kpiStats.writeup_count }}</span>
        </p>
      </div>

      <div class="content-view-block">
        <h3>Payment</h3>

        <p>
          <b>Accepted PRs</b>
          <span>{{ kpiStats.accepted_payment_requests }}</span>
        </p>
        <p>
          <b>Declined PRs</b>
          <span>{{ kpiStats.declined_payment_requests }}</span>
        </p>
        <p>
          <b>Received Money, $</b>
          <span>{{ kpiStats.total_paid / 100 }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
const SHIFT_COUNT_LABEL = 'Shifts Count';

import BarChart from '@/components/bar-chart.vue';

import {
  authorizationsMap,
  shiftStatusesMap,
  paymentStatusesMap,
} from "@/const";

export default {
  props: ['kpiStats'],
  components: {'bar-chart': BarChart},
  computed: {
    whereAmIShiftsCount() {
      const foundWhereAmIShiftsInfo = this.kpiStats.shift_by_origins.find((origin) => origin.item === 'whereami');

      return foundWhereAmIShiftsInfo ? foundWhereAmIShiftsInfo.count : 0;
    },
    scheduleShiftsCount() {
      const foundScheduleShiftsInfo = this.kpiStats.shift_by_origins.find((origin) => origin.item === 'schedule');

      return foundScheduleShiftsInfo ? foundScheduleShiftsInfo.count : 0;
    },
    marketplaceShiftsCount() {
      const foundMarketplaceShiftsInfo = this.kpiStats.shift_by_origins.find((origin) => origin.item === 'marketplace');

      return foundMarketplaceShiftsInfo ? foundMarketplaceShiftsInfo.count : 0;
    },

    shiftByStatuses() {
      return this.kpiStats.shift_by_statuses;
    },
    shiftByPaymentStatuses() {
      return this.kpiStats.shift_by_payment_statuses;
    },

    paidShiftsCount() {
      const paidShiftsItem = this.shiftByPaymentStatuses.find((status) => status.item == 'paid');

      return paidShiftsItem ? paidShiftsItem.count : 0;
    },

    authsByTypes() {
      return this.kpiStats.authorization_by_types;
    },

    originChartData() {
      const labels = [];
      const data = [];
      const colors = [];

      if (this.whereAmIShiftsCount) {
        labels.push('Time');
        colors.push('#FF6384');
        data.push(this.whereAmIShiftsCount);
      }

      if (this.scheduleShiftsCount) {
        labels.push('Schedule');
        colors.push('#36A2EB');
        data.push(this.scheduleShiftsCount);
      }

      if (this.marketplaceShiftsCount) {
        labels.push('Marketplace');
        colors.push('#FFCE56');
        data.push(this.marketplaceShiftsCount);
      }

      return {
        labels,
        datasets: [
          {
            label: SHIFT_COUNT_LABEL,
            backgroundColor: colors,
            data
          }
        ]
      }
    },
    shiftStatusesChartData() {
      const labels = [];
      const data = [];
      const colors = [];

      this.shiftByStatuses.forEach(st => {
        labels.push(this.getShiftStatusLabel(st.item));
        colors.push(this.getShiftStatusColor(st.item));
        data.push(st.count);
      });

      return {
        labels,
        datasets: [
          {
            label: SHIFT_COUNT_LABEL,
            backgroundColor: colors,
            data
          }
        ]
      }
    },
    shiftPaymentStatusesChartData() {
      const labels = [];
      const data = [];
      const colors = [];

      this.shiftByPaymentStatuses.forEach(pt => {
        labels.push(this.getPaymentStatusLabel(pt.item));
        colors.push(this.getPaymentStatusColor(pt.item));
        data.push(pt.count);
      });

      return {
        labels,
        datasets: [
          {
            label: SHIFT_COUNT_LABEL,
            backgroundColor: colors,
            data
          }
        ]
      }
    },
    authsChartData() {
      const labels = [];
      const data = [];
      const colors = [];

      this.authsByTypes.forEach(au => {
        labels.push(this.getAuthTypeLabel(au.item));
        colors.push(this.getAuthTypeColor(au.item));
        data.push(au.count);
      });

      return {
        labels,
        datasets: [
          {
            label: 'Auths Count',
            backgroundColor: colors,
            data
          }
        ]
      }
    }
  },
  methods: {
    getShiftStatusLabel(status) {
      return shiftStatusesMap[status] ? shiftStatusesMap[status].label : status;
    },
    getPaymentStatusLabel(status) {
      return paymentStatusesMap[status] ? paymentStatusesMap[status].label : status;
    },
    getAuthTypeLabel(type) {
      return authorizationsMap[type] ? authorizationsMap[type].label : type;
    },

    getShiftStatusColor(status) {
      return shiftStatusesMap[status] ? shiftStatusesMap[status].color : '#bbbbbb';
    },
    getPaymentStatusColor(status) {
      return paymentStatusesMap[status] ? paymentStatusesMap[status].color : '#bbbbbb';
    },
    getAuthTypeColor(type) {
      return authorizationsMap[type] ? authorizationsMap[type].color : '#bbbbbb';
    }

  }
}
</script>

<style scoped>
.content-view {
  width: 900px !important;
}

.kpi-chart {
  width: 400px;
  margin-bottom: 10px;
}
</style>
