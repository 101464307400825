import apiClient from "@/services/api";

export default {
  async fetchPayCodes({ commit, rootState }, { filter = { attached: null } }) {
    try {
      commit("setLoadingPayCodes", true);

      const { data } = await apiClient.post("api/project/pay_code/get_all", {
        project_id: rootState.applicationState.currentProject.id,
        filter,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setPayCodes", data.pay_codes);
    } catch (error) {
      throw error.message;
    } finally {
      commit("setLoadingPayCodes", false);
    }
  },

  async deletePayCode({ commit, state, rootState }, { pay_code_id }) {
    try {
      const { data } = await apiClient.post("/api/project/pay_code/delete", {
        project_id: rootState.applicationState.currentProject.id,
        pay_code_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while deleting the pay code.Please try again later"
        );
      }

      commit(
        "setPayCodes",
        state.payCodes.filter((payCode) => payCode.id !== pay_code_id)
      );
    } catch (error) {
      throw error.message;
    }
  },

  async updatePayCode({ commit, state, rootState }, { pay_code }) {
    try {
      const { data } = await apiClient.post("/api/project/pay_code/update", {
        project_id: rootState.applicationState.currentProject.id,
        pay_code,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while editing the pay code. Please try again later."
        );
      }

      const updatedPayCodes = state.payCodes.map((payCode) =>
        payCode.id === pay_code.id ? { ...payCode, ...data.pay_code } : payCode
      );

      commit("setPayCodes", updatedPayCodes);
    } catch (error) {
      throw error.message;
    }
  },

  async createPayCode({ commit, state, rootState }, { pay_code }) {
    try {
      const { data } = await apiClient.post("/api/project/pay_code/create", {
        project_id: rootState.applicationState.currentProject.id,
        pay_code,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while saving the pay code. Please try again later."
        );
      }

      commit("setPayCodes", [data.pay_code, ...state.payCodes]);
    } catch (error) {
      throw error.message;
    }
  },

  async fetchPayCode({ commit, rootState }, { pay_code_id }) {
    try {
      const { data } = await apiClient.post("/api/project/pay_code/get", {
        project_id: rootState.applicationState.currentProject.id,
        pay_code_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while saving the pay code. Please try again later."
        );
      }

      commit("setPayCode", data.pay_code);
    } catch (error) {
      throw error.message;
    }
  },
};
