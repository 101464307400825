<template>
  <a-modal
    title="Shift Deletion"
    :visible="visible"
    :closable="false"
    @cancel="closeDeletionModal"
    :destroy-on-close="true"
  >
    <div>
      <span v-if="version && version > 1">
        Are you sure you want to delete shift #{{ shiftId }}
        or just revoke last signature?
      </span>
      <span v-else-if="version && version == 1">
        Are you sure you want to delete shift #{{ shiftId }}?
      </span>
    </div>
    <template slot="footer">
      <a-button type="danger" @click="deleteSelectedShift"
        >Delete Shift</a-button
      >
      <a-button
        v-if="version && version > 1"
        type="danger"
        @click="deleteSelectedVersion"
      >
        Revoke Signature
      </a-button>
      <a-button type="primary" @click="closeDeletionModal">No</a-button>
    </template>
  </a-modal>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { Button, notification } from "ant-design-vue";

const { mapActions } = createNamespacedHelpers("shifts");

export default {
  components: {
    "a-button": Button,
  },
  props: {
    shiftId: {
      type: Number,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Function,
    },
    version: {
      type: Number,
      default: 1,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["deleteShift", "deleteVersion"]),

    closeDeletionModal() {
      this.$emit("update:visible", false);
    },

    async handleDeletion(action, successMessage) {
      try {
        await action();
        this.showNotification("success", "Success", successMessage);
        this.closeDeletionModal();
        this.$emit("edit");
      } catch (error) {
        this.showNotification("error", "Error", error.message || error);
      }
    },

    async deleteSelectedShift() {
      await this.handleDeletion(
        () => this.deleteShift({ shift_id: this.shiftId }),
        `Shift #${this.shiftId} has been successfully deleted`
      );
    },

    async deleteSelectedVersion() {
      await this.handleDeletion(
        () =>
          this.deleteVersion({ shift_id: this.shiftId, version: this.version }),
        `Last signature of #${this.shiftId} has been successfully deleted`
      );
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },
  },
};
</script>
