<template>
  <div class="flex flex-column gap-2">
    <a-form-model-item
      :colon="false"
      label-align="left"
      label="Associates"
      prop="associates"
    >
      <a-select
        mode="multiple"
        show-search
        v-model="associatesProxy"
        :autoClearSearchValue="false"
        :allow-clear="true"
        :loading="loadingAssociated"
        :filter-option="false"
        placeholder="Associates"
        @search="searchAssociates"
        @focus="focusAssociates"
      >
        <a-select-option
          v-for="user in users"
          :key="user.employee_id"
          :value="user.employee_id"
        >
          {{ `${user.first_name} ${user.last_name}` }}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item
      :colon="false"
      label-align="left"
      label="Dates"
      prop="dates"
    >
      <a-date-picker
        v-model="currentDate"
        format="MM/DD/YYYY"
        :allowClear="false"
        placeholder="Select Dates"
        @change="changeDates"
      >
        <!-- <template slot="dateRender" slot-scope="current">
            <div class="ant-calendar-date" :style="highlightDate(current)">
              {{ current.date() }}
            </div>
          </template> -->
      </a-date-picker>
    </a-form-model-item>

    <div v-if="dates && dates.length" class="date-container">
      <a-tag
        closable
        v-for="(date, index) in dates"
        @close="deleteDate(date, $event)"
        :key="index"
        >{{ formatDate(date) }}</a-tag
      >
    </div>
  </div>
</template>

<script>
import { debounce } from "@/helpers/debounce";
import {
  DatePicker,
  FormModel,
  notification,
  Select,
  Tag,
} from "ant-design-vue";
import moment from "moment-timezone";
import { createNamespacedHelpers } from "vuex";

const { mapState: searchState, mapActions: searchActions } =
  createNamespacedHelpers("search");

export default {
  components: {
    "a-form-model-item": FormModel.Item,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-date-picker": DatePicker,
    "a-tag": Tag,
  },
  data() {
    return {
      loadingAssociated: false,

      currentDate: null,
    };
  },
  props: {
    dates: {
      type: Array,
      default: [],
    },
    associates: {
      type: Array,
      default: [],
    },
  },
  computed: {
    ...searchState({
      users: (state) => state.users,
    }),

    associatesProxy: {
      get() {
        return this.associates ? this.associates : undefined;
      },
      set(value) {
        this.$emit("update:associates", value);
      },
    },
  },
  methods: {
    ...searchActions(["getUsers"]),

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    // work with datePicker

    changeDates(date) {
      const formattedDate = moment(date).startOf("day").utc(true).toISOString();

      if (this.dates.includes(formattedDate)) {
        this.deleteDate(formattedDate);
      } else {
        const updatedDates = [...this.dates, formattedDate];

        this.$emit("update:dates", updatedDates);
      }

      this.currentDate = null;
    },

    deleteDate(dateToRemove, event) {
      if (event) event.preventDefault();

      const updatedDates = [
        ...this.dates.filter((date) => date !== dateToRemove),
      ];

      this.$emit("update:dates", updatedDates);
    },

    formatDate(date) {
      return moment(date).format(this.$store.state.applicationState.dateFormat);
    },

    // works with associates

    searchAssociates: debounce(function (value) {
      this.fetchAssociates(value);
    }, 1000),

    focusAssociates() {
      if (!this.users || !this.users.length) {
        this.fetchAssociates("");
      }
    },

    async fetchAssociates(value) {
      this.loadingAssociated = true;
      try {
        await this.getUsers({ query: value });
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingAssociated = false;
      }
    },
  },
  mounted() {
    if (!this.users.length) this.fetchAssociates("");
  },
};
</script>

<style scoped>
.ant-row {
  display: flex;
  justify-content: space-between;
  margin: 0 !important;
}

.ant-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}

.ant-calendar-picker {
  margin: 0;
  width: 100%;
}

.ant-tag {
  margin: 0;
}

.date-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background-color: #fff;
  padding: 10px;
}

.ant-input-number {
  width: 100%;
}
</style>
