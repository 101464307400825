import apiClient from "@/services/api";

export default {
  async fetchRoles({ commit }) {
    try {
      commit("setLoadingRoles", true);

      const { data } = await apiClient.post("/api/tc_get_not_adm_roles");

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while loading the job classifications. Please try again later"
        );
      }

      commit("setRoles", data.roles);
    } catch (error) {
      throw error.message;
    } finally {
      commit("setLoadingRoles", false);
    }
  },

  async deleteRole({ commit, state }, { id }) {
    try {
      const { data } = await apiClient.post("/api/tc_delete_role", {
        role: { id },
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while deleting the job classification. Please try again later"
        );
      }

      commit(
        "setRoles",
        state.roles.filter((role) => role.id !== id)
      );
    } catch (error) {
      throw error.message;
    }
  },

  async updateRole({ commit, state }, { id, name, code, permissions }) {
    try {
      const { data } = await apiClient.post("/api/tc_update_role", {
        role: { id, name, code, role_properties: permissions },
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while editing the job classification. Please try again later."
        );
      }

      const updatedRoles = state.roles.map((role) =>
        role.id === id ? { ...role, ...data.role } : role
      );

      commit("setRoles", updatedRoles);
    } catch (error) {
      throw error.message;
    }
  },

  async createRole({ commit, state }, { name, code, permissions }) {
    try {
      const { data } = await apiClient.post("/api/tc_add_not_adm_role", {
        name,
        code,
        role_properties: permissions,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while saving the job classification. Please try again later."
        );
      }

      commit("setRoles", [data.role, ...state.roles]);
    } catch (error) {
      throw error.message;
    }
  },
};
