import { ADJUSTMENT_STATUS, PAYROLL_STATUS } from "./constants";

export default {
  getPayrollStatus(state) {
    return (status) => {
      return PAYROLL_STATUS[status ?? "created"];
    };
  },

  getAdjustmentStatus(state) {
    return (id) => {
      const status =
        state.adjustments.find((item) => item.id === id)?.status ?? "initial";

      return ADJUSTMENT_STATUS[status];
    };
  },

  getAdjustmentShift(state) {
    return (id) => state.adjustmentsShifts[id] ?? {};
  },

  getShiftsByType(state) {
    return (type) => state.shifts[type] ?? {};
  },

  getActivePayrollId(state) {
    return state.activePayroll?.id ?? null;
  },

  isPaidPayroll(state) {
    return state.activePayroll.status === "paid";
  },

  isCompletePayroll(state) {
    return state.activePayroll.status === "completed";
  },

  isSignCompetedPayroll(state) {
    return state.activePayroll.status === "sign_completed";
  },
};
