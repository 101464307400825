<template>
  <span
    class="flex gap-1 justify-content-center flex-column w-fit"
    v-if="hasHistory"
  >
    <span class="flex gap-1" v-if="isDetailAuth">
      <span class="value"
        >{{ history.total }}/{{ history.last_month_shifts_count }}</span
      >
      <span class="flex w-fit">(Auths/Shifts)</span>
    </span>
    <span class="flex gap-1 white-space-nowrap	" v-else>
      <span class="value">{{ history.total }} </span>
      <span class="flex w-fit">auths over </span>
      <span class="value">{{ history.last_month_shifts_count }} </span>
      <span class="flex w-fit">shifts </span>
    </span>
    <span class="flex flex-column gap-1 w-fit" v-if="!isManyAuth"
      ><span v-for="item in filteredTags" :key="item.label">
        <span class="custom-tag" v-if="item.value > 0">
          <a-tag :color="item.color" class="m-0">
            <span>{{ item.label }}</span>
          </a-tag>
          <span class="value"> - {{ item.value }}</span>
        </span>
      </span></span
    >
  </span>
  <span v-else> - </span>
</template>

<script>
import { Tag } from "ant-design-vue";
import { statusTagColors } from "@/const";

export default {
  components: {
    "a-tag": Tag,
  },
  props: {
    history: {
      type: Object,
    },
    isManyAuth: {
      type: Boolean,
      default: false,
    },
    isDetailAuth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasHistory() {
      return this.history && this.history.total > 0;
    },
    computedTags() {
      const { initial, approved, declined } = this.history;

      return [
        {
          label: "Initial ",
          value: initial,
          color: statusTagColors.GRAY,
        },
        {
          label: "Accepted ",
          value: approved,
          color: statusTagColors.GREEN,
        },
        {
          label: "Declined ",
          value: declined,
          color: statusTagColors.RED,
        },
      ];
    },
    filteredTags() {
      return this.computedTags.filter((item) => item.value > 0);
    },
  },
};
</script>

<style scoped>
.custom-tag {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  width: fit-content;
}

.value {
  margin-left: auto;
  font-weight: bold;
}

.custom-tag .ant-tag {
  font-size: 14px;
}
</style>
