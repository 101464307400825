<template>
  <div class="flex flex-column gap-2 pl-5 w-6">
    <h3>Permissions</h3>
    <permissions-info
      :permissions.sync="selectedPermissions"
      :is-disabled="isDisabled"
    />
  </div>
</template>

<script>
import RolePermissions from "@/modules/roles/components/role-permissions.vue";

export default {
  components: {
    "permissions-info": RolePermissions,
  },
  data() {
    return {
      selectedPermissions: this.permissions,
    };
  },
  props: {
    permissions: {
      type: Array,
      required: true,
      default: [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedPermissions() {
      this.$emit("change", this.selectedPermissions);
    },
  },
};
</script>
