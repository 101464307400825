export default {
  setShiftAuthorizations(state, payload) {
    state.shiftAuthorizations = payload;
  },
  setShifts(state, payload) {
    state.shifts = payload;
  },
  setShift(state, payload) {
    state.shift = payload;
  },
  setShiftPayCodes(state, payload) {
    state.shiftPayCodes = payload;
  },
  setShiftCalculations(state, payload) {
    state.shiftCalculations = payload;
  },
  setShiftPayments(state, payload) {
    state.shiftPayments = payload;
  },
  setShiftActions(state, payload) {
    state.shiftActions = payload;
  },
};
