<template>
  <div>
    <pbj-explanation-modal :visible="pbjExplanationModalVisible" @close="closePbjExplanationModal"/>

    <h2 class="view-header">PBJ Report</h2>
    <div class="table_controls_and_data">
      <div class="table_data_wrapper">
      </div>
      <div class="table_controls_wrapper">
        <div class="table_predefined_filters">
          <a-checkbox v-model="ignoreAuthsIssues">Ignore Auths Issues</a-checkbox>
          <a-button
            icon="file-text"
            @click="openPbjExplanationModal"
          >
            Explanation
          </a-button>
          <a-button
            icon="file-excel"
            :disabled="disableExcelButton"
            @click="downloadExcelFile"
          >
            Excel
          </a-button>
          <a-date-picker
            class="pbj-date-picker"
            placeholder="Start Date"
            v-model="dateFrom"
            :disabled="loading"
            :format="dateFormat"
            :disabled-date="disabledStartDate"
          />
          <a-date-picker
            class="pbj-date-picker"
            placeholder="End Date"
            v-model="dateTo"
            :disabled="loading"
            :format="dateFormat"
            :disabled-date="disabledEndDate"
          />

          <a-select
            v-model="selectedPayTypes"
            mode="multiple"
            placeholder="Select Pay Type"
            :disabled="loading"
          >
            <a-select-option :value="exemptPayType">Exempt</a-select-option>
            <a-select-option :value="nonExemptPayType">Non-Exempt</a-select-option>
            <a-select-option :value="contractPayType">Contract</a-select-option>
          </a-select>
        </div>
      </div>
    </div>
    <a-table
      size="small"
      :loading="loading"
      :columns="columns"
      :data-source="pbjReportRecords"
      :pagination="false"
      :locale="{ emptyText: tableEmptyText }"
    />
  </div>
</template>

<script>
import {Table, Button, notification, DatePicker, Modal, Select, Switch, Checkbox} from 'ant-design-vue';
import api from "@/api";
import moment from "moment-timezone";
import ConstData from '@/helpers/const-data';
import PbjExplanationModal from "@/components/pbj-explanation-modal.vue";

const EXEMPT_PAY_TYPE = 1;
const NON_EXEMPT_PAY_TYPE = 2;
const CONTRACT_PAY_TYPE = 3;

export default {
  components: {
    PbjExplanationModal,
    'a-table': Table,
    'a-button': Button,
    'a-date-picker': DatePicker,
    'a-modal': Modal,
    'a-select': Select,
    'a-select-option': Select.Option,
    'a-checkbox': Checkbox
  },
  mixins: [api],
  data() {
    return {
      loading: false,
      columns: [
        {
          title: 'Associate Number',
          dataIndex: 'associate_number'
        },
        {
          title: 'Date',
          dataIndex: 'date'
        },
        {
          title: 'Hours',
          dataIndex: 'hours'
        },
        {
          title: 'Job Title Code',
          dataIndex: 'job_title_code'
        },
        {
          title: 'Pay Type Code',
          dataIndex: 'pay_type_code'
        },
        {
          title: 'Associate Name',
          dataIndex: 'associate_name'
        }
      ],

      pbjReportRecords: [],
      excelReportLink: undefined,

      dateFrom: undefined,
      dateTo: undefined,

      pbjExplanationModalText: ConstData.pbjExplanationModalText,
      pbjExplanationModalVisible: false,

      exemptPayType: EXEMPT_PAY_TYPE,
      nonExemptPayType: NON_EXEMPT_PAY_TYPE,
      contractPayType: CONTRACT_PAY_TYPE,

      selectedPayTypes: [EXEMPT_PAY_TYPE, NON_EXEMPT_PAY_TYPE, CONTRACT_PAY_TYPE],

      ignoreAuthsIssues: false
    }
  },
  watch: {
    dateFrom(newVal, oldVal) {
      if (!newVal || !newVal.isSame(oldVal, 'day')) {
        this.loadData();
      }
    },
    dateTo(newVal, oldVal) {
      if (!newVal || !newVal.isSame(oldVal, 'day')) {
        this.loadData();
      }
    },
    selectedPayTypes() {
      this.loadData();
    },
    ignoreAuthsIssues() {
      this.loadData();
    }
  },

  computed: {
    disableExcelButton() {
      return !this.excelReportLink;
    },
    serverDateFormat() {
      return this.$store.state.applicationState.serverDateFormat;
    },
    dateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    serverDateFormatDateFrom() {
      return this.dateFrom ? this.dateFrom.format(this.serverDateFormat) : undefined;
    },
    serverDateFormatDateTo() {
      return this.dateTo ? this.dateTo.format(this.serverDateFormat) : undefined;
    },
    tableEmptyText() {
      let text = '';

      if (this.loading) {
        text = 'Data is being loaded. Please wait';
      } else if (!this.dateFrom || !this.dateTo) {
        text = 'Please select a valid date range';
      } else {
        text = 'No data found';
      }
      return text;
    }
  },
  methods: {
    loadData() {
      this.loading = true;

      this.apiGeneratePbjReport(
        this.serverDateFormatDateFrom,
        this.serverDateFormatDateTo,
        this.selectedPayTypes,
        this.ignoreAuthsIssues
      )
        .then(response => {
          this.pbjReportRecords = response.body.report_records;
          this.excelReportLink = response.body.excel_report_link;
        })
        .finally(() => this.loading = false)
    },
    downloadExcelFile() {
      if (!this.excelReportLink) {
        notification['warning']({
          message: 'Download Error'
        })
      }

      window.open(this.excelReportLink, '_blank')
    },

    disabledStartDate(startValue) {
      const endValue = this.dateTo;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.dateFrom;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },

    openPbjExplanationModal() {
      this.pbjExplanationModalVisible = true;
    },
    closePbjExplanationModal() {
      this.pbjExplanationModalVisible = false;
    },

    clearFilters() {
      this.loading = false;

      this.dateFrom = undefined;
      this.dateTo = undefined;
    }
  },
  mounted() {
    this.loadData();
  }
}
</script>

<style scoped>
.pbj-date-picker {
  min-width: unset !important;
  width: 150px;
}

.ant-table-content .ant-table-placeholder {
  font-weight: bold;
}
</style>
