export const roleEnum = Object.freeze({
  MANAGE_ASSOCIATES: "manage_associates",
  MANAGE_DOCUMENTS: "manage_documents",
  MANAGE_AUTHORIZATIONS: "manage_authorizations",
  MANAGE_PROJECT: "manage_project",
  SEND_PUSH_NOTIFICATION: "send_push_notification",
  INVITE_USER: "invite_user",
  VALIDATE_PAYMENTS: "validate_payments",
  PROCESS_PAYMENTS: "process_payments",
  FLOATING_WORK_AREA: "floating_work_area",
  RESOLVE_ALERTS: "resolve_alerts",
  REQUIRE_ATTACH_FILES: "require_attach_files",
  IGNORE_SHIFT_TIME_AUTHS: "ignore_shift_time_auths",
  SHIFTS_FROM_SCHEDULER: "shifts_from_scheduler",
  MOBILE_TIME_TRACKING: "mobile_time_tracking",
  REQUIRE_SHIFT_CONFIRMATION: "require_shift_confirmation",
});

export const roleDescriptionEnum = Object.freeze({
  MANAGE_ASSOCIATES: "Can view / edit associates' profiles.",
  MANAGE_DOCUMENTS:
    "Can view / edit / add / send to sign documents. Can manage Onboarding / Departure process for associates.",
  MANAGE_AUTHORIZATIONS:
    "Can view / accept / deny action authorizations from associates.",
  MANAGE_PROJECT: "Can edit company settings and information.",
  SEND_PUSH_NOTIFICATION: "Can send notifications to associates.",
  INVITE_USER: "Can invite users to company.",
  VALIDATE_PAYMENTS: "Can validate associates' payment requests.",
  PROCESS_PAYMENTS: "Can process associates' payment requests.",
  FLOATING_WORK_AREA: "Can work from any location.",
  RESOLVE_ALERTS: "Can view / resolve any company's alerts.",
  REQUIRE_ATTACH_FILES: "Associate should attach files when signing a shift.",
  IGNORE_SHIFT_TIME_AUTHS:
    "Associate can ignore shift start / end authorizations.",
  SHIFTS_FROM_SCHEDULER: "Associate can get shifts from Scheduler App",
  MOBILE_TIME_TRACKING:
    "Associate can clock in / clock out using mobile app. Otherwise associate required to use terminal.",
  REQUIRE_SHIFT_CONFIRMATION:
    "Associate is required to get the shift confirmed before signing it.",
});

export const roleTextEnum = Object.freeze({
  MANAGE_ASSOCIATES: "Manage Associates",
  MANAGE_DOCUMENTS: "Manage Documents",
  MANAGE_AUTHORIZATIONS: "Manage Authorizations",
  MANAGE_PROJECT: "Manage Company",
  SEND_PUSH_NOTIFICATION: "Send Notification",
  INVITE_USER: "Invite User",
  VALIDATE_PAYMENTS: "Validate Payments",
  PROCESS_PAYMENTS: "Process Payments",
  FLOATING_WORK_AREA: "Floating Work Area",
  RESOLVE_ALERTS: "Resolve Alerts",
  REQUIRE_ATTACH_FILES: "Requires Additional Info",
  IGNORE_SHIFT_TIME_AUTHS: "Ignore Clock on / out Authorizations",
  SHIFTS_FROM_SCHEDULER: "Shifts from Scheduler App",
  MOBILE_TIME_TRACKING: "Mobile Time Tracking",
  REQUIRE_SHIFT_CONFIRMATION: "Require Shift Confirmation",
});
