import { render, staticRenderFns } from "./document-send-modal.vue?vue&type=template&id=53baaa4f&"
import script from "./document-send-modal.vue?vue&type=script&lang=js&"
export * from "./document-send-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports