const loadStatePlugin = (store) => {
  // Load the state from localStorage when the store is initialized
  const savedState = localStorage.getItem("applicationState");
  if (savedState) {
    const parsedState = JSON.parse(savedState);
    store.commit("setApplicationState", parsedState);
  }

  // Function to automatically update the state every hour
  const updateApplicationState = async () => {
    try {
      await store.dispatch("loadApplicationState");
      localStorage.setItem(
        "applicationState",
        JSON.stringify(store.state.applicationState)
      );
    } catch (error) {}
  };

  // Set an interval for automatic state updates
  const intervalId = setInterval(updateApplicationState, 3600000);

  // Clear the interval when the store is destroyed
  store.subscribe((mutation, state) => {
    if (mutation.type === "clearApplicationState") {
      clearInterval(intervalId);
    }
  });
};

export default loadStatePlugin;
