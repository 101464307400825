<template>
  <div>
    <h3>Calculations</h3>
    <a-table
      :rowKey="(record) => record.id"
      :locale="{ emptyText: 'Calculations Not Found' }"
      size="small"
      :columns="columns"
      :data-source="calculations"
      :pagination="false"
      :scroll="{ y: 300 }"
    >
      <template slot="footer">
        <div class="summary">
          <span>Total </span>
          <span class="summary_item">${{ totalSum }}</span>
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { Table } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";

const { mapState: shiftState } = createNamespacedHelpers("shifts");

export default {
  components: {
    "a-table": Table,
  },
  data() {
    return {
      columns: [
        {
          title: "Name",
          dataIndex: "name",
        },
        {
          title: "Coefficient",
          dataIndex: "coeff",
          align: "center",
          width: "100px",
        },
        {
          title: "Hours",
          dataIndex: "hours",
          align: "center",
          width: "100px",
        },
        {
          title: "Amount",
          dataIndex: "total",
          align: "center",
          width: "100px",
          customRender: (_, record) => {
            return `$${record.total / 100}`;
          },
        },
      ],
    };
  },
  computed: {
    ...shiftState({
      calculations: (state) => state.shiftCalculations,
    }),

    totalSum() {
      return this.calculations.reduce(
        (sum, record) => sum + record.total / 100,
        0
      );
    },
  },
};
</script>

<style scoped>
.summary {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.summary_item {
  width: 100px;
  text-align: center;
  padding: 0 8px;
}

::v-deep .ant-table-footer {
  padding: 8px;
}

::v-deep .ant-table-header {
  scrollbar-width: none;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 8px;
}
</style>
