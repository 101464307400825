<template>
	<transition name="modal"  v-if="isVisible" ref="popup"  @close="close">
		<div class="modal-mask">
      <div class="modal-wrapper" >
        <div class="modal-container">
          <div>
            <h2>{{title}}</h2>
          </div>

          <div class="modal-body">
            <a-form :form="form">
              <a-form-item
                v-for="field in fields"
                :key="field.label"
                :label="field.label"
                :label-col="{span: 8}"
                :wrapper-col="{span: 12}"
              >
                <a-select v-if="field.type === 'select'"
                          v-decorator="field.decorator"
                >
                  <a-select-option v-for="type in field.options" :key="type.key" :value="type.value">
                    {{ type.key }}
                  </a-select-option>
                </a-select>
                <a-input v-else
                         v-decorator="field.decorator"
                />
              </a-form-item>
            </a-form>
          </div>

          <div class="modal-footer">
            <a-button @click="save">{{ firstButtonText }}</a-button>
            <a-button @click="close">{{ secondButtonText }}</a-button>
          </div>
        </div>
		  </div>
		</div>
  </transition>
</template>


<script>
import {Button, Form, Input, Select} from "ant-design-vue";

export default {
    components: {
      'a-button': Button,
      'a-form': Form,
      'a-form-item': Form.Item,
      'a-input': Input,
      'a-select': Select,
      'a-select-option': Select.Option,
    },
    data: function() {
        return {
          isVisible: false,

          form: this.$form.createForm(this),

          title: '',
          fields: [],
          firstButtonText: '',
          secondButtonText: '',

          resolvePromise: undefined,
          rejectPromise: undefined,
        };
    },
    methods: {
      show(opts = {}) {
        this.isVisible = true;

        this.title = opts.title;
        this.fields = opts.fields;
        this.firstButtonText = opts.firstButtonText ?? "Save";
        this.secondButtonText = opts.secondButtonText ?? "Cancel";

        return new Promise((resolve, reject) => {
          this.resolvePromise = resolve
          this.rejectPromise = reject
        })
      },

      save() {
        this.form.validateFields((err, values) => {
          if (!err) {
            this.isVisible = false;
            this.resolvePromise(values);
          }
        })
      },

      close() {
        this.isVisible = false;
        this.resolvePromise(null)
      },
    }
}
</script>

<style scoped>
  .modal-container {
    width: 500px;
  }
  .modal-footer > * {
    margin: 0 2px;
  }
</style>
