<template>
  <a-modal
    title="PBJ Report Explanation"
    width="700px"
    :visible="visible"
    :footer="null"
    @cancel="closePbjExplanationModal"
  >
    <div>
      <p>This report consolidates the working hours logged by an associate across all their shifts on a given day,
        including their training hours.</p>
      <div class="job-codes">
        <div class="column">
          <h3>Job Codes 1-20</h3>
          <ul>
            <li>Code 1: Administrator</li>
            <li>Code 2: Medical Director</li>
            <li>Code 3: Physicians</li>
            <li>Code 4: Physician Aids</li>
            <li>Code 5: Director of Nursing</li>
            <li>Code 6: Administrative RN</li>
            <li>Code 7: RN</li>
            <li>Code 8: Administrative LVN</li>
            <li>Code 9: LVN</li>
            <li>Code 10: CNA</li>
            <li>Code 11: Nurse Aid in Training</li>
            <li>Code 12: Medication Aid/Tech</li>
            <li>Code 13: RN Practitioner</li>
            <li>Code 14: Clinical RN</li>
            <li>Code 15: Pharmacist</li>
            <li>Code 16: Dietitian</li>
            <li>Code 17: Feeding Assistant</li>
            <li>Code 18: Occupational Therapy (OT)</li>
            <li>Code 19: OT Assistant</li>
            <li>Code 20: OT Aid</li>
          </ul>
        </div>
        <div class="column">
          <h3>Job Codes 21-40</h3>
          <ul>
            <li>Code 21: Physical Therapy (PT)</li>
            <li>Code 22: PT Assistant</li>
            <li>Code 23: PT Aid</li>
            <li>Code 24: Respiratory Therapist (RT)</li>
            <li>Code 25: RT Technician</li>
            <li>Code 26: Speech/Language Pathology (ST)</li>
            <li>Code 27: Therapeutic Rec Specialist</li>
            <li>Code 28: Qualified Activities Person</li>
            <li>Code 29: Activity Aid</li>
            <li>Code 30: Qualified Social Worker</li>
            <li>Code 31: Social Worker</li>
            <li>Code 32: Dentist</li>
            <li>Code 33: Podiatrist</li>
            <li>Code 34: Mental Health Service Worker</li>
            <li>Code 35: Vocational Service Worker</li>
            <li>Code 36: Clinical Lab Services</li>
            <li>Code 37: X-Ray Services</li>
            <li>Code 38: Blood Services</li>
            <li>Code 39: Housekeeping</li>
            <li>Code 40: Other (Everyone Else)</li>
          </ul>
        </div>
      </div>
      <div class="pay-types">
        <h3>Pay Type Codes and Their Application</h3>
        <ul>
          <li><b>Pay Type 1 (Exempt)</b>: Applied if the Payment Type is 'Employee' and the Pay Status is 'Full Time'.
          </li>
          <li><b>Pay Type 2 (Non-Exempt)</b>: Applied if the Payment Type is 'Employee' and the Pay Status is any other
            than
            'Full Time'.
          </li>
          <li><b>Pay Type 3 (Contract)</b>: Applied if the Payment Type is 'Contractor'.</li>
        </ul>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {Modal} from "ant-design-vue";

export default {
  props: ['visible'],
  components: {
    'a-modal': Modal
  },
  methods: {
    closePbjExplanationModal() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>
.job-codes {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
</style>
