<template>
  <a-modal
    title="Shift Sign"
    :visible="visible"
    @cancel="closeModal"
    :destroy-on-close="true"
  >
    <div
      v-if="loadingShift || !shift"
      class="loading-spin"
      style="height: 650px; background-color: white"
    >
      <a-spin />
    </div>
    <div v-else>
      <p>
        <b>Date:</b>
        {{ formatDateRange(shift.scheduled_start, shift.scheduled_end) }}
      </p>
      <p>
        <b>Time:</b>
        {{ timeRangeToString(shift.work_start, shift.work_end) }}
      </p>
      <p>
        <b>Duration:</b>
        {{ minutesToHoursAndMinutes(shift.duration) }}
      </p>
      <p>
        <b>Lunch:</b>
        {{ timeRangeToString(shift.lunch_start, shift.lunch_end) }}
      </p>
      <p>
        <b>Lunch Duration:</b>
        {{ minutesToHoursAndMinutes(shift.lunch_duration) }}
      </p>
      <p>
        <b>Second Lunch:</b>
        {{
          timeRangeToString(shift.second_lunch_start, shift.second_lunch_end)
        }}
      </p>
      <p>
        <b>Second Lunch Duration:</b>
        {{ minutesToHoursAndMinutes(shift.second_lunch_duration) }}
      </p>
      <p>
        <b>Work Hours:</b>
        {{ workHours() }}
      </p>
      <p>
        <b>Pay Hours:</b>
        {{ payHours() }}
      </p>
      <signature
        checkbox-label="I agree that all data is true and correct"
        :associate="signatureAssociate"
        :available-signature="adminAssociateSignature"
        @checked="setSignatureCheckboxValue"
        @save-signature="setSignatureId"
      />
    </div>

    <template slot="footer">
      <div class="flex justify-content-end">
        <a-button
          type="primary"
          :disabled="!checked"
          :loading="loadingSave"
          @click="signShift"
        >
          Sign
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { Button, Modal, notification, Spin } from "ant-design-vue";
import shiftsHelper from "@/helpers/shifts";
import { createNamespacedHelpers } from "vuex";
import util from "@/util";
import Signature from "@/components/signature.vue";

const { mapState: shiftState, mapActions: shiftActions } =
  createNamespacedHelpers("shifts");

export default {
  components: {
    "a-modal": Modal,
    "a-button": Button,
    "a-spin": Spin,
    signature: Signature,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    shift_id: {
      type: String,
      default: undefined,
    },
    edit: {
      type: Function,
    },
  },
  mixins: [shiftsHelper],
  data() {
    return {
      loadingShift: false,
      loadingSave: false,

      signature_id: undefined,
      checked: false,
    };
  },
  watch: {
    shift_id: {
      handler(newVal) {
        if (newVal) this.fetchShift();
      },
      immediate: true,
    },
  },
  computed: {
    ...shiftState({
      shift: (state) => state.shift.shift,
    }),

    signatureAssociate() {
      return {
        name: util.combineAssociateName(
          this.$store.state.applicationState.associate.first_name,
          this.$store.state.applicationState.associate.last_name
        ),
        email: this.$store.state.applicationState.associate.email,
        phone: this.$store.state.applicationState.associate.phone,
      };
    },
    adminAssociateSignature() {
      return this.$store.state.applicationState.associate.signature;
    },
  },
  methods: {
    ...shiftActions(["getShiftWithAuth", "postSignShift"]),
    setSignatureCheckboxValue(val) {
      this.checked = val;
    },

    setSignatureId(signatureId) {
      this.signature_id = signatureId;
    },

    closeModal() {
      this.$emit("update:visible", false);
    },

    timeRangeToString(start, end) {
      if (!start || !end) {
        return " - ";
      }

      const { start: startTime, end: endTime } = this.formatTimeRange(
        start,
        end
      );

      return `${startTime} - ${endTime}`;
    },

    getShiftTimes(type) {
      const prefix = type === "signed" ? "" : "actual_";
      return {
        work_start: this.shift[`${prefix}work_start`],
        work_end: this.shift[`${prefix}work_end`],
        lunch_start: this.shift[`${prefix}lunch_start`],
        lunch_end: this.shift[`${prefix}lunch_end`],
        second_lunch_start: this.shift[`${prefix}second_lunch_start`],
        second_lunch_end: this.shift[`${prefix}second_lunch_end`],
      };
    },

    workHours(type = "signed") {
      const {
        work_start,
        work_end,
        lunch_start,
        lunch_end,
        second_lunch_start,
        second_lunch_end,
      } = this.getShiftTimes(type);

      const formatted = this.formatWorkHours(
        work_start,
        work_end,
        lunch_start,
        lunch_end,
        second_lunch_start,
        second_lunch_end
      );

      return formatted ?? " - ";
    },

    payHours(type = "signed") {
      const {
        work_start,
        work_end,
        lunch_start,
        lunch_end,
        second_lunch_start,
        second_lunch_end,
      } = this.getShiftTimes(type);

      const formatted = this.formatPayHours(
        this.shift.scheduled_start,
        this.shift.scheduled_end,
        work_start,
        work_end,
        lunch_start,
        lunch_end,
        second_lunch_start,
        second_lunch_end
      );

      return formatted ?? " - ";
    },

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    async fetchShift() {
      try {
        this.loadingShift = true;

        const payload = {
          shift_id: this.shift_id,
        };

        await this.getShiftWithAuth(payload);
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingShift = false;
      }
    },

    async signShift() {
      this.loadingSave = true;

      const payload = {
        shift_id: this.shift_id,
        version_id: this.shift.version,
        signature_id: this.signature_id,
      };

      try {
        await this.postSignShift(payload);

        this.showNotification(
          "success",
          "Success",
          "The shift has been signed"
        );

        this.$emit("edit");
        this.closeModal();
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingSave = false;
      }
    },
  },
  mounted() {
    if (this.shift_id) this.fetchShift();
  },
};
</script>
