<template>
  <div>
    <h2 class="view-header">Mailing</h2>
    <div class="notification_form_wrapper">
      <a-form-model
        ref="form"
        layout="vertical"
        :model="formData"
        :rules="rules"
      >
        <a-form-model-item label="Recipients" prop="recipient">
          <a-select v-model="formData.recipient" placeholder="Select recipient">
            <a-select-option value="All associates">All associates</a-select-option>
            <a-select-option value="Specific associates">Specific associates</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="Payment type" prop="selectedPaymentType" v-if="formData.recipient === 'All associates'">
          <a-select v-model="formData.selectedPaymentType" placeholder="Select Payment Type">
            <a-select-option v-for="type in paymentTypes" :key="type.id" :value="type.id">
              {{ type.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item label="Job classification" prop="jobClassification" v-if="formData.recipient === 'All associates'">
          <a-select v-model="formData.jobClassification" placeholder="Select job classification">
            <a-select-option value="all" selected>All</a-select-option>
            <a-select-option v-for="role in projectRoles" :key="role.id" :value="role.id">
              {{ role.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="workingOnly" v-if="formData.recipient === 'Specific associates'">
          <div class="checkbox-container">
            <a-checkbox v-model="formData.workingOnly">
              Only working at the moment
            </a-checkbox>
          </div>
        </a-form-model-item>


        <a-form-model-item label="Associates" prop="selectedEmployees" v-if="formData.recipient === 'Specific associates'">
          <a-select
            v-model="formData.selectedEmployees"
            show-search
            placeholder="Enter associate name"
            :allowClear="true"
            :options="foundEmployees"
            :loading="searchingUsers"
            :filter-option="false"
            :not-found-content="null"
            @search="fetchUsers"
          />
        </a-form-model-item>

        <a-form-model-item label="Title" prop="notificationTitle">
          <a-input v-model="formData.notificationTitle" placeholder="Enter title" />
        </a-form-model-item>

        <a-form-model-item label="Message" prop="notificationMessage" class="message-field">
          <a-textarea v-model="formData.notificationMessage" placeholder="Enter your message" rows="4" />
        </a-form-model-item>

        <a-form-model-item>
          <div style="display: flex; justify-content: space-between; width: 100%;">
            <a-button type="primary" @click="handleSend">Send</a-button>
            <a-button @click="clearForm">Clear</a-button>
          </div>
        </a-form-model-item>

        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { Button, Checkbox, Input, Select, FormModel, notification } from "ant-design-vue";
import api from "@/api";
import store from '@/store';
import { debounce } from '@/helpers/debounce';

const PaymentTypes = Object.freeze([
  { id: 'all', name: 'All' },
  { id: 'employee', name: 'Employee' },
  { id: 'contractor', name: 'Contractor' },
  { id: 'volunteer', name: 'Volunteer' }
]);

export default {
  components: {
    'a-checkbox': Checkbox,
    'a-input': Input,
    'a-select-option': Select.Option,
    'a-textarea': Input.TextArea,
    'a-button': Button,
    'a-select': Select,
    'a-form-model-item': FormModel.Item,
    'a-form-model': FormModel,
  },
  mixins: [api],
  data: () => ({
    formData: {
      workingOnly: false,
      jobClassification: "all",
      selectedPaymentType: "all",
      notificationTitle: '',
      notificationMessage: '',
      recipient: 'All associates',
      selectedEmployees: [],
    },
    rules: {
      recipient: [{required: true, message: 'Recipient is required', trigger: 'change'}],
      notificationTitle: [{required: true, message: 'Title is required', trigger: 'change'}],
      notificationMessage: [{required: true, message: 'Message is required', trigger: 'change'}],
    },
    loading: false,
    searchingUsers: null,
    foundEmployees: [],
    paymentTypes: PaymentTypes,
  }),

  computed: {
    projectRoles() {
      return this.$store.state.applicationState.projectRoles || [];
    },
    role() {
      if (this.formData.recipient === 'Specific associates') return null;
      if (this.formData.jobClassification === 'all') return null;
      return this.getRoleObject(this.formData.jobClassification);
    },
  },

  methods: {
    async handleSend() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return;

        const notificationData = {
          project_id: this.$store.state.applicationState.currentProject.id,
          title: this.formData.notificationTitle,
          message: this.formData.notificationMessage,
          working_only: this.formData.workingOnly,
          associates: this.formData.recipient === 'Specific associates'
            ? (Array.isArray(this.formData.selectedEmployees) ? this.formData.selectedEmployees : [this.formData.selectedEmployees])
            : [],
          role: this.role,
          payment_type: this.formData.recipient === 'Specific associates' ? null : (this.formData.selectedPaymentType === 'all' ? null : this.formData.selectedPaymentType),
          with_alert: false,
          with_sms: false,
          with_email: false,
          with_work_notice: false
        };

        try {
          await this.apiSendNotification(notificationData);
          notification.success({
            message: "Successfully sent",
            description: "Mail sent successfully!",
          });
          this.clearForm();
        } catch (error) {
          console.error(error);
          notification.error({
            message: "Failed to send",
            description: "Failed to send notification.",
          });
        }
      });
    },

    clearForm() {
      this.$refs.form.resetFields();
    },

    fetchUsers: debounce(async function (value) {
      this.searchingUsers = true;
      const resp = await this.apiSearchAssociate(value);
      this.foundEmployees = resp.data.associate_list.map(user => ({
        label: `[${user.user_id}] ${user.first_name} ${user.last_name}`,
        key: user.user_id,
        value: user.user_id
      }));
      this.searchingUsers = false;
    }, 1000),

    getRoleObject(roleId) {
      const role = this.projectRoles.find(role => role.id === roleId);
      return role ? { id: role.id, name: role.name } : null;
    },
  }
}
</script>

<style scoped>
.notification_form_wrapper {
  margin-top: 16px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

</style>
