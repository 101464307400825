import apiClient from "@/services/api";

export default {
  async fetchShiftAuthorizations({ commit }, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/get_current_shift_auths", {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }

      commit("setShiftAuthorizations", data.authorizations);
    } catch (error) {
      throw error.message;
    }
  },

  async deleteShift({}, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/tc_delete_employee_shift", {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while deleting shift. Please try again later"
        );
      }
    } catch (error) {
      throw error.message;
    }
  },

  async deleteVersion({}, { shift_id, version }) {
    try {
      const { data } = await apiClient.post(
        `/api/shift/${shift_id}/version/${version}/delete`
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while when revoking shift. Please try again later"
        );
      }
    } catch (error) {
      throw error.message;
    }
  },

  async updateShiftAuthorizations(
    { commit },
    { ids, status, comment, params }
  ) {
    try {
      const { data } = await apiClient.post(
        "/api/tc_set_auth_status_for_multiple_auths",
        {
          ids,
          status,
          comment,
          ...params,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error("Please try again later");
      }
    } catch (error) {
      throw error.message;
    }
  },

  async getShifts({ rootState }, { page = 1, size = 10, filter = {} }) {
    try {
      const { data } = await apiClient.post(`api/shift/get_all`, {
        project_id: rootState.applicationState.currentProject.id,
        page,
        size,
        filter,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while loading the shifts. Please try again later"
        );
      }

      return data;
    } catch (error) {
      throw error.message;
    }
  },

  async getShiftWithAuth({ commit }, { shift_id }) {
    try {
      const { data } = await apiClient.post(`/api/shift/get_with_auth`, {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while loading the shift. Please try again later"
        );
      }

      commit("setShift", data.authorizations_by_shift);
    } catch (error) {
      throw error.message;
    }
  },

  async getPayCode({}, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/shift/pay_code/get", {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while loading the pay code. Please try again later"
        );
      }
    } catch (error) {
      throw error.message;
    }
  },

  async getShiftPayCodes({ commit }, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/shift/pay_codes/get", {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while loading the pay codes. Please try again later"
        );
      }

      commit("setShiftPayCodes", data.pay_codes);
    } catch (error) {
      throw error.message;
    }
  },

  async getShiftCalculations({ commit }, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/shift/calculations/get", {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while loading the calculations. Please try again later"
        );
      }

      commit("setShiftCalculations", data.calculations);
    } catch (error) {
      throw error.message;
    }
  },

  async getShiftPayments({ commit }, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/shift/payment/get", {
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while loading the payments. Please try again later"
        );
      }

      commit("setShiftPayments", data.payments);
    } catch (error) {
      throw error.message;
    }
  },

  async getShiftActions({ commit }, { shift_id }) {
    try {
      const { data } = await apiClient.post("/api/shift/action/get_all", {
        shift_ids: [shift_id],
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while loading the actions. Please try again later"
        );
      }

      if (data.shift_actions && data.shift_actions.length) {
        commit("setShiftActions", data.shift_actions[0].records);
      } else {
        throw new Error(
          "An error occurred while loading the actions. Please try again later"
        );
      }
    } catch (error) {
      throw error.message;
    }
  },

  async createPayCode({ rootState }, { dates, associates, pay_code }) {
    try {
      const { data } = await apiClient.post("/api/shift/pay_code/create", {
        project_id: rootState.applicationState.currentProject.id,
        pay_code_shift_form: { dates, associates, pay_code },
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while creating the pay code. Please try again later"
        );
      }
    } catch (error) {
      throw error.message;
    }
  },

  async attachPayCode({ rootState }, { pay_code, shift_ids }) {
    try {
      const { data } = await apiClient.post("/api/shift/pay_code/attach", {
        project_id: rootState.applicationState.currentProject.id,
        author_id: rootState.applicationState.associate.id,
        pay_code,
        shift_ids,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while attaching the pay code. Please try again later"
        );
      }
    } catch (error) {
      throw error.message;
    }
  },

  async updatePayCode({ rootState }, { pay_code, shift_id }) {
    try {
      const { data } = await apiClient.post("/api/shift/pay_code/update", {
        project_id: rootState.applicationState.currentProject.id,
        pay_code,
        shift_id,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while attaching the pay code. Please try again later"
        );
      }
    } catch (error) {
      throw error.message;
    }
  },

  async postSignShift({}, { shift_id, version_id, signature_id }) {
    try {
      const { data } = await apiClient.post(
        `shift/${shift_id}/version/${version_id}/sign`,
        {
          signature: {
            id: signature_id,
          },
          create_context: true,
        }
      );

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          `${
            data.msg ?? "An error occurred while signing shift."
          } Please try again later`
        );
      }
    } catch (error) {
      throw error.message;
    }
  },
};
