import { notification } from "ant-design-vue";
import Vue from "vue";
import api from "@/api";
import store from "./store";

export default {
  handleAuthError(responseBody) {
    let timeout = 0;
    let errorDescription = "";

    if (responseBody.msg === "This user has no active company.") {
      timeout = 5000;
      errorDescription =
        "You are missing a current company. " +
        "Please select the current company in the mobile app and try logging in again. " +
        "We will redirect you to the sign in page in 5 seconds.";
    }

    notification["error"]({
      message: "Sign In Error",
      description: errorDescription,
      duration: 0,
    });

    setTimeout(() => {
      Vue.http.post(api.methods.getRootAddress() + "sign_out");
      store.commit("setToken", null);
    }, timeout);
  },
};
