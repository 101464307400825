<template>
  <div>
    <a-modal
      title="Shift Actions List"
      v-model="visibleLocationsModal"
      width="850px"
      :footer="null"
      @cancel="closeLocationsModal"
      :destroy-on-close="true"
    >
      <locations :list="currentLocationsList" />
    </a-modal>

    <a-modal
      title="Decline Payment Request"
      v-model="visibleDeclineModal"
      width="350px"
      @cancel="closeDeclineModal"
      :destroy-on-close="true"
    >
      <a-textarea v-model="cancelReason" placeholder="Enter Decline Reason" />
      <template slot="footer">
        <div class="flex justify-content-end">
          <a-button type="danger" @click="closeDeclineModal"> Cancel </a-button>
          <a-button type="primary" @click="declineValidation">
            Decline
          </a-button>
        </div>
      </template>
    </a-modal>

    <a-modal
      width="800px"
      title="Payment Request"
      v-model="visible"
      :centered="true"
      :destroy-on-close="true"
      @ok="closeModal"
      @cancel="closeModal"
    >
      <div v-if="loadingPayment" class="loading-spin">
        <a-spin />
      </div>

      <template v-else>
        <div class="content-view">
          <div class="content-view-block">
            <div>
              <h3>Payment Info</h3>
              <p>
                <b>ID</b>
                <span>{{ paymentInfo.payment.id }}</span>
              </p>
              <p>
                <b>System</b>
                <span>{{ combinedPaymentSystem }}</span>
              </p>
              <p>
                <b>Status</b>
                <span>
                  <a-tag
                    class="status-tag"
                    :color="
                      getPaymentStatus(
                        paymentInfo.payment.status,
                        paymentInfo.payment.method
                      ).color
                    "
                  >
                    {{
                      getPaymentStatus(
                        paymentInfo.payment.status,
                        paymentInfo.payment.method
                      ).title
                    }}
                  </a-tag>
                </span>
              </p>
              <p>
                <b>Total, $</b>
                <span>{{ convertedTotal }}</span>
              </p>
              <p>
                <b>Net Amount, $</b>
                <span>{{ convertedNetTotal }}</span>
              </p>
            </div>
            <div>
              <h3>Associate Info</h3>
              <p>
                <b>Number</b>
                <span>{{ paymentInfo.associate.associate_number }}</span>
              </p>
              <p>
                <b>Name</b>
                <span>{{ requesterFullName }}</span>
              </p>
              <p>
                <b>Profile</b>
                <a-button
                  icon="edit"
                  size="small"
                  :disabled="loading"
                  @click="
                    openAssociateProfile(paymentInfo.associate.associate_number)
                  "
                >
                  Open
                </a-button>
              </p>
            </div>
            <div v-if="paymentInfo && paymentInfo.reviewer">
              <h3>Reviewer Info</h3>
              <p>
                <b>Number</b>
                <span>{{ paymentInfo.payment.reviewer.associate_number }}</span>
              </p>
              <p>
                <b>Name</b>
                <span>{{ reviewerFullName }}</span>
              </p>
              <p>
                <b>Profile</b>
                <a-button
                  icon="edit"
                  size="small"
                  :disabled="loading"
                  @click="
                    openAssociateProfile(paymentInfo.reviewer.associate_number)
                  "
                >
                  Open
                </a-button>
              </p>
            </div>
          </div>
          <div class="content-view-block">
            <div>
              <h3>Content</h3>
              <p>
                <b>Invoice</b>
                <a-dropdown size="small" :disabled="!canDownloadInvoice">
                  <a-button
                    @click="openAttachmentOnNewPage(paymentInfo.invoice.link)"
                    :disabled="loading"
                    size="small"
                    icon="download"
                  >
                    Download
                  </a-button>
                </a-dropdown>
              </p>
              <p>
                <b>Time/Attendance Detail Report</b>
                <a-dropdown :disabled="contentDisabled" size="small">
                  <a-menu slot="overlay">
                    <a-menu-item
                      key="pdf"
                      @click="
                        downloadTimeAttendanceReport(
                          timeAttendanceExportFormatType.PDF
                        )
                      "
                    >
                      <a-icon type="file-pdf" />
                      PDF
                    </a-menu-item>
                    <a-menu-item
                      key="excel"
                      @click="
                        downloadTimeAttendanceReport(
                          timeAttendanceExportFormatType.EXCEL
                        )
                      "
                    >
                      <a-icon type="file-excel" />
                      Excel
                    </a-menu-item>
                  </a-menu>
                  <a-button :disabled="loading" size="small" icon="download">
                    Download
                  </a-button>
                </a-dropdown>
              </p>
              <p>
                <b>Actions</b>
                <a-button
                  size="small"
                  icon="environment"
                  :disabled="locationsLoading || contentDisabled"
                  @click="showLocationsInfo"
                >
                  Open
                </a-button>
              </p>
              <p
                v-if="
                  paymentInfo.attached_file_urls &&
                  paymentInfo.attached_file_urls.length > 0
                "
              >
                <b>Files</b>
                <a-button size="small" icon="download" @click="openFiles">
                  Open
                </a-button>
              </p>
            </div>
            <div v-if="paymentInfo.shifts">
              <h3>Shifts</h3>
              <a-table
                class="shifts-to-pay-table"
                size="small"
                :columns="shiftsToPayColumns"
                :pagination="false"
                :data-source="paymentInfo.shifts"
              >
                <span slot="shift-date" slot-scope="text, record">
                  {{
                    record.shift_info.work_start
                      ? formatDateRange(
                          record.shift_info.work_start,
                          record.shift_info.work_end
                        )
                      : formatDateRange(
                          record.shift_info.scheduled_start,
                          record.shift_info.scheduled_end
                        )
                  }}
                </span>
                <div slot="expandedRowRender" slot-scope="record">
                  <div class="content-view-block">
                    <p v-if="record.shift_info.status">
                      <b>Status</b>
                      <a-tag
                        class="payment-status-tag m-0"
                        :color="
                          getShiftStatusTagColor(record.shift_info.status)
                        "
                      >
                        {{
                          tryToGetPaymentStatusTagText(record.shift_info.status)
                        }}
                      </a-tag>
                    </p>
                    <p>
                      <b>Scheduled</b>
                      <span class="text-right">{{
                        getScheduledDuration(record)
                      }}</span>
                    </p>
                    <p>
                      <b>Signed</b>
                      <span class="text-right">{{
                        getSignedDuration(record)
                      }}</span>
                    </p>
                    <p>
                      <b>Actual</b>
                      <span class="text-right">{{
                        getActualDuration(record)
                      }}</span>
                    </p>
                    <p>
                      <b>Lunch Break</b>
                      <span class="text-right">{{
                        getFirstLunchDuration(record)
                      }}</span>
                    </p>
                    <p>
                      <b>2nd Lunch Break</b>
                      <span class="text-right">{{
                        getSecondLunchDuration(record)
                      }}</span>
                    </p>
                    <p>
                      <b>Payment Type</b>
                      <span class="text-right">{{
                        record.shift_info.payment_type
                      }}</span>
                    </p>
                    <p>
                      <b>Rate</b>
                      <span class="text-right"
                        >${{ convertedRate(record) }}</span
                      >
                    </p>
                    <p>
                      <b>Instant Loan</b>
                      <span class="text-right"
                        >{{ instant_loan_percent * 100 }}%</span
                      >
                    </p>
                    <p>
                      <b>Attachments</b>
                      <a-dropdown
                        size="small"
                        v-if="attachmentsAvailable(record)"
                      >
                        <a-menu slot="overlay">
                          <a-menu-item
                            v-for="file in record.shift_info.attached_file_urls"
                            :key="file"
                            @click="openAttachmentOnNewPage(file)"
                          >
                            <a-icon type="file-image" />
                            {{ file }}
                          </a-menu-item>
                        </a-menu>
                        <a-button
                          :disabled="loading"
                          size="small"
                          icon="download"
                        >
                          Download
                        </a-button>
                      </a-dropdown>
                      <span v-else> N/A </span>
                    </p>
                  </div>
                </div>
              </a-table>
            </div>
          </div>
        </div>

        <template slot="footer" v-if="availableShiftValidation">
          <div class="flex justify-content-end">
            <a-button type="danger" icon="close" @click="openDeclineModal">
              Decline
            </a-button>
            <a-button
              type="primary"
              icon="check"
              @click="approveValidation('shifts')"
            >
              Approve Shifts
            </a-button>
          </div>
        </template>

        <template slot="footer" v-if="availablePaymentValidation">
          <div class="flex justify-content-end">
            <a-button
              type="primary"
              icon="check"
              @click="approveValidation('payment')"
            >
              Approve Payment
            </a-button>
          </div>
        </template>
      </template>
    </a-modal>
  </div>
</template>
<script>
import {
  Button,
  Dropdown,
  Icon,
  Menu,
  notification,
  Table,
  Tag,
  Spin,
  Input,
  InputNumber,
  Modal,
} from "ant-design-vue";
import moment from "moment-timezone";
import api from "@/api";
import Util from "@/util";
import shiftsHelper from "@/helpers/shifts";
import locations from "@/components/locations.vue";
import { tryToGetPaymentStatusTagText } from "@/const";
import { createNamespacedHelpers } from "vuex";

const {
  mapState: paymentState,
  mapActions: paymentActions,
  mapGetters: paymentGetters,
} = createNamespacedHelpers("payment");

const statusTagColors = {
  GREEN: "#34db30",
  GRAY: "#b4b4b4",
  RED: "#ff4646",
  ORANGE: "#ffb546",
  BLUE_LIGHT: "#52bfff",
};

const shiftStatusEnum = {
  SCHEDULED: "scheduled",
  STARTED: "started",
  MISSED: "missed",
  SICK: "sick",
  VACATION: "vacation",
  CANCELLED: "cancelled",
  CALL_OFF: "call_off",
  NOT_SIGNED: "not_signed",
  AUTH_ISSUES: "auth_issues",
  READY_TO_PAYMENT: "not_paid",
  DRAFT: "draft",
  IN_PROGRESS: "in_progress",
  PAID: "paid",
};

const shiftStatusColors = {
  GREEN: [shiftStatusEnum.READY_TO_PAYMENT],
  GRAY: [
    shiftStatusEnum.SCHEDULED,
    shiftStatusEnum.STARTED,
    shiftStatusEnum.PAID,
  ],
  RED: [shiftStatusEnum.MISSED],
  LILAC: [
    shiftStatusEnum.SICK,
    shiftStatusEnum.VACATION,
    shiftStatusEnum.CALL_OFF,
    shiftStatusEnum.CANCELLED,
  ],
  ORANGE: [shiftStatusEnum.NOT_SIGNED, shiftStatusEnum.AUTH_ISSUES],
  BLUE_LIGHT: [shiftStatusEnum.IN_PROGRESS],
  TURTLE: [shiftStatusEnum.DRAFT],
};

export default {
  data() {
    return {
      currentLocationsList: undefined,
      cancelReason: "",

      shiftsToPayColumns: [
        {
          title: "Date",
          scopedSlots: { customRender: "shift-date" },
        },
      ],
      loading: false,
      loadingPayment: false,

      timeAttendanceExportFormatType: {
        PDF: 0,
        EXCEL: 1,
      },

      locationsLoading: false,

      instant_loan_percent:
        this.$store.state.applicationState.currentProject.payment_settings
          .instant_loan_percent,

      visibleDeclineModal: false,
      visible: false,
    };
  },
  props: {
    paymentId: {
      type: Number,
    },
    change: {
      type: Function,
    },
  },
  mixins: [api, shiftsHelper],
  components: {
    locations,
    "a-button": Button,
    "a-dropdown": Dropdown,
    "a-table": Table,
    "a-tag": Tag,
    "a-icon": Icon,
    "a-menu": Menu,
    "a-spin": Spin,
    "a-menu-item": Menu.Item,
    "a-input": Input,
    "a-textarea": Input.TextArea,
    "a-input-number": InputNumber,
    "a-modal": Modal,
  },
  watch: {
    paymentId(curr, prev) {
      if (prev && !curr) {
        this.visible = false;
      } else {
        this.visible = true;
        this.fetchPaymentInfo();
      }
    },
  },
  computed: {
    ...paymentState({
      paymentInfo: (state) => state.payment,
    }),
    ...paymentGetters(["getPaymentStatus"]),
    requesterFullName() {
      if (!this.paymentInfo || !this.paymentInfo.associate) {
        return "-";
      }

      return Util.combineAssociateName(
        this.paymentInfo.associate.first_name,
        this.paymentInfo.associate.last_name
      );
    },
    reviewerFullName() {
      if (!this.paymentInfo || !this.paymentInfo.payment.reviewer) {
        return "-";
      }

      return Util.combineAssociateName(
        this.paymentInfo.payment.reviewer.first_name,
        this.paymentInfo.payment.reviewer.last_name
      );
    },
    combinedPaymentSystem() {
      let method = "-";

      if (this.paymentInfo.payment && this.paymentInfo.payment.method) {
        method = Util.capitalize(this.paymentInfo.payment.method);
      }
      if (
        this.paymentInfo.payment.method &&
        this.paymentInfo.payment.manual_payment_method
      ) {
        method +=
          " " +
          "(" +
          Util.splitAndCapitalize(
            this.paymentInfo.payment.manual_payment_method
          ) +
          ")";
      }

      return method;
    },
    convertedTotal() {
      if (!this.paymentInfo.payment) {
        return 0;
      }

      return Util.convertDbToMoney(this.paymentInfo.payment.total);
    },

    convertedNetTotal() {
      if (!this.paymentInfo) {
        return 0;
      }

      return Util.convertDbToMoney(this.paymentInfo.payment.net_amount);
    },

    canDownloadInvoice() {
      return this.paymentInfo.invoice ? this.paymentInfo.invoice.link : false;
    },

    contentDisabled() {
      return !this.paymentInfo.shifts || this.paymentInfo.shifts.length == 0;
    },

    visibleLocationsModal() {
      return this.currentLocationsList;
    },

    availableShiftValidation() {
      return (
        this.paymentInfo.payment.next_action === "wait_for_shifts_approval" &&
        this.$store.state.applicationState.associate.permissions.includes(
          "validate_payments"
        )
      );
    },

    availablePaymentValidation() {
      return (
        this.paymentInfo.payment.next_action === "wait_for_payment_approval" &&
        this.$store.state.applicationState.associate.permissions.includes(
          "process_payments"
        )
      );
    },
  },
  methods: {
    ...paymentActions([
      "getPayment",
      "denyPaymentRequest",
      "approvePaymentRequest",
    ]),
    tryToGetPaymentStatusTagText,

    showNotification(type, message, description) {
      notification[type]({
        message,
        description,
      });
    },

    getShiftStatusTagColor(status) {
      switch (true) {
        case shiftStatusColors.GREEN.includes(status):
          return statusTagColors.GREEN;
        case shiftStatusColors.GRAY.includes(status):
          return statusTagColors.GRAY;
        case shiftStatusColors.RED.includes(status):
          return statusTagColors.RED;
        case shiftStatusColors.LILAC.includes(status):
          return statusTagColors.LILAC;
        case shiftStatusColors.BLUE_LIGHT.includes(status):
          return statusTagColors.BLUE_LIGHT;
        case shiftStatusColors.ORANGE.includes(status):
          return statusTagColors.ORANGE;
        case shiftStatusColors.TURTLE.includes(status):
          return statusTagColors.TURTLE;
      }
    },

    openAssociateProfile(associateNumber) {
      const route = {
        name: "associate-profiles",
        query: { editingAssociate: associateNumber },
      };

      const url = this.$router.resolve(route).href;

      window.open(url, "_blank");
    },

    getScheduledHours(record) {
      return this.formatWorkHours(
        moment(record.shift_info.scheduled_start),
        moment(record.shift_info.scheduled_end)
      );
    },

    getScheduledDuration(record) {
      const { scheduled_start, scheduled_end } = record.shift_info;

      const { start, end } = this.formatTimeRange(
        scheduled_start,
        scheduled_end
      );
      const scheduledHours = this.getScheduledHours(record);

      return `${start} - ${end} (${scheduledHours})`;
    },

    getSignedHours(record) {
      const {
        work_start,
        work_end,
        lunch_start,
        lunch_end,
        second_lunch_start,
        second_lunch_end,
      } = record.shift_info;

      const parseTime = (time) => (time ? moment(time) : null);

      return this.formatWorkHours(
        parseTime(work_start),
        parseTime(work_end),
        parseTime(lunch_start),
        parseTime(lunch_end),
        parseTime(second_lunch_start),
        parseTime(second_lunch_end)
      );
    },

    getSignedDuration(record) {
      const { work_start, work_end } = record.shift_info;
      const { start, end } = this.formatTimeRange(work_start, work_end);
      const signedHours = this.getSignedHours(record);

      return `${start} - ${end} (${signedHours})`;
    },

    getActualHours(record) {
      const {
        actual_work_start,
        actual_work_end,
        actual_lunch_start,
        actual_lunch_end,
        actual_second_lunch_start,
        actual_second_lunch_end,
      } = record.shift_info;

      const parseTime = (time) => (time ? moment(time) : null);

      return this.formatWorkHours(
        parseTime(actual_work_start),
        parseTime(actual_work_end),
        parseTime(actual_lunch_start),
        parseTime(actual_lunch_end),
        parseTime(actual_second_lunch_start),
        parseTime(actual_second_lunch_end)
      );
    },

    getActualDuration(record) {
      const { actual_work_start, actual_work_end } = record.shift_info;

      if (!actual_work_start || !actual_work_end) {
        return " - ";
      }

      const { start, end } = this.formatTimeRange(
        actual_work_start,
        actual_work_end
      );
      const actualHours = this.getActualHours(record);

      return `${start} - ${end} (${actualHours})`;
    },

    getLunchDuration(startTime, endTime) {
      if (!startTime || !endTime) {
        return " - ";
      }

      const timeRange = this.formatTimeRange(startTime, endTime);
      const duration = moment(endTime).diff(moment(startTime), "minutes");

      return `${timeRange.start} - ${
        timeRange.end
      } (${this.minutesToHoursAndMinutes(duration)})`;
    },

    getFirstLunchDuration({ shift_info }) {
      return this.getLunchDuration(
        shift_info.lunch_start,
        shift_info.lunch_end
      );
    },

    getSecondLunchDuration({ shift_info }) {
      return this.getLunchDuration(
        shift_info.second_lunch_start,
        shift_info.second_lunch_end
      );
    },

    attachmentsAvailable(record) {
      return (
        record.shift_info.attached_file_urls &&
        record.shift_info.attached_file_urls.length > 0
      );
    },

    openAttachmentOnNewPage(file) {
      window.open(file, "_blank");
    },

    convertedRate(record) {
      return Util.convertDbToMoney(record.hour_rate);
    },

    // modal
    closeLocationsModal() {
      this.currentLocationsList = undefined;
    },

    openDeclineModal() {
      this.visibleDeclineModal = true;
    },

    closeDeclineModal() {
      this.visibleDeclineModal = false;
    },

    closeModal() {
      this.visible = false;
      this.$emit("update:paymentId", undefined);
    },

    openFiles() {
      this.paymentInfo.attached_file_urls.forEach((file) => {
        window.open(file.url, "_blank");
      });
    },

    async approveValidation(type) {
      this.loadingPayment = true;
      try {
        const payload = {
          request_id: this.paymentId,
          type: type,
        };
        await this.approvePaymentRequest(payload);
        await this.fetchPaymentInfo();
        this.$emit("change", {}, true);
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingPayment = false;
      }
    },

    async declineValidation() {
      this.loadingPayment = true;

      try {
        const payload = {
          request_id: this.paymentId,
          reason: this.cancelReason,
        };

        await this.denyPaymentRequest(payload);
        await this.fetchPaymentInfo();
        this.$emit("change", {}, true);
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingPayment = false;
      }
    },

    async showLocationsInfo() {
      this.locationsLoading = true;

      try {
        const { body } = await this.apiGetShiftLocations(
          this.paymentInfo.shifts.map((shift) => shift.shift_info.id)
        );

        if (!body.error_code || body.error_code === "0") {
          const locations = body.shift_actions;

          if (
            locations.length === 0 ||
            !locations[0] ||
            !locations[0].records ||
            !locations[0].records.length
          ) {
            this.showLocationsListEmptyMessage();
          } else {
            this.currentLocationsList = locations[0].records;
          }
        } else {
          this.showNotification(
            "error",
            "Error",
            "An error occurred while loading the list of locations. Please try again later"
          );
        }
      } catch (error) {
        this.showNotification(
          "error",
          "Error",
          "An error occurred while loading the list of locations. Please try again later"
        );
      } finally {
        this.locationsLoading = false;
      }
    },

    async downloadTimeAttendanceReport(format) {
      this.loading = true;
      try {
        let resp;
        switch (format) {
          case this.timeAttendanceExportFormatType.PDF:
            resp = await this.apiGetTimeAttendanceDetailReportByShiftIds(
              this.paymentInfo.shifts.map((shift) => shift.shift_info.id)
            );
            break;
          case this.timeAttendanceExportFormatType.EXCEL:
            resp = await this.apiSendShiftsReportByShiftIds(
              this.paymentInfo.shifts.map((shift) => shift.shift_info.id)
            );
            break;
        }

        if (resp.body.error_code && resp.body.error_code !== "0") {
          this.showNotification("warning", "Not Found", resp.body.msg);
        } else {
          window.open(resp.body.report_url, "_blank");
        }
      } catch (error) {
        this.showNotification(
          "warning",
          "Not Found",
          "An error occurred while downloading the report."
        );
      } finally {
        this.loading = false;
      }
    },

    async fetchPaymentInfo() {
      if (!this.paymentId) {
        return;
      }

      this.loadingPayment = true;
      try {
        await this.getPayment({
          payment_id: this.paymentId,
        });
      } catch (error) {
        this.showNotification("error", "Error", error);
      } finally {
        this.loadingPayment = false;
      }
    },
  },
  mounted() {
    if (this.paymentId) {
      this.visible = true;
      this.fetchPaymentInfo();
    }
  },
};
</script>

<style scoped>
.shifts-to-pay-table {
  height: 300px !important;
  overflow-y: auto;
}

.status-tag {
  margin: unset;
}

.loading-spin {
  background-color: rgba(255, 255, 255);
}
</style>
