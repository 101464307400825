<template>
  <Bar
    class="chart"
    :options="chartOptions"
    :data="chartData"
  />
</template>

<script>
import {Bar} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'BarChart',
  components: {Bar},
  props: {
    chartData: {
      type: Object,
      required: true
    },
  },
  computed: {
    chartOptions() {
      return this.$store.state.applicationState.chartOptions;
    },
  }
}
</script>

<style scoped>
.chart {
  margin-bottom: 20px;
}
</style>
