<template>
  <div>
    <h2 class="view-header">Payroll System ID Report</h2>
    <send-notification
      :associate="associateProfileToSendSms"
      @close="closeSmsModal"
    />
    <add-note :associate="associateProfileToAddNote" @close="closeNoteModal" />

    <div class="table_controls_and_data">
      <div class="table_data_wrapper"></div>
      <div class="table_controls_wrapper">
        <div class="table_params"></div>
        <div class="table_predefined_filters">
          <a-checkbox v-model="isWithoutPayrollSystemId" :disabled="loading"
            >Without Payroll System ID</a-checkbox
          >
          <a-checkbox v-model="isVerified" :disabled="loading"
            >Verified</a-checkbox
          >
          <a-checkbox v-model="isActive" :disabled="loading">Active</a-checkbox>
          <a-select
            ref="userSelect"
            show-search
            :allowClear="true"
            placeholder="Associate"
            style="min-width: 180px"
            :filter-option="false"
            :not-found-content="null"
            :dropdown-match-select-width="false"
            :disabled="loading"
            :options="foundAssociates"
            @search="fetchAssociates"
            @change="handleSearchAssociatesChange"
          />
          <a-select
            default-value="0"
            v-model="selectedRole"
            class="role-select"
            placeholder="Job classification"
            :disabled="loading"
          >
            <a-select-option :key="0" :value="0">
              All Job Classifications
            </a-select-option>
            <a-select-option
              v-for="role in roles"
              :key="role.id"
              :value="role.id"
            >
              {{ role.name }}
            </a-select-option>
          </a-select>
          <a-select
            default-value="0"
            v-model="selectedScheduleType"
            placeholder="Schedule Type"
            :disabled="loading"
          >
            <a-select-option :key="0" :value="0">
              All Schedule Types
            </a-select-option>
            <a-select-option
              v-for="type in scheduleTypes"
              :key="type.id"
              :value="type.id"
            >
              {{ type.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>
    <a-table
      rowKey="id"
      size="small"
      :columns="columns"
      :data-source="tableData"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <span slot="associate" slot-scope="text, record" :key="record.photo_url">
        <grouped-associate-info
          :first-name="record.first_name"
          :last-name="record.last_name"
          :photo-url="record.photo_url"
          :associate-number="record.associate_number"
        />
      </span>
      <span slot="isActive" slot-scope="text, record">
        <a-popover v-if="record.suspension" title="Suspension">
          <template slot="content">
            <p>
              <b>Author: </b>
              <span>
                {{
                  combineFullName(
                    record.suspension.author.first_name,
                    record.suspension.author.last_name
                  )
                }}
              </span>
            </p>
            <p>
              <b>Reason</b>
              <span>
                {{ record.suspension.reason }}
              </span>
            </p>
            <p>
              <b>Duration: </b>
              <span v-if="record.suspension.indefinite_period">
                Indefinite
              </span>
              <span v-else>
                {{ record.suspension.days_amount }}
              </span>
            </p>
            <p v-if="!record.suspension.indefinite_period">
              <b>Days left: </b>
              <span>
                {{ record.suspension.remaining_days }}
              </span>
            </p>
          </template>
          <a-tag :color="getTagColor(record)">{{
            getAssociateStatus(record)
          }}</a-tag>
        </a-popover>
        <a-tag v-else :color="getTagColor(record)">{{
          getAssociateStatus(record)
        }}</a-tag>
      </span>
      <span slot="verified" slot-scope="text, record">
        <a-tag :color="getVerifiedTagColor(record)">{{
          record.is_verified ? "YES" : "NO"
        }}</a-tag>
      </span>
      <span slot="scheduleType" slot-scope="text, record">
        {{ renderScheduleType(record.schedule_type) }}
      </span>
      <span slot="payStatus" slot-scope="text, record">
        {{ renderPayStatus(record.payment_info.status) }}
      </span>
      <span slot="rate" slot-scope="text, record">
        {{ renderRate(record.payment_info.rate) }}
      </span>
      <span slot="payrollSystemId" slot-scope="text, record">
        <a-input
          v-model="record.editingPayrollSystemId"
          placeholder="Enter Payroll System ID"
          style="width: 100%"
          @blur="savePayrollSystemId(record)"
        />
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  Table,
  Icon,
  Button,
  Modal,
  Checkbox,
  Select,
  Tag,
  Popover,
  notification,
  Input,
} from "ant-design-vue";
import api from "@/api";
import Util from "@/util";

import store from "@/store";
import SendNotification from "@/components/send-notification.vue";
import AddNote from "@/components/add-note.vue";
import CloneAssociate from "@/components/clone-associate.vue";
import router from "@/router";
import GroupedAssociateInfo from "@/components/grouped-associate-info.vue";

const ScheduleType = {
  FIXED: "fixed",
  SELF_SCHEDULE: "self_schedule",
  FREE: "free",
};

const PayStatus = Object.freeze({
  FULL_TIME: "full_time",
  PART_TIME: "part_time",
  ON_CALL: "on_call",
  TERMINATED: "terminated",
});

const StatusTagColors = Object.freeze({
  GRAY: "#b4b4b4",
  GREEN: "#34db30",
  RED: "#ff4646",
});

export default {
  components: {
    "a-table": Table,
    "a-icon": Icon,

    "a-button": Button,

    "a-modal": Modal,
    "a-checkbox": Checkbox,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-input": Input,

    "a-tag": Tag,
    "a-popover": Popover,

    "send-notification": SendNotification,
    "add-note": AddNote,
    "clone-associate": CloneAssociate,

    "grouped-associate-info": GroupedAssociateInfo,
  },
  mixins: [api],
  data() {
    return {
      associateNumber: undefined,
      roleId: undefined,
      scheduleTypeId: undefined,

      rateFrom: undefined,
      rateTo: undefined,

      showAllPaymentTypes: false,
      isActive: false,
      isVerified: false,
      isWithoutPayrollSystemId: false,

      roles: store.state.applicationState.projectRoles,
      selectedRole: 0,

      scheduleTypes: store.state.applicationState.scheduleTypes,
      selectedScheduleType: 0,

      tableData: [],

      foundAssociates: [],

      columns: [
        {
          title: "Associate ID",
          dataIndex: "id",
          sorter: true,
          width: 100,
          align: "center",
        },
        {
          title: "Associate",
          key: "associate",
          scopedSlots: { customRender: "associate" },
          sorter: true,
          align: "center",
        },
        {
          title: "Status",
          key: "status",
          scopedSlots: { customRender: "isActive" },
          align: "center",
        },
        {
          title: "Verified",
          key: "verified",
          scopedSlots: { customRender: "verified" },
          align: "center",
        },
        {
          title: "Schedule Type",
          key: "schedule_type",
          scopedSlots: { customRender: "scheduleType" },
          sorter: true,
          align: "center",
        },
        {
          title: "Payroll System ID",
          key: "payroll_system_id",
          scopedSlots: { customRender: "payrollSystemId" },
        },
      ],
      pagination: {
        pageSizeOptions: ["10", "50", "100"],
        pageSize: 50,
        current: 1,
        total: 0,
        showSizeChanger: true,
        onChange: (page) => {
          this.pagination.current = page;
          this.loadData();
        },
        onShowSizeChange: (current, size) => {
          this.pagination.pageSize = size;
          this.loadData();
        },
      },
      loading: false,

      associateProfileToSendSms: undefined,
      associateProfileToAddNote: undefined,
    };
  },
  watch: {
    isActive() {
      this.loadData();
    },
    isVerified() {
      this.loadData();
    },
    selectedRole() {
      this.loadData();
    },
    selectedScheduleType() {
      this.loadData();
    },
    isWithoutPayrollSystemId() {
      this.loadData();
    },
    associateNumber() {
      this.loadData();
    },
  },
  computed: {
    selectedRoleFilter() {
      return this.selectedRole != 0 ? this.selectedRole : null;
    },
    selectedScheduleTypeFilter() {
      return this.selectedScheduleType != 0 ? this.selectedScheduleType : null;
    },
    associateProfileModalVisible() {
      return this.editingAssociateNumber != null;
    },
  },
  methods: {
    loadData(sorter) {
      this.loading = true;
      this.apiGetAssociateProfiles(
        this.associateNumber,
        this.selectedRoleFilter,
        this.selectedScheduleTypeFilter,
        this.rateFrom,
        this.rateTo,
        this.showAllPaymentTypes,
        this.isActive,
        this.isVerified,
        this.pagination.current,
        this.pagination.pageSize,
        sorter,
        this.isWithoutPayrollSystemId
      )
        .then((response) => {
          const body = response.body;

          this.tableData = body.associates.map((associate) => ({
            ...associate,
            editingPayrollSystemId: associate.payroll_system_id,
          }));
          this.pagination.total = body.total;
        })
        .finally(() => (this.loading = false));
    },

    renderScheduleType(scheduleType) {
      switch (scheduleType) {
        case ScheduleType.FIXED:
          return "Fixed";
        case ScheduleType.SELF_SCHEDULE:
          return "Self Schedule";
        case ScheduleType.FREE:
          return "Free";
      }
    },
    renderPayStatus(payStatus) {
      switch (payStatus) {
        case PayStatus.TERMINATED:
          return "Terminated";
        case PayStatus.ON_CALL:
          return "On Call";
        case PayStatus.PART_TIME:
          return "Part Time";
        case PayStatus.FULL_TIME:
          return "Full Time";
        default:
          return "N/A";
      }
    },

    renderRate(rateInCents) {
      return rateInCents / 100;
    },

    startEditAssociateProfile(associateNumber) {
      this.associateProfileModalKey++;
      this.editingAssociateNumber = associateNumber;
    },

    openAssociateShiftsList(associateNumber) {
      this.$store.state.applicationState.timeCardsSelectedEmployee =
        associateNumber;
      this.$router.push({ path: "/shifts" });
    },

    endEditAssociateProfile() {
      this.editingAssociateNumber = undefined;
    },

    handleEdited() {
      this.loadData();
    },

    fetchAssociates(value) {
      if (this.searchUsersTimeout) {
        clearTimeout(this.searchUsersTimeout);
      }
      this.searchUsersTimeout = setTimeout(() => {
        this.apiSearchAssociate(value).then((resp) => {
          this.foundAssociates = resp.data.associate_list.map((user) => ({
            label: `[${user.employee_id}] ${user.first_name} ${user.last_name}`,
            key: user.employee_id,
            value: user.employee_id,
          }));
        });
      }, 1000);
    },

    handleSearchAssociatesChange(value) {
      this.associateNumber = value;
      this.$refs.userSelect.blur();
    },

    combineFullName(firstName, lastName) {
      return Util.combineAssociateName(firstName, lastName);
    },

    openSmsSendModal(associate) {
      this.associateProfileToSendSms = associate;
    },
    closeSmsModal() {
      this.associateProfileToSendSms = undefined;
    },
    openNoteModal(associate) {
      this.associateProfileToAddNote = associate;
    },
    closeNoteModal() {
      this.associateProfileToAddNote = undefined;
    },
    getTagColor(record) {
      if (!record.is_active) {
        return StatusTagColors.GRAY;
      }
      if (record.suspension) {
        return StatusTagColors.RED;
      }

      return StatusTagColors.GREEN;
    },
    getVerifiedTagColor(record) {
      return record.is_verified ? StatusTagColors.GREEN : StatusTagColors.RED;
    },
    getAssociateStatus(record) {
      if (!record.is_active) {
        return "TERMINATED";
      }
      if (record.suspension) {
        return "SUSPENDED";
      }

      return "ACTIVE";
    },
    handleTableChange(pagination, filters, sorter) {
      this.loadData(sorter);
    },

    openNewUserWithAssociateView() {
      router.push("/create-user-with-associate");
    },

    setPayrollSystemId(associateId, payrollSystemId) {
      this.apiSetPayrollSystemId(associateId, payrollSystemId).then(
        ({ body }) => {
          if (body.error_code && body.error_code !== "0") {
            notification["warning"]({
              message: "Error",
              description: `An error occurred while setting payroll system ID ${payrollSystemId} for associate ID #${associateId}`,
            });

            return;
          }

          notification["success"]({
            message: "Success",
            description: `Payroll system ID ${payrollSystemId} set for associate ID ${associateId}`,
          });
        }
      );
    },

    savePayrollSystemId(record) {
      const payrollSystemId = record.editingPayrollSystemId;

      this.setPayrollSystemId(record.id, payrollSystemId)
        .then(({ body }) => {
          if (body.error_code && body.error_code !== "0") {
            notification["warning"]({
              message: "Error",
              description: `An error occurred while setting payroll system ID ${payrollSystemId} for associate ID #${record.id}`,
            });
            this.$set(record, "payroll_system_id", null);
          } else {
            this.$set(record, "payroll_system_id", payrollSystemId);

            notification["success"]({
              message: "Success",
              description: `Payroll system ID ${payrollSystemId} has been set for associate ID #${record.id}`,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          notification["error"]({
            message: "Error",
            description: "Failed to save Payroll System ID. Please try again.",
          });
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style>
.associate-profile-modal-wrapper {
  overflow-x: auto;
  width: 100%;
  height: 600px;
}
</style>
