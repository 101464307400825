<template>
  <div>
    <h2 class="view-header">Daily Summary for {{ dailySummaryDisplayDate }}</h2>

    <shift-view
      :visible.sync="visibleShiftModal"
      :shift_id="editingShiftId"
      @edit="loadData"
    />

    <send-notification
      :associate="associateProfileToSendSms"
      @close="closeSmsModal"
    />

    <daily-summary-filters
      :current-filters="filters"
      :visible="filtersModalVisible"
      :key="filtersModalKey"
      @apply="applyFilters"
    />

    <div class="flex align-items-center justify-content-end gap-2">
      <a-button-group>
        <a-tooltip>
          <template slot="title"> Open Filters </template>
          <a-button
            icon="filter"
            :disabled="loading"
            @click="showDailySummaryFilterModal"
          />
        </a-tooltip>

        <a-tooltip>
          <template slot="title"> Reset Filters </template>
          <a-button
            icon="undo"
            :disabled="loading || disableResetButton"
            @click="resetDailySummaryFilters"
          />
        </a-tooltip>
      </a-button-group>
      <a-date-picker
        v-model="reportDate"
        placeholder="Report Date"
        :disabled="loading"
        :format="visualDateFormat"
        :allow-clear="false"
      />
    </div>
    <div class="daily-summary flex flex-column gap-3">
      <h3>Stats</h3>
      <div class="content-view">
        <div class="content-view-block">
          <h4>Totals</h4>
          <p>
            <b>Scheduled</b>
            <span>{{
              loading ? "Loading..." : schedulerShiftsDataLength
            }}</span>
          </p>
          <p>
            <b>Self-Created</b>
            <span>{{
              loading ? "Loading..." : selfCreatedShiftsDataLength
            }}</span>
          </p>
          <p>
            <b>Manually Created</b>
            <span>{{
              loading ? "Loading..." : manuallyCreatedShiftsDataLength
            }}</span>
          </p>
          <p>
            <b>Didn't Work</b>
            <span>{{
              loading ? "Loading..." : associatesNotWorkingDataLength
            }}</span>
          </p>
        </div>
        <div class="content-view-block">
          <h4>Shift Status</h4>
          <span v-if="loading">Loading...</span>
          <bar-chart
            v-else
            class="daily-summary-chart"
            :chart-data="shiftStatusChartData"
          />
        </div>
        <div class="content-view-block">
          <h4>Shift Payment Status</h4>
          <span v-if="loading">Loading...</span>
          <bar-chart
            v-else
            class="daily-summary-chart"
            :chart-data="shiftPaymentStatusChartData"
          />
        </div>
        <div class="content-view-block">
          <h4>Auth Types</h4>
          <span v-if="loading">Loading...</span>
          <bar-chart
            v-else
            class="daily-summary-chart"
            :chart-data="authTypesChartData"
          />
        </div>
      </div>
      <div class="table-section">
        <div class="flex flex-row align-items-center justify-content-between">
          <h3>Scheduled</h3>
          <!--          <h3 v-if="!loading">Total: {{ schedulerShiftsDataLength }}</h3>-->
        </div>
        <a-table
          size="small"
          row-key="associate.associate_number"
          :expand-row-by-click="true"
          :loading="loading"
          :columns="shiftsColumns"
          :data-source="schedulerShiftsData"
          :expanded-row-render="expandedRowRender"
        >
          <span slot="associate" slot-scope="text, record">
            <span class="flex flex-column gap-1">
              <span>{{ record.associate.associate_number }}</span>
              <span>{{
                capitalizeWords(
                  record.associate.first_name + " " + record.associate.last_name
                )
              }}</span>
            </span>
          </span>
          <span slot="status" slot-scope="text, record">
            <a-tooltip>
              <template slot="title">
                {{ getShiftStatusTagText(record.shift.status) }}
              </template>
              <a-tag :color="getAuthStatusTagColor(record.shift.status)">
                {{ getShiftStatusTagLabel(record.shift.status) }}
              </a-tag>
            </a-tooltip>
          </span>
          <span slot="start" slot-scope="text, record">
            {{ getScheduledStart(record) }}
          </span>
          <span slot="end" slot-scope="text, record">
            {{ getScheduledEnd(record) }}
          </span>
          <span slot="duration" slot-scope="text, record">
            {{ getScheduledDuration(record) }}
          </span>
          <span slot="completed_auths" slot-scope="text, record">
            <span
              v-if="
                record.shift.completed_auths &&
                record.shift.completed_auths.length > 0
              "
              class="flex flex-column gap-2"
            >
              <a-tag
                class="auth-tag"
                v-for="auth in record.shift.completed_auths"
                :key="auth"
              >
                {{ convertType(auth) }}
              </a-tag>
            </span>
            <span v-else>-</span>
          </span>
          <span slot="origin" slot-scope="text, record">
            <a-tag :color="getOriginColor(record.shift.origin_label)">
              {{ record.shift.origin_label }}
            </a-tag>
          </span>
          <span slot="actions" slot-scope="text, record">
            <a-button-group size="small">
              <a-tooltip>
                <template slot="title"> Open Shift </template>
                <a-button icon="eye" @click="openShift(record)" />
              </a-tooltip>

              <a-tooltip>
                <template slot="title"> Send Message </template>
                <a-button
                  title="Send Message"
                  icon="message"
                  @click="openSmsSendModal(record)"
                />
              </a-tooltip>

              <a-tooltip>
                <template slot="title"> Delete Shift </template>
                <a-button
                  title="Delete Shift"
                  icon="delete"
                  @click="
                    openShiftDeletionConfirmationModal(
                      record.shift.id,
                      record.version
                    )
                  "
                />
              </a-tooltip>
            </a-button-group>
          </span>
        </a-table>
      </div>
      <div class="table-section">
        <div class="flex flex-row align-items-center justify-content-between">
          <h3>Self-Created</h3>
        </div>
        <a-table
          size="small"
          row-key="associate.associate_number"
          :expand-row-by-click="true"
          :loading="loading"
          :columns="shiftsColumns"
          :data-source="selfCreatedShiftsData"
          :expanded-row-render="expandedRowRender"
        >
          <span slot="associate" slot-scope="text, record">
            <span class="flex flex-column gap-1">
              <span>{{ record.associate.associate_number }}</span>
              <span>{{
                capitalizeWords(
                  record.associate.first_name + " " + record.associate.last_name
                )
              }}</span>
            </span>
          </span>
          <span slot="status" slot-scope="text, record">
            <a-tooltip>
              <template slot="title">
                {{ getShiftStatusTagText(record.shift.status) }}
              </template>
              <a-tag :color="getAuthStatusTagColor(record.shift.status)">
                {{ getShiftStatusTagLabel(record.shift.status) }}
              </a-tag>
            </a-tooltip>
          </span>
          <span slot="start" slot-scope="text, record">
            {{ getSignedStart(record) ?? getScheduledStart(record) }}
          </span>
          <span slot="end" slot-scope="text, record">
            {{ getSignedEnd(record) ?? getScheduledEnd(record) }}
          </span>
          <span slot="duration" slot-scope="text, record">
            {{ getSignedDuration(record) }}
          </span>
          <span slot="completed_auths" slot-scope="text, record">
            <span
              v-if="
                record.shift.completed_auths &&
                record.shift.completed_auths.length > 0
              "
              class="flex flex-column gap-2"
            >
              <a-tag
                class="auth-tag"
                v-for="auth in record.shift.completed_auths"
                :key="auth"
              >
                {{ convertType(auth) }}
              </a-tag>
            </span>
            <span v-else>-</span>
          </span>
          <span slot="origin" slot-scope="text, record">
            <a-tag :color="getOriginColor(record.shift.origin_label)">
              {{ record.shift.origin_label }}
            </a-tag>
          </span>
          <span slot="actions" slot-scope="text, record">
            <a-button-group size="small">
              <a-tooltip>
                <template slot="title"> Open Shift </template>
                <a-button icon="eye" @click="openShift(record)" />
              </a-tooltip>

              <a-tooltip>
                <template slot="title"> Send Message </template>
                <a-button
                  title="Send Message"
                  icon="message"
                  @click="openSmsSendModal(record)"
                />
              </a-tooltip>

              <a-tooltip>
                <template slot="title"> Delete Shift </template>
                <a-button
                  title="Delete Shift"
                  icon="delete"
                  @click="
                    openShiftDeletionConfirmationModal(
                      record.shift.id,
                      record.version
                    )
                  "
                />
              </a-tooltip>
            </a-button-group>
          </span>
        </a-table>
      </div>
      <div class="table-section">
        <div class="flex flex-row align-items-center justify-content-between">
          <h3>Manually Created</h3>
          <!--          <h3 v-if="!loading">Total: {{ manuallyCreatedShiftsDataLength }}</h3>-->
        </div>
        <a-table
          size="small"
          row-key="associate.associate_number"
          :expand-row-by-click="true"
          :loading="loading"
          :columns="shiftsColumns"
          :data-source="manuallyCreatedShiftsData"
          :expanded-row-render="expandedRowRender"
        >
          <span slot="associate" slot-scope="text, record">
            <span class="flex flex-column gap-1">
              <span>{{ record.associate.associate_number }}</span>
              <span>{{
                capitalizeWords(
                  record.associate.first_name + " " + record.associate.last_name
                )
              }}</span>
            </span>
          </span>
          <span slot="status" slot-scope="text, record">
            <a-tooltip>
              <template slot="title">
                {{ getShiftStatusTagText(record.shift.status) }}
              </template>
              <a-tag :color="getAuthStatusTagColor(record.shift.status)">
                {{ getShiftStatusTagLabel(record.shift.status) }}
              </a-tag>
            </a-tooltip>
          </span>
          <span slot="start" slot-scope="text, record">
            {{ getSignedStart(record) ?? getScheduledStart(record) }}
          </span>
          <span slot="end" slot-scope="text, record">
            {{ getSignedEnd(record) ?? getScheduledEnd(record) }}
          </span>
          <span slot="duration" slot-scope="text, record">
            {{ getSignedDuration(record) }}
          </span>
          <span slot="completed_auths" slot-scope="text, record">
            <span
              v-if="
                record.shift.completed_auths &&
                record.shift.completed_auths.length > 0
              "
              class="flex flex-column gap-2"
            >
              <a-tag
                class="auth-tag"
                v-for="auth in record.shift.completed_auths"
                :key="auth"
              >
                {{ convertType(auth) }}
              </a-tag>
            </span>
            <span v-else>-</span>
          </span>
          <span slot="origin" slot-scope="text, record">
            <a-tag :color="getOriginColor(record.shift.origin_label)">
              {{ record.shift.origin_label }}
            </a-tag>
          </span>
          <span slot="actions" slot-scope="text, record">
            <a-button-group size="small">
              <a-tooltip>
                <template slot="title"> Open Shift </template>
                <a-button icon="eye" @click="openShift(record)" />
              </a-tooltip>

              <a-tooltip>
                <template slot="title"> Send Message </template>
                <a-button
                  title="Send Message"
                  icon="message"
                  @click="openSmsSendModal(record)"
                />
              </a-tooltip>

              <a-tooltip>
                <template slot="title"> Delete Shift </template>
                <a-button
                  title="Delete Shift"
                  icon="delete"
                  @click="
                    openShiftDeletionConfirmationModal(
                      record.shift.id,
                      record.version
                    )
                  "
                />
              </a-tooltip>
            </a-button-group>
          </span>
        </a-table>
      </div>
      <div class="table-section">
        <div class="flex flex-row align-items-center justify-content-between">
          <h3>Pay Codes</h3>
        </div>
        <a-table
          size="small"
          row-key="associate.associate_number"
          :loading="loading"
          :columns="payCodesColumns"
          :data-source="payCodesData"
        >
          <span slot="associate" slot-scope="text, record">
            <span class="flex flex-column gap-1">
              <span>{{ record.associate.associate_number }}</span>
              <span>{{
                capitalizeWords(
                  record.associate.first_name + " " + record.associate.last_name
                )
              }}</span>
            </span>
          </span>
          <span slot="name" slot-scope="text, record">
            {{ record.shift.pay_code.name }}
          </span>
          <span slot="type" slot-scope="text, record">
            {{ capitalize(record.shift.pay_code.type) }}
          </span>
          <span slot="value" slot-scope="text, record">
            {{ record.shift.pay_code.value }}
          </span>
          <span slot="actions" slot-scope="text, record">
            <a-button-group size="small">
              <a-tooltip>
                <template slot="title"> Send Message </template>
                <a-button
                  title="Send Message"
                  icon="message"
                  @click="openSmsSendModal(record)"
                />
              </a-tooltip>
            </a-button-group>
          </span>
        </a-table>
      </div>
      <div class="table-section">
        <div class="flex flex-row align-items-center justify-content-between">
          <h3>Didn't Work</h3>
          <!--          <h3 v-if="!loading">Total: {{ associatesNotWorkingDataLength }}</h3>-->
        </div>
        <a-table
          size="small"
          row-key="associate.associate_number"
          :expand-row-by-click="true"
          :loading="loading"
          :columns="nonWorkingAssociatesColumns"
          :data-source="associatesNotWorkingData"
        >
          <span slot="name" slot-scope="text, record">
            {{
              capitalizeWords(
                record.associate.first_name + " " + record.associate.last_name
              )
            }}
          </span>
          <span slot="payment_type" slot-scope="text, record">
            {{ record.associate.payment_type }}
          </span>
          <span slot="legal_entity" slot-scope="text, record">
            {{
              record.associate.legal_entity
                ? record.associate.legal_entity.name
                : "-"
            }}
          </span>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  DatePicker,
  Spin,
  Tooltip,
  Tag,
  notification,
  Modal,
  Button,
} from "ant-design-vue";
import moment from "moment-timezone";
import api from "@/api";
import Util from "@/util";
import shiftsHelper from "@/helpers/shifts";
import shiftAuths from "@/helpers/shift-auths";
import SendNotification from "@/components/send-notification.vue";
import DailySummaryFilters from "@/components/daily-summary-filters.vue";
import BarChart from "@/components/bar-chart.vue";

import {
  getShiftStatusTagText,
  getShiftStatusTagLabel,
  getAuthStatusTagColor,
} from "@/const";
import util from "@/util";
import ShiftViewModal from "@/modules/shifts/components/shift-view-modal.vue";

const filtersBaseState = Object.freeze({
  shiftStatus: undefined,
  shiftPaymentStatus: undefined,
  role: undefined,
  legalEntity: undefined,
  associates: undefined,
});

export default {
  components: {
    SendNotification,
    "a-table": Table,
    "a-date-picker": DatePicker,
    "a-spin": Spin,
    "a-tooltip": Tooltip,
    "a-tag": Tag,
    "a-modal": Modal,
    "a-button": Button,
    "a-button-group": Button.Group,
    "send-notification": SendNotification,
    "daily-summary-filters": DailySummaryFilters,
    "bar-chart": BarChart,
    "shift-view": ShiftViewModal,
  },
  mixins: [api, shiftsHelper],
  data() {
    return {
      reportDate: moment().subtract(90, "day").startOf("day"),
      loading: false,
      schedulerShiftsData: [],
      selfCreatedShiftsData: [],
      manuallyCreatedShiftsData: [],
      associatesNotWorkingData: [],
      payCodesData: [],

      visibleShiftModal: false,

      editingShiftId: undefined,
      associateProfileToSendSms: undefined,

      shiftsColumns: [
        {
          title: "Shift ID",
          dataIndex: "shift.id",
          align: "center",
        },
        {
          title: "Associate",
          key: "associate",
          scopedSlots: { customRender: "associate" },
          width: 150,
          align: "center",
        },
        {
          title: "Job Classification",
          key: "role",
          dataIndex: "associate.role.name",
          width: 120,
          align: "center",
        },
        {
          title: "Status",
          scopedSlots: { customRender: "status" },
          align: "center",
        },
        {
          title: "Authorizations",
          key: "completed_auths",
          scopedSlots: { customRender: "completed_auths" },
          align: "center",
        },
        {
          title: "Start",
          scopedSlots: { customRender: "start" },
          align: "center",
        },
        {
          title: "End",
          scopedSlots: { customRender: "end" },
          align: "center",
        },
        {
          title: "Duration",
          scopedSlots: { customRender: "duration" },
          align: "center",
        },
        {
          title: "Origin",
          key: "origin",
          scopedSlots: { customRender: "origin" },
          align: "center",
        },
        {
          title: "Shift Actions",
          key: "locationsList",
          scopedSlots: { customRender: "locationsList" },
        },
        {
          title: "Actions",
          key: "actions",
          scopedSlots: { customRender: "actions" },
        },
      ],
      payCodesColumns: [
        {
          title: "Shift ID",
          dataIndex: "shift.id",
          align: "center",
        },
        {
          title: "Associate",
          key: "associate",
          scopedSlots: { customRender: "associate" },
          align: "center",
        },
        {
          title: "Job Classification",
          key: "role",
          dataIndex: "associate.role.name",
          align: "center",
        },
        {
          title: "Name",
          key: "name",
          dataIndex: "name",
          align: "center",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "Type",
          key: "type",
          dataIndex: "type",
          align: "center",
          scopedSlots: { customRender: "type" },
        },
        {
          title: "Value",
          key: "value",
          dataIndex: "value",
          align: "center",
          scopedSlots: { customRender: "value" },
        },
        {
          title: "Actions",
          key: "actions",
          scopedSlots: { customRender: "actions" },
        },
      ],
      nonWorkingAssociatesColumns: [
        {
          title: "Associate Number",
          key: "associate_number",
          dataIndex: "associate.associate_number",
          align: "center",
        },
        {
          title: "Name",
          key: "name",
          scopedSlots: { customRender: "name" },
          align: "center",
        },
        {
          title: "Job Classification",
          key: "role",
          dataIndex: "associate.role.name",
          align: "center",
        },
        {
          title: "Payment Type",
          key: "payment_type",
          dataIndex: "associate.payment_type",
          align: "center",
        },
        {
          title: "Agency",
          key: "legal_entity",
          scopedSlots: { customRender: "legal_entity" },
          align: "center",
        },
      ],

      nestedColumns: [
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
        },
        {
          title: "Start",
          dataIndex: "start",
        },
        {
          title: "End",
          dataIndex: "end",
        },
        {
          title: "Dur",
          dataIndex: "duration",
        },
        {
          title: "Work Hrs",
          dataIndex: "work_hours",
        },
        {
          title: "Pay Hrs",
          dataIndex: "pay_hours",
        },
        {
          title: "L1 Start",
          dataIndex: "first_lunch_start",
        },
        {
          title: "L1 End",
          dataIndex: "first_lunch_end",
        },
        {
          title: "L1 Dur",
          dataIndex: "first_lunch_duration",
        },
        {
          title: "L2 Start",
          dataIndex: "second_lunch_start",
        },
        {
          title: "L2 End",
          dataIndex: "second_lunch_end",
        },
        {
          title: "L2 Dur",
          dataIndex: "second_lunch_duration",
        },
      ],

      filters: { ...filtersBaseState },
      filtersModalVisible: false,
      filtersModalKey: 0,

      shiftStatusMap: {},
      shiftPaymentStatusMap: {},
      providedAuthTypesMap: {},
    };
  },
  watch: {
    reportDate() {
      this.loadData();
    },
    filters: {
      handler(newVal, prevVal) {
        if (JSON.stringify(newVal) != JSON.stringify(prevVal)) {
          this.loadData();
        }
      },
      deep: true,
    },
  },
  computed: {
    isoReportDate() {
      return this.convertDateTimeToServerFormat(this.reportDate);
    },

    schedulerShiftsDataLength() {
      if (!this.schedulerShiftsData) {
        return 0;
      }

      return this.schedulerShiftsData.length;
    },
    selfCreatedShiftsDataLength() {
      if (!this.selfCreatedShiftsData) {
        return 0;
      }

      return this.selfCreatedShiftsData.length;
    },
    manuallyCreatedShiftsDataLength() {
      if (!this.manuallyCreatedShiftsData) {
        return 0;
      }

      return this.manuallyCreatedShiftsData.length;
    },
    associatesNotWorkingDataLength() {
      if (!this.associatesNotWorkingData) {
        return 0;
      }

      return this.associatesNotWorkingData.length;
    },

    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    dailySummaryDisplayDate() {
      return this.reportDate.format(this.visualDateFormat);
    },

    disableResetButton() {
      return JSON.stringify(filtersBaseState) == JSON.stringify(this.filters);
    },

    shiftStatusChartData() {
      const labels = [];
      const data = [];
      const colors = [];

      Object.keys(this.shiftStatusMap).forEach((key) => {
        labels.push(this.getShiftStatusTagLabel(key));
        data.push(this.shiftStatusMap[key]);
        colors.push(this.getRandomColor());
      });

      return {
        labels,
        datasets: [
          {
            label: "Shift Status Count",
            backgroundColor: colors,
            data,
          },
        ],
      };
    },
    shiftPaymentStatusChartData() {
      const labels = [];
      const data = [];
      const colors = [];

      Object.keys(this.shiftPaymentStatusMap).forEach((key) => {
        labels.push(this.getShiftStatusTagLabel(key));
        data.push(this.shiftPaymentStatusMap[key]);
        colors.push(this.getRandomColor());
      });

      return {
        labels,
        datasets: [
          {
            label: "Shift Payment Status Count",
            backgroundColor: colors,
            data,
          },
        ],
      };
    },
    authTypesChartData() {
      const labels = [];
      const data = [];
      const colors = [];

      Object.keys(this.providedAuthTypesMap).forEach((key) => {
        labels.push(this.convertType(key));
        data.push(this.providedAuthTypesMap[key]);
        colors.push(this.getRandomColor());
      });

      return {
        labels,
        datasets: [
          {
            label: "Auth Types Count",
            backgroundColor: colors,
            data,
          },
        ],
      };
    },
  },
  methods: {
    convertType: shiftAuths.convertType,
    capitalize: util.capitalize,
    getShiftStatusTagText,
    getShiftStatusTagLabel,
    getAuthStatusTagColor,

    showReportLoadingError() {
      notification["warning"]({
        message: "Report Data Loading Error",
        description: "Please contact the administrator",
      });
    },
    clearDataArrays() {
      this.schedulerShiftsData = [];
      this.selfCreatedShiftsData = [];
      this.manuallyCreatedShiftsData = [];
      this.associatesNotWorkingData = [];
    },
    loadData() {
      this.clearDataArrays();
      this.loading = true;

      if (!this.isoReportDate) {
        this.loading = false;
        return;
      }

      this.apiGetGeneralDailyReport(this.isoReportDate, this.filters)
        .then(({ data }) => {
          if (data.error_code && data.error_code !== "0") {
            this.showReportLoadingError();
            return;
          }

          this.shiftStatusMap = {};
          this.shiftPaymentStatusMap = {};
          this.providedAuthTypesMap = {};

          data.result.forEach((record) => {
            if (record.shift == null) {
              this.associatesNotWorkingData.push(record);
              return;
            }

            this.shiftStatusMap[record.shift.status] =
              (this.shiftStatusMap[record.shift.status] ?? 0) + 1;
            this.shiftPaymentStatusMap[record.shift.payment_status] =
              (this.shiftPaymentStatusMap[record.shift.payment_status] ?? 0) +
              1;

            record.shift.completed_auths.forEach((auth) => {
              this.providedAuthTypesMap[auth] =
                (this.providedAuthTypesMap[auth] ?? 0) + 1;
            });

            const formattedDurations = this.formatShiftDurations({
              scheduled_start: record.shift.scheduled_start,
              scheduled_end: record.shift.scheduled_end,
              work_start: record.shift.actual_work_start,
              work_end: record.shift.actual_work_end,
              signed_start: record.shift.signed_start,
              signed_end: record.shift.signed_end,
              lunch_start: record.shift.actual_lunch_start,
              lunch_end: record.shift.actual_lunch_end,
              signed_lunch_start: record.shift.lunch_start,
              signed_lunch_end: record.shift.lunch_end,
              second_lunch_start: record.shift.actual_second_lunch_start,
              second_lunch_end: record.shift.actual_second_lunch_end,
              signed_second_lunch_start: record.shift.second_lunch_start,
              signed_second_lunch_end: record.shift.second_lunch_end,
            });

            let statusToShow;

            if (record.shift.status !== "completed") {
              statusToShow = record.shift.status;
            } else {
              statusToShow = record.shift.payment_status;
            }

            const processedRecord = {
              ...record,
              shift: {
                ...record.shift,
                scheduled_start: formattedDurations.scheduled.start,
                scheduled_end: formattedDurations.scheduled.end,
                actual_start: formattedDurations.actual.start,
                actual_end: formattedDurations.actual.end,
                signed_start: formattedDurations.signed.start,
                signed_end: formattedDurations.signed.end,

                status: statusToShow,

                scheduled_duration: record.shift.scheduled_duration
                  ? this.minutesToHoursAndMinutes(
                      record.shift.scheduled_duration
                    )
                  : "-",
                actual_duration: record.shift.actual_duration
                  ? this.minutesToHoursAndMinutes(record.shift.actual_duration)
                  : "-",
                signed_duration: record.shift.duration
                  ? this.minutesToHoursAndMinutes(record.shift.duration)
                  : "-",

                actual_first_lunch_start:
                  formattedDurations.actual.first_lunch_start,
                actual_first_lunch_end:
                  formattedDurations.actual.first_lunch_end,
                actual_first_lunch_duration: record.shift.actual_lunch_duration
                  ? Util.formatMinutesToHoursAndMinutesDuration(
                      record.shift.actual_lunch_duration
                    )
                  : "-",

                signed_first_lunch_start:
                  formattedDurations.signed.first_lunch_start,
                signed_first_lunch_end:
                  formattedDurations.signed.first_lunch_end,
                signed_first_lunch_duration: record.shift.lunch_duration
                  ? Util.formatMinutesToHoursAndMinutesDuration(
                      record.shift.lunch_duration
                    )
                  : "-",

                actual_second_lunch_start:
                  formattedDurations.actual.second_lunch_start,
                actual_second_lunch_end:
                  formattedDurations.actual.second_lunch_end,
                actual_second_lunch_duration: record.shift
                  .actual_second_lunch_duration
                  ? Util.formatMinutesToHoursAndMinutesDuration(
                      record.shift.actual_second_lunch_duration
                    )
                  : "-",

                signed_second_lunch_start:
                  formattedDurations.signed.second_lunch_start,
                signed_second_lunch_end:
                  formattedDurations.signed.second_lunch_end,
                signed_second_lunch_duration: record.shift.second_lunch_duration
                  ? Util.formatMinutesToHoursAndMinutesDuration(
                      record.shift.second_lunch_duration
                    )
                  : "-",

                work_hours: this.formatWorkHours(
                  record.shift.signed_start
                    ? moment.utc(record.shift.signed_start)
                    : null,
                  record.shift.signed_end
                    ? moment.utc(record.shift.signed_end)
                    : null,
                  record.shift.lunch_start
                    ? moment.utc(record.shift.lunch_start)
                    : null,
                  record.shift.lunch_end
                    ? moment.utc(record.shift.lunch_end)
                    : null,
                  record.shift.second_lunch_start
                    ? moment.utc(record.shift.second_lunch_start)
                    : null,
                  record.shift.second_lunch_end
                    ? moment.utc(record.shift.second_lunch_end)
                    : null
                ),
                pay_hours: this.formatPayHours(
                  record.shift.scheduled_start
                    ? moment.utc(record.shift.scheduled_start)
                    : null,
                  record.shift.scheduled_end
                    ? moment.utc(record.shift.scheduled_end)
                    : null,
                  record.shift.signed_start
                    ? moment.utc(record.shift.signed_start)
                    : null,
                  record.shift.signed_end
                    ? moment.utc(record.shift.signed_end)
                    : null,
                  record.shift.lunch_start
                    ? moment.utc(record.shift.lunch_start)
                    : null,
                  record.shift.lunch_end
                    ? moment.utc(record.shift.lunch_end)
                    : null,
                  record.shift.second_lunch_start
                    ? moment.utc(record.shift.second_lunch_start)
                    : null,
                  record.shift.second_lunch_end
                    ? moment.utc(record.shift.second_lunch_end)
                    : null
                ),
              },
            };

            if (this.shiftIsPaycode(record)) {
              this.payCodesData.push(processedRecord);
            } else if (this.shiftIsFromScheduler(record)) {
              this.schedulerShiftsData.push(processedRecord);
            } else if (this.shiftIsSelfCreated(record)) {
              this.selfCreatedShiftsData.push(processedRecord);
            } else if (this.shiftIsManuallyCreated(record)) {
              this.manuallyCreatedShiftsData.push(processedRecord);
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    convertDateTimeToServerFormat(dateTime) {
      if (!dateTime) {
        return null;
      }

      return moment(dateTime).format("YYYY-MM-DD");
    },
    shiftAvailableInRecord(record) {
      return record.shift != null;
    },
    shiftIsFromScheduler(record) {
      if (!this.shiftAvailableInRecord(record)) {
        return false;
      }
      return record.shift.origin === "schedule";
    },
    shiftIsSelfCreated(record) {
      if (!this.shiftAvailableInRecord(record)) {
        return false;
      }
      return (
        !this.shiftIsManuallyCreated(record) &&
        record.shift.origin == "whereami"
      );
    },
    shiftIsManuallyCreated(record) {
      if (!this.shiftAvailableInRecord(record)) {
        return false;
      }
      return record.shift.manually_created;
    },

    getScheduledStart(record) {
      if (!this.shiftAvailableInRecord(record)) {
        return "-";
      }

      return record.shift.scheduled_start ?? "-";
    },
    getScheduledEnd(record) {
      if (!this.shiftAvailableInRecord(record)) {
        return "-";
      }

      return record.shift.scheduled_end ?? "-";
    },
    getScheduledDuration(record) {
      if (!this.shiftAvailableInRecord(record)) {
        return "-";
      }

      return record.shift.scheduled_duration ?? "-";
    },

    getSignedStart(record) {
      if (!this.shiftAvailableInRecord(record)) {
        return "-";
      }

      return record.shift.signed_start ?? null;
    },
    getSignedEnd(record) {
      if (!this.shiftAvailableInRecord(record)) {
        return "-";
      }

      return record.shift.signed_end ?? "-";
    },
    getSignedDuration(record) {
      if (!this.shiftAvailableInRecord(record)) {
        return "-";
      }

      return record.shift.signed_duration ?? null;
    },

    capitalizeWords(str) {
      return str.replace(/\b\w/g, (char) => char.toUpperCase());
    },

    expandedRowRender(record) {
      const data = [
        {
          key: "scheduled",
          type: "Scheduled",
          start: record.shift.scheduled_start,
          end: record.shift.scheduled_end,
          duration: record.shift.scheduled_duration,
          work_hours: "-",
          pay_hours: "-",
          first_lunch_start: "-",
          first_lunch_end: "-",
          first_lunch_duration: "-",
          second_lunch_start: "-",
          second_lunch_end: "-",
          second_lunch_duration: "-",
        },
        {
          key: "actual",
          type: "Actual",
          start: record.shift.actual_start,
          end: record.shift.actual_end,
          duration: record.shift.actual_duration,
          work_hours: "-",
          pay_hours: "-",
          first_lunch_start: record.shift.actual_first_lunch_start,
          first_lunch_end: record.shift.actual_first_lunch_end,
          first_lunch_duration: record.shift.actual_first_lunch_duration,
          second_lunch_start: record.shift.actual_second_lunch_start,
          second_lunch_end: record.shift.actual_second_lunch_end,
          second_lunch_duration: record.shift.actual_second_lunch_duration,
        },
        {
          key: "signed",
          type: "Signed",
          start: record.shift.signed_start,
          end: record.shift.signed_end,
          duration: record.shift.signed_duration,
          work_hours: record.shift.work_hours,
          pay_hours: record.shift.pay_hours,
          first_lunch_start: record.shift.signed_first_lunch_start,
          first_lunch_end: record.shift.signed_first_lunch_end,
          first_lunch_duration: record.shift.signed_first_lunch_duration,
          second_lunch_start: record.shift.signed_second_lunch_start,
          second_lunch_end: record.shift.signed_second_lunch_end,
          second_lunch_duration: record.shift.signed_second_lunch_duration,
        },
      ];

      return (
        <a-table
          size="small"
          row-key={String(record.shift.id)}
          columns={this.nestedColumns}
          dataSource={data}
          pagination={false}
        />
      );
    },

    openShift(record) {
      this.editingShiftId = record.shift.id;
      this.visibleShiftModal = true;
    },

    openSmsSendModal(record) {
      this.associateProfileToSendSms = {
        associate_number: record.associate.associate_number,
        first_name: record.associate.first_name,
        last_name: record.associate.last_name,
      };
    },

    closeSmsModal() {
      this.associateProfileToSendSms = undefined;
    },

    showDailySummaryFilterModal() {
      ++this.filtersModalKey;
      this.filtersModalVisible = true;
    },

    hideDailySummaryFilterModal() {
      this.filtersModalVisible = false;
    },

    resetDailySummaryFilters() {
      this.filters = { ...filtersBaseState };
    },

    applyFilters(filters) {
      this.hideDailySummaryFilterModal();
      this.filters = { ...filters };
    },

    // generates bright colors
    getRandomColor() {
      const getLightColorComponent = () => {
        return Math.floor(Math.random() * 206) + 50;
      };

      const r = getLightColorComponent().toString(16);
      const g = getLightColorComponent().toString(16);
      const b = getLightColorComponent().toString(16);

      return `#${r}${g}${b}`;
    },

    getOriginColor(origin) {
      switch (origin) {
        case "Time Tracker":
          return "#7432FF";
        case "Scheduler":
          return "#52BFFF";
        case "Marketplace":
          return "#FF4646";
      }
    },

    shiftIsPaycode(record) {
      return !!record.shift.pay_code;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.daily-summary h3 {
  margin: 10px 30px 0;
  text-align: left;
}

.table-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stats-section {
  display: flex;
  gap: 20px;
}

.auth-tag {
  text-align: center;
}

.daily-summary-chart {
  width: 270px;
  height: 150px;
}
</style>
