import { PAYMENT_STATUS } from "./constants";

export default {
  getPaymentStatus() {
    return (status, method) => {
      const initialStatus = status.toUpperCase();

      if (method == "Manual" && initialStatus === "PENDING") {
        return {
          title: "Awaiting payment",
          color: "#ffb546",
        };
      }

      return (
        PAYMENT_STATUS[initialStatus.toUpperCase()] ?? PAYMENT_STATUS.CREATED
      );
    };
  },
};
