import {
  SHIFT_ORIGIN_COLORS,
  SHIFT_PAYMENT_REQUEST_STATUS,
  SHIFT_STATUS,
  SHIFT_PAYMENT_STATUS,
} from "./constants";

export default {
  getActiveShiftId(state) {
    return state.shift?.id ?? undefined;
  },
  getShiftPaymentRequestStatus() {
    return (status) => {
      return (
        SHIFT_PAYMENT_REQUEST_STATUS[status] ?? {
          title: status,
          color: SHIFT_PAYMENT_REQUEST_STATUS.draft.color,
        }
      );
    };
  },
  getShiftPaymentStatus() {
    return (status) => {
      return (
        SHIFT_PAYMENT_STATUS[status] ?? {
          title: status,
          color: SHIFT_PAYMENT_STATUS.not_paid.color,
        }
      );
    };
  },
  getShiftStatus() {
    return (status) => {
      return (
        SHIFT_STATUS[status] ?? {
          title: status,
          color: SHIFT_STATUS.scheduled.color,
          text: "",
        }
      );
    };
  },
  getOriginColor() {
    return (origin) => {
      return SHIFT_ORIGIN_COLORS[origin] ?? "#7432FF";
    };
  },
};
