import apiClient from "@/services/api";

export default {
  async getAssociate({ commit, rootState }, { associate_number }) {
    try {
      const { data } = await apiClient.post("/api/associate/get", {
        project_id: rootState.applicationState.currentProject.id,
        associate_number,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while loading the KPI. Please try again later"
        );
      }

      commit("setAssociate", data);
    } catch (error) {
      throw error.message;
    }
  },
  async getKPI({ commit, rootState }, { associate_number, start, end }) {
    try {
      const { data } = await apiClient.post("/api/associate/kpi/get", {
        project_id: rootState.applicationState.currentProject.id,
        associate_number,
        start,
        end,
      });

      if (data.error_code && data.error_code !== "0") {
        throw new Error(
          "An error occurred while loading the KPI. Please try again later"
        );
      }

      commit("setAssociateKPI", data.kpi);
    } catch (error) {
      throw error.message;
    }
  },
};
