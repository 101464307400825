<template>
  <div>
    <h3>Info</h3>
    <div class="flex gap-4">
      <a-descriptions :column="1" bordered size="small" class="col-fixed p-0">
        <a-descriptions-item label="Date">
          {{ formattedDate }}
        </a-descriptions-item>
        <a-descriptions-item v-if="shift.version" label="Version">
          {{ shift.version }} </a-descriptions-item
        ><a-descriptions-item label="Status">
          <a-tag
            class="m-0"
            :color="
              shift.pay_code ? '#d47aff' : getShiftStatus(shift.status).color
            "
          >
            {{
              shift.pay_code
                ? shift.pay_code.name
                : getShiftStatus(shift.status).title
            }}</a-tag
          > </a-descriptions-item
        ><a-descriptions-item label="Source">
          {{ shift.origin_label }}
        </a-descriptions-item>
        <a-descriptions-item v-if="shift.author" label="Author">
          {{ shift.author.first_name }}
          {{ shift.author.last_name }}</a-descriptions-item
        >
        <a-descriptions-item v-if="!shift.pay_code" label="Payment Status">
          <a-tag
            class="m-0"
            :color="getShiftPaymentRequestStatus(shift.payment_status).color"
            >{{ getShiftPaymentRequestStatus(shift.payment_status).title }}</a-tag
          >
        </a-descriptions-item>
        <a-descriptions-item label="Payment Type">
          {{ shift.payment_type }}
        </a-descriptions-item>
        <a-descriptions-item label="Rate">
          ${{ shift.hour_rate / 100 }}</a-descriptions-item
        >
      </a-descriptions>

      <div class="flex flex-column gap-2">
        <a-card title="Digital Signature" size="small">
          <signature
            v-if="shift.signature"
            :associate="signatureAssociate"
            :available-signature="shift.signature"
          />

          <span v-else>Signature not provided</span>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, Card, Descriptions, Table, Tag } from "ant-design-vue";
import { createNamespacedHelpers } from "vuex";
import Signature from "@/components/signature.vue";
import Util from "@/util";
import moment from "moment-timezone";

const { mapState: shiftState, mapGetters: shiftGetters } =
  createNamespacedHelpers("shifts");

export default {
  components: {
    "a-table": Table,
    "a-button": Button,
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
    "a-tag": Tag,
    "a-card": Card,
    signature: Signature,
  },
  computed: {
    ...shiftState({
      shift: (state) => state.shift.shift,
    }),
    ...shiftGetters(["getShiftPaymentRequestStatus", "getShiftStatus"]),

    signatureAssociate() {
      return {
        name: Util.combineAssociateName(
          this.shift.signature.first_name,
          this.shift.signature.last_name
        ),
        email: this.shift.signature.email,
        phone: this.shift.signature.phone,
      };
    },

    formattedDate() {
      const start = moment(this.shift.scheduled_start).utc(true);
      const end = moment(this.shift.scheduled_end).utc(true);

      let result = "";

      result = `${start.format(this.$store.state.applicationState.dateFormat)}`;

      if (!start.isSame(end, "day")) {
        result += ` - ${end.format(
          this.$store.state.applicationState.dateFormat
        )}`;
      }

      return result;
    },
  },
  methods: {},
};
</script>

<style scoped>
.ant-card {
  height: 100%;
  border: none;
}
::v-deep .ant-card-head {
  border: 1px solid #e8e8e8;
  font-weight: bold;
}

::v-deep .ant-descriptions-item-label {
  font-weight: bold;
}
</style>
