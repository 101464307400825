import Cookies from 'js-cookie';

export default {
  token: Cookies.get("csrftoken") ?? null,
  user: null,
  associate: null,
  currentProject: null,
  currentPaymentType: null,
  currentPaymentTypeName: null,
  allProjects: null,
  dateFromFilter: null,
  dateToFilter: null,
  timeCardsSelectedEmployee: undefined,
  shiftAuthSelectedEmployee: undefined,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  dateFormat: "MM/DD/YYYY",
  dateTimeFormat: "MM/DD/YYYY hh:mm A",
  serverDateFormat: "YYYY-MM-DD",
  timeFormat: "hh:mm A",
  isProd: true,
  shiftAuthTypes: {},
  extendedShiftAuthTypes: [],
  timeConstants: {},
  projectRoles: [],
  projectLegalEntities: [],
  scheduleTypes: [],
  paymentTypes: [],
  chartOptions: {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  },
};
