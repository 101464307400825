<template>
  <div>
    <shift-overlap-modal
      :visible="visibleShiftAlreadyExistsModal"
      :overlapping-shifts="overlappingShifts"
      @edit-existing-shift="editExistingShift"
      @edit-new-shift="closeExistingShiftModal"
    />

    <a-modal
      v-model="visibleShiftModal"
      width="950px"
      :footer="null"
      @cancel="closeAssociateShiftModal"
      :destroy-on-close="true"
    >
      <associate-shift
        :shift-id="shiftId"
        :associate-number="associateNumber"
        @edit="editAssociateShiftModal"
        @shift-exists="handleShiftExists"
      />
    </a-modal>
  </div>
</template>

<script>
import { Modal } from "ant-design-vue";
import ShiftOverlapModal from "@/modules/shifts/components/shift-overlap-modal.vue";
import AssociateShift from "@/modules/shifts/components/associate-shift.vue";

export default {
  components: {
    "a-modal": Modal,
    "associate-shift": AssociateShift,
    "shift-overlap-modal": ShiftOverlapModal,
  },
  props: {
    associateNumber: {
      type: String,
      default: undefined,
    },
    shiftId: {
      type: Number,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Function,
    },
  },
  data() {
    return {
      visibleShiftModal: false,
      visibleShiftAlreadyExistsModal: false,

      overlappingShifts: [],
    };
  },
  watch: {
    visible: {
      handler(newVal) {
        this.visibleShiftModal = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    handleShiftExists(overlappingShifts) {
      this.closeAssociateShiftModal();
      console.log(overlappingShifts)
      this.visibleShiftAlreadyExistsModal = true;
      this.overlappingShifts = overlappingShifts;
    },

    editExistingShift(id) {
      this.visibleShiftAlreadyExistsModal = false;
      this.editingAssociateShiftId = id;
      this.visibleShiftModal = true;
    },

    closeAssociateShiftModal() {
      this.$emit("update:visible", false);
    },

    editAssociateShiftModal() {
      this.closeAssociateShiftModal();
      this.$emit("edit");
    },

    closeExistingShiftModal() {
      this.visibleShiftModal = true;
    },
  },
};
</script>
