<template>
  <div>
    <h3>Suspension Info</h3>
    <div class="grid">
      <template v-if="hasSuspension">
        <b class="col-4">Author</b>
        <span class="col-8">{{ suspensionAuthorName }}</span>

        <b class="col-4">Reason</b>
        <span class="col-8">{{ suspension.reason }}</span>

        <b class="col-4">Duration</b>
        <span class="col-8">
          {{
            isSuspensionDurationIndefinite
              ? "Indefinite"
              : suspension.days_amount
          }}
        </span>

        <template v-if="!isSuspensionDurationIndefinite">
          <b class="col-4">Days left</b>
          <span class="col-8">
            {{ suspension.remaining_days }}
          </span>
        </template>

        <div class="col-12 flex gap-2">
          <a-button icon="redo" @click="releaseFromSuspension"
            >Cancel Suspension</a-button
          >
        </div>
      </template>
      <template v-else>
        <div class="col-12 flex gap-2" v-if="!creatingSuspension">
          <a-button icon="plus" @click="switchAddSuspension"
            >Create Suspension</a-button
          >
        </div>
        <template v-else>
          <b class="col-4"
            >Suspension Days
            <span
              v-show="!newSuspension.indefinite_period"
              class="required-field"
              >*</span
            ></b
          >
          <div class="col-8">
            <a-input-number
              class="w-full"
              v-model="newSuspension.days_amount"
              :min="1"
              :disabled="newSuspension.indefinite_period"
              placeholder="Set days"
            />
          </div>

          <b class="col-4">Indefinite Period</b>
          <div class="col-8">
            <a-switch v-model="newSuspension.indefinite_period" />
          </div>

          <b class="col-4">Reason <span class="required-field">*</span></b>
          <div class="col-8">
            <a-textarea
              v-model="newSuspension.reason"
              :autoSize="true"
              placeholder="Enter suspension reason"
            />
          </div>

          <div class="col-12 flex gap-2">
            <a-button
              type="primary"
              @click="setSuspension"
              :disabled="saveSuspensionDisabled"
              >Suspend
            </a-button>
            <a-button type="danger" @click="cancelSuspension">Cancel</a-button>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import Util from "@/util";
import api from "@/api";
import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  notification,
  Switch,
} from "ant-design-vue";

export default {
  components: {
    "a-button": Button,
    "a-date-picker": DatePicker,
    "a-textarea": Input.TextArea,
    "a-switch": Switch,
    "a-input-number": InputNumber,
  },
  mixins: [api],
  data() {
    return {
      creatingSuspension: false,
      newSuspension: {
        days_amount: undefined,
        indefinite_period: false,
        reason: undefined,
      },
    };
  },
  props: {
    suspension: {
      type: [Object, null],
      default: null,
    },
    associateNumber: {
      type: String,
      default: null,
    },
  },
  watch: {
    newSuspension: {
      handler() {
        if (this.newSuspension.indefinite_period) {
          this.newSuspension.days_amount = undefined;
        }
      },
      deep: true,
    },
  },
  computed: {
    hasSuspension() {
      return this.suspension !== null;
    },
    visualDateFormat() {
      return this.$store.state.applicationState.dateFormat;
    },
    suspensionAuthorName() {
      if (!this.hasSuspension) {
        return "-";
      }

      const { first_name, last_name } = this.suspension.author || {};
      return Util.combineAssociateName(first_name, last_name);
    },
    isSuspensionDurationIndefinite() {
      return Boolean(this.suspension.indefinite_period);
    },
    saveSuspensionDisabled() {
      const { days_amount, indefinite_period, reason } = this.newSuspension;

      const isDaysAmountValid = days_amount != null || indefinite_period;
      const isReasonValid = reason && reason.length > 0;

      return !(isDaysAmountValid && isReasonValid);
    },
  },
  methods: {
    showNotification(type, message, description) {
      notification[type]({
        message: message,
        description: description,
      });
    },
    async releaseFromSuspension() {
      try {
        const { data } = await this.apiReleaseFromSuspension(
          this.associateNumber
        );

        if (data.error_code && data.error_code !== "0") {
          this.showNotification(
            "error",
            "Suspension Release Error",
            "Please try again later"
          );
          return;
        }

        this.showNotification(
          "success",
          "Suspension Released",
          `Associate ${this.associateNumber} is now not suspended`
        );

        this.$emit("reset");
      } catch (error) {
        this.showNotification(
          "error",
          "Suspension Release Error",
          "An unexpected error occurred"
        );
      }
    },
    switchAddSuspension() {
      this.creatingSuspension = !this.creatingSuspension;
    },
    setSuspension() {
      Modal.confirm({
        title: "Are you sure?",
        content: `You are trying to suspend an associate "${this.associateNumber}". If you are sure - click OK.`,
        onOk: async () => {
          try {
            const { data } = await this.apiSetSuspension(
              this.associateNumber,
              this.newSuspension
            );

            if (data.error_code && data.error_code !== "0") {
              this.showNotification(
                "error",
                "Suspension Set Error",
                "Please try again later"
              );
              return;
            }

            this.showNotification(
              "success",
              "Suspension Set",
              `Associate ${this.associateNumber} is now suspended`
            );

            this.resetNewSuspension();
            this.switchAddSuspension();
            this.$emit("reset");
          } catch (error) {
            this.showNotification(
              "error",
              "Suspension Set Error",
              "An unexpected error occurred"
            );
          }
        },
        onCancel() {},
      });
    },
    resetNewSuspension() {
      this.newSuspension.days_amount = undefined;
      this.newSuspension.indefinite_period = false;
      this.newSuspension.reason = undefined;
    },
    cancelSuspension() {
      this.resetNewSuspension();
      this.switchAddSuspension();
    },
  },
};
</script>

<style scoped>
span {
  text-align: left !important;
}
</style>
