<template>
    <div class="modal_windows">
        <modal v-if="isVisible" ref="popup"  @close="_cancel()" >
            <h4 slot="header">
              <slot name="header">{{title}}</slot>
            </h4>
            <div slot="body">
                <div class="">
                  <slot name="body">{{message}}</slot>
                </div>
            </div>
            <div slot="footer" style="text-align:center;">
                <button class="ui button" style="margin-right: 4px;" @click="_confirm()">{{this.customOkButton ? this.customOkButton : 'Ok'}}</button>
                <button class="ui button" @click="_cancel()">{{this.customCancelButton ? this.customCancelButton: 'Cancel'}}</button>
            </div>
        </modal>
    </div>
</template>

<script>
    import modal from './modal.vue';
    export default {
        components: {
            'modal': modal
        },
        data: function() {
            return {
              title: undefined,
              message: undefined,
              isVisible: false,
              resolvePromise: undefined,
              rejectPromise: undefined,
              customClose: false,
              customOkButton: undefined,
              customCancelButton: undefined,
            };
        },
        methods: {
          show(opts = {}) {

            this.title = opts.title
            this.message = opts.message
            this.customOkButton = opts.okText
            this.customCancelButton = opts.cancelText
            this.isVisible = true;
            return new Promise((resolve, reject) => {
              this.resolvePromise = resolve
              this.rejectPromise = reject
            })
          },

          _confirm() {
            this.isVisible = false;
            this.customOkButton = undefined
            this.customCancelButton = undefined
            this.resolvePromise(true)
          },

          _cancel() {
            this.isVisible = false;
            this.customOkButton = undefined;
            this.customCancelButton = undefined;
            this.resolvePromise(false)
          },
        }
    }
</script>